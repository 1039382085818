import { Grid } from "@mui/material";
import Typography from "../Typography";
import styled from "styled-components";
import { Divider } from "@mui/material";
import OrderStatusField from "./ItemSKUField";
import { useTranslation } from "react-i18next";
import { WindowSize, breakpoint } from '../../utils/responsive';

const DividerContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const formatPhoneNumber = (phoneNumber="12345678") => {
    return phoneNumber.replace(/(\d{4})(\d{4})/, '$1 $2');
}

const OrderNoDetail = (props) => {
    const { orderRecord, type="plan" } = props;
    const { t } = useTranslation(["orderContentItem", "common"]);
    const isMobile = WindowSize() === 'mobile';

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px', marginTop: '15px', width: "100%" }}>
            <DividerContainer>
                <Divider style={{ width: isMobile ? "100%" : "100%" }} />
            </DividerContainer>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '7px', padding: isMobile ? '0 25px' : '0' }}>
                { orderRecord.map((item, index) => (
                    <div key={`orderDetail-${index}`} style={{ display: "flex", width: "100%" }}>
                        <Grid container item style={{ width: "100%" }} justifyContent={isMobile ? 'flex-end' : 'flex-start'}>
                            {/* { !isMobile && <Grid item style={{ width: "7%" }}></Grid> } */}
                            <Grid item>
                                {
                                    type === "plan" ? (
                                        <Grid item container gap={2} style={{ alignItems: 'center' }}>
                                            <Grid item><Typography fontSize="16">{t("numberNo", { index: index+1 })}</Typography></Grid>
                                            <Grid item xs container gap={'5px'} style={{ alignItems: 'center' }}>
                                                <Grid item><Typography fontSize="18" bold style={{ lineHeight: '24px' }}>{formatPhoneNumber(item.number)}</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item container gap={2} style={{ alignItems: 'center' }}>
                                            <Grid item><Typography fontSize="16" textAlign="end">{item.sku}</Typography></Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OrderNoDetail;
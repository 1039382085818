import styled from "styled-components"
import Typography from "./Typography";
import WarnIcon from "../assets/ic-smallWarn.svg";
import { breakpoint } from "../utils/responsive";

const Container = styled.div`
    border-radius: 15px 15px 0 0;
    box-shadow: 0 0 3px 0 #dbbd00;
    background-color: #fefdec;
    height: auto;
    padding: 10px 16px;
    display: flex;
    align-items: center;

    @media ${breakpoint.desktop} {
        max-width: calc(100% - 100px);
        margin-left: 30px;
        width: fit-content;
    }

    @media ${breakpoint.mobile} {
        width: calc(100% - 75px);
        margin-left: 20px;
    }
`;

const ExternalRemark = (props) => {
    const { value="", icon=null, remarkComponent=null, style=null } = props;

    return (
        <Container>
            <img src={icon || WarnIcon} style={{ marginRight: 10 }} />
            { remarkComponent || (
                    <Typography
                        fontSize="16"
                        fontWeight="500"
                        color="#6a6a6a"
                        style={{ whiteSpace: "pre-wrap", ...style }}
                    >
                        {value}
                    </Typography>
                )
            }
        </Container>
    )
}

export default ExternalRemark;
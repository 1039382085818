import { useMemo } from "react";
import { Grid } from "@mui/material";
import ImagePreviewer from "../../components/ImagePreviewer";
import { WindowSize } from "../../utils/responsive";

const ProductImages = (props) => {
    const { images=[], isOptionSelected=false } = props;

    const isMobile = WindowSize() === 'mobile';

    const productImages = images.length > 0 ? images.map((image, index) => {
        return {
            url: image,
            alt: `Product Image ${index + 1}`
        }
    }) : [];

    return (
        <Grid item container gap={2} style={{ maxWidth: isMobile ? undefined : '455px', flex: 1 }}>
            <ImagePreviewer images={productImages} optionSelected={isOptionSelected} />
        </Grid>
    )
}

export default ProductImages;
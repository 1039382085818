import Typography from './Typography';
import styled from 'styled-components';
import theme from '../theme';

const Tag = styled(Typography)`
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: ${props => props.bgColor || "#979797"};
    // height: 32px;
    width: max-content;
    max-width: 200px;
    padding: 3px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 0 0 6px #656565;
    && {
        color: #ebfaff;
        // font-size: ${theme.fontSize.smallTag};
        font-size: 15px;
    }

    @media ${theme.device.mobile} {
        padding: 4px 20px;

        && {
            line-height: 15px;
        }
    }
`

export default Tag
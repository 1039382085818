import MyTypography from "../../components/Typography";
import CheckboxGroup from "../../components/CheckboxGroup";
import { WindowSize } from "../../utils/responsive";
import theme from "../../theme";
import BubbleSelect from "../../components/Selects/BubbleSelect";

const iPhoneTNC = (props) => {
    const { value, t, tncs, locale, showError } = props;

    const isMobile = WindowSize() === 'mobile';

    const options = tncs.map((tnc) => {
        return {
            value: tnc._id,
            label: tnc.description?.[locale] ? <div dangerouslySetInnerHTML={{ __html : tnc.description?.[locale] }} /> : "",
        }
    })

    return (
        <>
            <MyTypography fontSize={16} fontWeight={'bold'} color={theme.lightBlack}>
                {t('iphoneTermsTitle')}
                <span style={{ color: theme.mandatory, marginLeft: '5px' }}>*</span>
            </MyTypography>
            <BubbleSelect
                id="tnc"
                options={options}
                // data={formik.values.needRemovalService?.[0]}
                containerStyle={{ width: '100%' }}
                verticalAlign
                fullWidth
                multiple
                circle={false}
                checkboxContainerStyle={{
                    alignItems: 'flex-start',
                }}
                bubbleContentWrapperStyle={{
                    margin: "20px",
                    marginLeft: "15px",
                }}
                typographyProps={{
                    isHTML: true,
                }}
            />
        </>
        // <CheckboxGroup 
        //     id="tnc"
        //     title={t('iphoneTermsTitle')}
        //     // options={[
        //     //     { value: 'agree1', _renderLabel: () => TNCContent(t), mandatory: true },
        //     // ]}
        //     options={options}
        //     multiple={true}
        //     mandatory
        //     data={value}
        //     titleStyle={{
        //         fontWeight: 'bold',
        //     }}
        //     optionsContainerStyle={{
        //         borderRadius: '25px',
        //         backgroundColor: '#fff',
        //         boxShadow: '0 3px 8px 0 #c9c9c9',
        //         padding: '23.5px',
        //     }}
        //     checkboxStyle={{
        //         display: isMobile ? 'flex' : undefined,
        //         flexDirection: isMobile ? 'column' : undefined,
        //         whiteSpace: 'pre-wrap',
        //     }}
        //     t={t}
        //     showError={showError}
        // />
    )
}

export default iPhoneTNC;
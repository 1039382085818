import styled from "styled-components";
import CommonCheckbox from "../Common/CommonCheckbox"
import MyTypography from "../Typography";
import theme from "../../theme";
import { breakpoint } from "../../utils/responsive";
import { useField } from "formik";
import _ from "lodash";

const BubbleSelect = ({
  options,
  fullWidth = false,
  bubbleWidth,
  onChange,
  data = null,
  containerStyle = null,
  checkboxContainerStyle = null,
  typographyProps = null,
  bubbleStyle = null,
  multiple,
  id,
  viewOnly,
  verticalAlign = true,
  disableUnchecked,
  circle=true,
  bubbleContentWrapperStyle = null,
}) => {
  const [field, meta, helpers] = useField(id);

  return (
    <Container
      fullWidth={fullWidth}
      style={{ ...containerStyle }}
      verticalAlign={verticalAlign}
    >
      {viewOnly ? (
        <MyTypography style={{ fontSize: "18px", color: theme.lightBlack }}>
          {_.find(options, ["value", field.value])?.label}
        </MyTypography>
      ) : (
        options &&
        options.map((item) => {
          return (
            <Bubble
              fullWidth={fullWidth}
              verticalAlign={verticalAlign}
              hasTag={item.tag}
              width={bubbleWidth}
              style={{...bubbleStyle}}
            >
              {item.tag && <BubbleTag>{item?.tag}</BubbleTag>}
              <div style={{ margin: 35, marginLeft: 15, ...bubbleContentWrapperStyle }}>
                <CommonCheckbox
                  style={checkboxContainerStyle}
                  key={`bubbleCheckbox-option-${item.id}`}
                  label={item.label}
                  circle={circle}
                  onChange={(e) => {
                    let selected = "";
                    if (multiple) {
                      selected = e.target.checked
                        ? [...field?.value, item?.value]
                        : field?.value.filter((value) => value !== item.value);
                    } else {
                      disableUnchecked
                        ? (selected = item?.value)
                        : (selected = e.target.checked ? item?.value : "");
                    }

                    onChange && onChange(field.value !== selected);

                    helpers.setValue(selected);
                  }}
                  checked={
                    multiple
                      ? field?.value && field?.value.includes(item?.value)
                      : field?.value === item?.value
                  }
                  id={id}
                  typographyProps={{...typographyProps}}
                />
              </div>
            </Bubble>
          );
        })
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props) =>
    props.fullWidth ? "space-between" : "flex-start"};
  gap: 30px;
  ${(props) => props.verticalAlign && `flex-direction: column;`}

  @media ${breakpoint.mobile} {
    gap: 15px;
    flex-wrap: ${(props) => (props.verticalAlign ? "wrap" : "nowrap")};
  }
`;

const BubbleTag = styled(MyTypography)`
  position: relative;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  background: ${theme.tagGradientColor};
  height: 28px;
  width: max-content;
  padding: 3px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 0px;
  top: 0px;
  && {
    text-shadow: 0 3px 6px #c7b645;
    color: #333;
    font-size: ${theme.fontSize.tag};
    font-weight: bold;
  }
`;

const Bubble = styled.div`
  border-radius: 25px;
  box-shadow: 0 0 6px 0 #c9c9c9;
  background-color: #fff;
  position: relative;
  flex-grow: ${(props) => (props.fullWidth ? "1" : "none")};
  display: flex;
  align-items: center;
  width: ${(props) =>
    props.fullWidth ? "100%" : props.width ? props.width : "max-content"};
  padding: ${(props) =>
    props.verticalAlign && props.hasTag ? "10px 0px" : "0px"};

  @media ${breakpoint.mobile} {
    padding: ${(props) =>
      props.verticalAlign && props.hasTag ? "10px 0px" : "0px"};
    width: 100%;
  }
`;

export default BubbleSelect;

import styled from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import SimpleDialog from '../../../../components/CommonDialog/SimpleDialog';
import YellowBubbleCheckbox from '../../../../components/Checkboxs/YellowBubbleCheckbox';
import { Grid } from '@mui/material';
import MyTextField from '../../../../components/TextField';
import MyTypography from '../../../../components/Typography';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import CommonTextField from '../../../../components/Common/CommonTextField';
import MyButton from '../../../../components/Button';
import _ from 'lodash';
import { scrollToHash } from '../../../../utils/common';
import {
  fetchLuckyNumber,
  holdLuckyNumber,
  holdNewNumber,
  searchLuckyNumber,
} from '../../../../apis';
import { fetchWithTimeout } from '../../../../utils/common';
import { GlobalContextStore } from '../../../../context/GlobalProvider';
import { appearGlobalError } from '../../../../requests/globalRequest';
import { WindowSize } from '../../../../utils/responsive';
import IconRefresh from '../../../../assets/ic-refresh.svg';
import Space from '../../../../components/Space';

const NewNumberDialog = ({ isOpen, setIsOpen, name }) => {
  const { t } = useTranslation(['checkout', 'common']);
  const { globalDispatch } = useContext(GlobalContextStore);
  const [field, meta, helper] = useField(name);
  const [keyword, setKeyword] = useState();
  const [tempNumList, setTempNumList] = useState([]);
  const [selectedNumList, setSelectedNumList] = useState([]);
  const [error, setError] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const isMobile = WindowSize() === 'mobile';

  const orderId = field.value?._id

  useEffect(() => {
    const getLuckyNumber = async () => {
      setNoResult(false);
      const res = await fetchLuckyNumber();

      setTempNumList([...field?.value?.order_newNumList, ...res]);
      return res;
    };

    if (isOpen) {
      getLuckyNumber();

      if (field?.value?.order_newNumList) {
        setSelectedNumList(field?.value?.order_newNumList);
      }
    }
  }, [isOpen]);

  const fetchSearchResult = async (num) => {
    try {
      if (num) {
        setNoResult(false);
        const { data } = await fetchWithTimeout(
          searchLuckyNumber({ number: num }),
          2000
        );
        if (data.length < 1) return setNoResult(true);

        // filter selected but not saved-in-quote number => prevent duplicate selected number
        const filterData = selectedNumList.length > 0 ? data.filter(
          (item) => !selectedNumList.find((selected) => selected._id === item._id)
        ) : data;
        setTempNumList([...selectedNumList, ...filterData]);
      }
    } catch (e) {
      console.error('search number error - ', e);
    }
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    const reg = new RegExp(/^\d+$/);

    if (val === '') {
      setKeyword('');
      fetchSearchResult('6');
    }

    if (reg.test(val)) {
      setKeyword(val);

      if (val.length >= 2) {
        fetchSearchResult(val);
      }
    }
  };

  const handleRefreshSearch = async (e) => {
    setKeyword('')
    const result = await fetchLuckyNumber()
    setTempNumList([...selectedNumList, ...result])
  }

  const handleSelect = (num) => {
    setSelectedNumList([...selectedNumList, num]);
  };

  const handleDelete = (num) => {
    setSelectedNumList(selectedNumList.filter((val) => val !== num));
  };

  const handleSave = async () => {
    if (selectedNumList.length === field.value.order_newNumQty) {
      try {
        const body = {
          newNumberPoolIds: selectedNumList.map((item) => item._id),
        };
        //const result = await holdLuckyNumber(body);
        const result = await holdNewNumber(body, orderId);

        if (result?.msg === 'success') {
          helper.setValue({
            ...field.value,
            order_newNumList: selectedNumList,
          });
          setIsOpen(false);
          setError(false);
        } else throw result;
      } catch (e) {
        appearGlobalError(globalDispatch, e?.result?.code, e?.result?.errorMessage, {
          type: 'fail',
        });
      }
    } else {
      setError(true);
    }
  };


  return (
    <SimpleDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={t('changeNumber')}
      hasSpace="false"
    //containerStyle={{ display: 'flex', flexDirection: 'column' }}
    >
      <Grid
        container
        item
        direction="column"
        wrap="nowrap"
        height={"95%"}
        rowSpacing={2}
        id="newNumberDialog"
        pt={4}

      >
        <Grid
          item
          spacing={2}
          className="tmp-section-selectedNumber"
          marginTop="20"
          style={{ padding: 10, margin: -10 }} //box-shadow disappear with overflow https://stackoverflow.com/a/41503444
        >
          <Grid container item spacing={2}>
            {selectedNumList.map((num) => {
              const formattedLabel = num?.number
                ?.toString()
                .replace(/(\d{4})/g, '$1 ');
              return (
                <Grid item xs={isMobile ? 12 : 6}>
                  <YellowBubbleCheckbox
                    fullWidth
                    label={formattedLabel}
                    selected
                    checked={true}
                    onChange={(e) => handleDelete(num)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          {error && (
            <MyTypography color="error">
              {t('warnPleaseChooseNumber', {
                num: field.value.order_newNumQty,
              })}
            </MyTypography>
          )}
        </Grid>
        <Grid item mb={3}>
          <CommonTextField
            placeholder={t('checkout:newNumberDialog.placeholder')}
            value={keyword}
            handleChange={(e) => handleSearch(e)}
            inputProps={{
              style: {
                padding: isMobile ? "9px" : undefined,
                roundCorner: isMobile ? "12px" : undefined,
              },
            }}
          />
        </Grid>
        <Grid
          item
          rowSpacing={2}
          className="tmp-selection-allNumbersInPool"
          style={{ flex: 1, padding: 10, margin: -10, display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}

        >
          <Grid item pb={2} >
            <MyTypography fontWeight="500" fontSize={18} mFontSize={16} >
              {t('checkout:newNumberDialog.title')}
            </MyTypography>
            {noResult && (
              <MyTypography color="error">{t('noMatchingResult')}</MyTypography>
            )}
          </Grid>
          <Grid container item spacing={2} minHeight={200} style={{ flex: 1 }}>
            {tempNumList.map((num) => {
              const formattedLabel = num?.number
                ?.toString()
                .replace(/(\d{4})/g, '$1 ');
              return (
                <Grid item xs={isMobile ? 12 : 6} key={formattedLabel}>
                  <YellowBubbleCheckbox
                    fullWidth
                    label={formattedLabel}
                    onChange={() => handleSelect(num)}
                    checked={selectedNumList.indexOf(num) > -1}
                    disabled={selectedNumList.indexOf(num) > -1}
                  />
                </Grid>
              );
            })}
            <Space size="20px" width="1px" />
          </Grid>
        </Grid>
        <Grid container gap={2} direction="column" alignItems="center" style={{ marginTop: 20 }}>

          <MyButton style={{ width: isMobile ? '100%' : '50%', color: 'black', boxShadow: '0 3px 10px #c9c9c9', minWidth: 'fit-content' }} color="white" onClick={handleRefreshSearch}>
            <img
              src={IconRefresh}
              alt="refresh"
              style={{ marginRight: 20, height: '1em' }}
            />
            {t('refreshNumber')}
          </MyButton>


          <MyButton onClick={handleSave} style={{ width: isMobile ? '100%' : '50%' }}>
            {t('common:save')}
          </MyButton>

        </Grid></Grid>

    </SimpleDialog>
  );
};
export default NewNumberDialog;

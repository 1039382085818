import styled from "styled-components"
import Typography from "../../../components/Typography";
import WarnIcon from "../../../assets/ic-smallWarn.svg";
import { breakpoint } from "../../../utils/responsive";
import { useTranslation } from "react-i18next";

const Container = styled.div`
    border-radius: 15px 15px 0 0;
    box-shadow: 0 0 3px 0 #dbbd00;
    background-color: #fefdec;
    height: auto;
    padding: 6px 15px;
    display: flex;
    align-items: center;
    width: fit-content;
`;

const ErrorHint = (props) => {
    const { value, icon=null, remarkComponent=null, style=null } = props;

    const { t } = useTranslation("profile");

    return (
        <Container>
            <img src={icon || WarnIcon} style={{ marginRight: 10 }} />
            { remarkComponent || (
                <Typography
                    fontSize="16"
                    fontWeight="500"
                    color="#333"
                    style={{ whiteSpace: "pre-wrap", ...style }}
                >
                    {t(`pendingDocument.${value?.error || "uploadErrorMessage"}`)}
                </Typography>
            )}
        </Container>
    )
}

export default ErrorHint;
import { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Typography from './Typography'
import theme from '../theme'
import PropTypes from 'prop-types';

const Step = styled.div`
    border-radius: 50%;
    background-color: ${props => props.bgColor ? props.bgColor : theme.yellow};
    color: ${theme.lightBlack};
    height: ${props => props?.size || '33px'};
    width: ${props => props?.size || '33px'};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    z-index: 2;
`

export default Step
import styled from "styled-components"
import theme from "../../theme";
import MuiCheckbox from '@mui/material/Checkbox';
import Typography from "../Typography";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin-left: 20px;
`

const LabelText = styled(Typography)`
    font-size: ${theme.fontSize.body3};
    font-weight: bold;
    margin-left: 14px;
    align-self: center;
    overflow-wrap: break-word;

    * {
        overflow-wrap: break-word !important;
        word-break: break-word !important;
        text-wrap: wrap !important;
        white-space: pre-wrap !important;
    }
`

const ErrorText = styled(Typography)`
    color: ${theme.error2};
    font-size: 12px;
    margin: 8px 14px 0 0;
`

const HTMLLabelText = styled.div`
    align-self: center;
    overflow-wrap: break-word;
    font-size: inherit;
    margin-right: 14px;

    * {
        overflow-wrap: break-word !important;
        word-break: break-word !important;
        text-wrap: wrap !important;
        /* white-space: pre-line !important; */
        font-size: inherit;
    }
`

const Checkbox = styled(MuiCheckbox)({

    '&.MuiCheckbox-root': {
        color: '#999999',
    },
    '&.Mui-checked': {
        color: `#666666 !important`,
    }
});

function MyCheckbox({ label, style, typographyProps, circle, checked, disabled, onChange, checkboxStyle, error, ...props }) {

    const checkboxType = circle ? {
        icon: <RadioButtonUncheckedIcon />,
        checkedIcon: <CheckCircleIcon />,
    } : {}

    return (
        <div>
            <CheckboxContainer style={style} {...props}>
                <Checkbox checked={checked} {...checkboxType} style={checkboxStyle} disabled={disabled} onChange={(e) => onChange && onChange(e)} />
                {label && !!typographyProps?.isHTML ? <HTMLLabelText>{label}</HTMLLabelText> : <LabelText {...typographyProps}>{label}</LabelText>}
            </CheckboxContainer>
            {error && <ErrorText>{error}</ErrorText>}
        </div>
    )
}

export default MyCheckbox;
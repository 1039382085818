import MyTypography from "../Typography";
import theme from "../../theme";

const ErrorText = ({ children }) => {
  return (
    <MyTypography fontSize={12} color={theme.error2} style={{ marginTop: 15 }}>
      {children}
    </MyTypography>
  );
};

export default ErrorText;

import { Grid } from "@mui/material";
import Tab from "../../components/Tab";
import { breakpoint, pageMargin } from "../../utils/responsive";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    // margin: ${pageMargin.desktop};

    // @media ${breakpoint.mobile} {
    //     margin: 0;
    //     margin-bottom: 30px;
    // }
`

const ProductDetails = (props) => {
    const { tabs, productDetailsRef } = props;
    
    return (
        <Container ref={productDetailsRef}>
            <Tab tabs={tabs} tabStyle={{ fontSize: "16px" }} />
        </Container>
    )
}

export default ProductDetails;
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Form from "./Form";
import MyTypography from "../../../components/Typography";
import theme from "../../../theme";
import { useField, useFormikContext } from "formik";
import _ from "lodash";
import Space from "../../../components/Space";
import { ResponsiveUI } from "../../../utils/responsive";

const TEMP_NUMLIST = [
  {
    numberId: "64af73e3ff33464b0b28ab6f",
    number: "12345678",
    _id: "650132057755f6b25aa27755",
  },
  {
    numberId: "64af73e3ff33464b0b28ab6f",
    number: "23456789",
    _id: "650132057755f6b25aa27755",
  },
  {
    numberId: "64af73e3ff33464b0b28ab6f",
    number: "31231231",
    _id: "650132057755f6b25aa27755",
  },
];

const MnpNumberPreivew = () => {
  const { t } = useTranslation("checkout");
  const [field, meta, helpers] = useField("order_list");
  const mnpNumberList = field?.value
    ? field.value?.map((o) => o?.mnpNumbers).flat()
    : [];

  if (_.isEmpty(mnpNumberList)) return;

  const _renderDesktop = () => {
    return (
      <Form title={t("mobilePortin")}>
        <Grid container item paddingX={"30px"}>
          <Grid item xs={6}>
            <MyTypography>{t("mobileNumberPorted")}</MyTypography>
          </Grid>
          <Grid item xs={6}>
            <MyTypography>{t("effectiveDateTime")}</MyTypography>
          </Grid>
          <Grid item xs={12}>
            <Space size="15px" />
          </Grid>
          {mnpNumberList.map((item) => {
            return (
              <Grid container item style={{ marginBottom: 10 }}>
                <Grid item xs={6}>
                  <MyTypography
                    style={{ fontSize: "18px", color: theme.lightBlack }}
                  >
                    {item?.number}
                  </MyTypography>
                </Grid>
                <Grid item xs={6}>
                  <MyTypography
                    style={{ fontSize: "18px", color: theme.lightBlack }}
                  >
                    {item?.effectiveDate}
                  </MyTypography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Form>
    );
  };

  const _renderMobile = () => {
    return (
      <Form title={t("mobilePortin")}>
        <Grid container spacing={4} paddingX={"25px"}>
          {mnpNumberList.map((item) => {
            return (
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <MyTypography>{t("mobileNumberPorted")}</MyTypography>
                </Grid>
                <Grid item xs={12}>
                  <MyTypography fontSize="18">{item?.number}</MyTypography>
                </Grid>
                <Grid item xs={12}>
                  <MyTypography>{t("effectiveDateTime")}</MyTypography>
                </Grid>
                <Grid item xs={12}>
                  <MyTypography fontSize="18">{item?.effectiveDate}</MyTypography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Form>
    );
  };

  return <ResponsiveUI desktop={_renderDesktop()} mobile={_renderMobile()} />;
};

const Container = styled.div`
  display: flex;
`;

export default MnpNumberPreivew;

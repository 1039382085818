import AutoComplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
// import TextField from './TextField';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { Select, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper'
import styled from 'styled-components'
import Typography from './Typography'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import theme from '../theme';
import { GetWord } from '../utils/common';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomDivider from './Divider';
import MyTypography from './Typography';
import { useMemo } from 'react';

const StyledTheme = createTheme({
    components: {
        // MuiAutocomplete: {
        //     styleOverrides: {
        //         root: {
        //             boxShadow: '0 0 6px 0 #c9c9c9',
        //             borderRadius: '10px',
        //         },
        //         option: {
        //             '&.Mui-focused': {
        //                 backgroundColor: '#E3FEFF',
        //                 color: "#0A296A"
        //             }
        //         },
        //     },
        // },
        // MuiInputBase: {
        //     styleOverrides: {
        //         root: {
        //             '&.Mui-focused': {
        //                 borderRadius: '15px',
        //                 boxShadow: '0 0 6px 0 #c9c9c9',
        //                 padding: '0 10px',
        //                 fontSize: '18px',
        //                 fontWeight: 500,
        //                 borderColor: 'none',
        //                 borderWidth: '0px',

        //                 "&.Mui-error": {
        //                     boxShadow: `0 0 6px 0 ${theme.error}`,
        //                 }
        //             },
        //             '&.MuiOutlinedInput-notchedOutline': {
        //                 // borderColor: 'transparent !important',
        //                 // borderWidth: '0px !important',
        //             },
        //         },
        //     },
        // },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    boxShadow: '0 0 6px 0 #c9c9c9',
                    padding: '0 10px',
                    fontSize: '18px',
                    fontWeight: 500,
                    borderColor: 'none',
                    borderWidth: '0px',
                    maxWidth: '100%',

                    "& .MuiOutlinedInput-input": {
                        padding: '12px 14px',
                    },

                    "&.Mui-error": {
                        boxShadow: `0 0 6px 0 ${theme.error}`,
                    },

                    "&.Mui-disabled": {
                        backgroundColor: '#f2f2f2',
                        boxShadow: `0 0 6px 0 #c9c9c9`,
                    },
                },
                notchedOutline: {
                    borderColor: 'transparent !important',
                    borderWidth: '0px !important',
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    // FIXME: have better way to build the divider?
                    '&.MuiSelect-icon': {
                        marginRight: '10px',
                        paddingLeft: '10px',
                        borderLeft: `2px solid ${theme.divider}`,
                    },
                    '&.MuiSelect-iconOpen': {
                        marginRight: '10px',
                        padding: '0px 10px 0px 0px',
                        borderLeft: 'none',
                        borderRight: `2px solid ${theme.divider}`,
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.MuiPaper-root': {
                        marginTop: '5px',
                        boxShadow: `0 0 10px 0 ${theme.shadowYellow}`,
                        // maxHeight: '300px',     // TODO: to be confirmed
                        borderRadius: "10px",
                    },
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '&.MuiList-root': {
                        padding: '2px 0',
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: '12px 23px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    color: theme.lightBlack,
                    '&.Mui-selected': {
                        backgroundColor: '#fffbde !important',
                        '&:hover': {
                            backgroundColor: '#fffbde', // TODO
                        }
                    },
                }
            }
        }
    },
});

const MyPicker = (props) => {
    const { 
        title, 
        titleColor = '#333333', 
        titleSize, 
        readOnly = false, 
        getOptionLabelField = 'title',
        mandatory = false,
        value=[],
        multiple=false,
        options=[],
        onChange,
        placeholder,
        error=false,
        helperText,
    } = props;

    const { t, i18n } = useTranslation('common');

    const handleOnSelect = (value) => {
        const optionSelected = multiple ? value : [value]
        onChange && onChange(optionSelected)
    }

    const ItemValueComponent = ({ option, key }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', fontWeight: 'bold', maxWidth: "100%" }} key={key}>
                { option.colorCode && <div style={{ minWidth: '26px', minHeight: '26px', borderRadius: '9px', backgroundColor: option.colorCode, marginRight: '10px' }} /> }
                <MyTypography fontSize={18} bold style={{ whiteSpace: "pre-line", paddingRight: "20px" }}>{ GetWord(option[getOptionLabelField]) || "" }</MyTypography>
            </div>
        )
    }

    const optionIdKey = useMemo(() => options[0]?._id ? '_id' : 'id', [options]);

    return (
        <>
            {
                title && <Typography color={error ? theme.error : titleColor} style={{ fontWeight: 'bold', maxWidth: '100%' }} header>
                    {title}
                    {mandatory && <span style={{ color: theme.mandatory, marginLeft: '5px' }}>*</span>}
                </Typography>
            }
            <ThemeProvider theme={StyledTheme}>
                {/* <TextField
                    placeholder={title || "選擇..."}
                    variant="outlined"
                    margin='none'
                    mandatory={mandatory}
                    select
                    disabled={readOnly}
                >
                    <MenuItem value="" disabled>{'=== Select Option ==='}</MenuItem>
                    {
                        props.options.map((option, index) => {
                            return (
                                <MenuItem key={option.id} value={option} disabled={option.disabled}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        { option.colorCode && <div style={{ width: '26px', height: '26px', borderRadius: '9px', backgroundColor: option.colorCode, marginRight: '10px' }} /> }
                                        { option[getOptionLabelField] }
                                    </div>
                                </MenuItem>
                            )
                        })
                    }
                </TextField> */}
                <Select
                    value={multiple ? value : value?.[0] || ""}
                    defaultValue={multiple ? value : value?.[0] || ""}
                    multiple={multiple}
                    disabled={readOnly}
                    onChange={event => handleOnSelect(event.target.value)}
                    displayEmpty
                    error={error}
                    IconComponent={ExpandMoreIcon} // TODO: change to custom icon
                    MenuProps={{ disableScrollLock: true }}
                    renderValue={(selectedValue) => {
                        const selectedOptions = options.filter(option => multiple ? selectedValue.includes(option[optionIdKey]) : option[optionIdKey] === selectedValue)

                        if (selectedOptions.length > 0) return (
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                {selectedOptions.map((option, index) => (
                                    <ItemValueComponent option={option} key={`${title}-selected-${index}`} />
                                ))}
                            </div>
                        )
                        
                        return ( <span style={{ color: readOnly ? '#999999' : '#a7a7a7' }}>{placeholder || t('selectOption')}</span> )
                    }}
                >
                    <MenuItem value="" disabled>{`=== ${t('selectOption')} ===`}</MenuItem>
                    {
                        options.map((option, index) => (
                            <MenuItem key={`${title}-option-${index}`} value={option[optionIdKey]} disabled={option.disabled}>
                                <ItemValueComponent option={option} key={`${title}-selected-${index}`} />
                            </MenuItem>
                        ))
                    }
                </Select>
                { error && helperText && <Typography color={theme.error} fontSize={12} style={{ marginTop: '6px' }}>{helperText}</Typography>}
            </ThemeProvider>
        </>
    )
}

export default MyPicker;

MyPicker.propTypes = {
    title: PropTypes.string,
    titleColor: PropTypes.string,
    titleSize: PropTypes.string,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    getOptionLabelField: PropTypes.string.isRequired,
};
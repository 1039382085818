import { AppBar, Slide, useScrollTrigger, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import Navbar from './Menubar'
import CookieConsent from './CookieConsent/'
import { useLocation, useRoutes } from 'react-router-dom';
import { WindowSize } from '../../utils/responsive';
import styled from 'styled-components';
import { GlobalContextStore } from '../../context/GlobalProvider';

const HIDE_ON_SCROLL_PAGES = [
  "/mobile",
  "/checkout",
  "/shoppingcart",
]

const ScrollableAppbar = () => {
  const { globalState } = useContext(GlobalContextStore);
  const isMobile = WindowSize() === 'mobile';
  const location = useLocation();
  const theme = useTheme()
  const matchedHideOnScrollPage = HIDE_ON_SCROLL_PAGES.some((path) => location.pathname.includes(path));
  const shouldHideOnScroll =
    matchedHideOnScrollPage && isMobile;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200
  });
  return shouldHideOnScroll ? (
    <div style={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar, height: globalState.CookieConsentHeight }}>
      <AppBar position={'sticky'}><CookieConsent /></AppBar>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar position={'relative'}>
          <Navbar />
        </AppBar>

      </Slide>
    </div>
  ) : (
    <AppBar position={'sticky'}>
      <CookieConsent />
      <Navbar />
    </AppBar>
  );
};

export default ScrollableAppbar;



import { useTranslation } from "react-i18next";
import Select from "../Select";
import CommonSelect from "../Common/CommonSelect";

const DistrictSelect = ({
  id,
  viewOnly,
  value,
  onChange,
  error,
  helperText,
}) => {
  const { t } = useTranslation("checkout");

  const GROUPED_DISTRICT_LIST = [
    { 
      headerId: "hongKongIsland", 
      title: t("hongKongIsland"), 
      options: [
        { title: t("aberdeen"), value: "aberdeen" },
        { title: t("admiralty"), value: "admiralty" },
        { title: t("apLeiChau"), value: "apLeiChau" },
        { title: t("causewayBay"), value: "causewayBay" },
        { title: t("central"), value: "central" },
        { title: t("chaiWan"), value: "chaiWan" },
        { title: t("cyberport"), value: "cyberport" },
        { title: t("deepWaterBay"), value: "deepWaterBay" },
        { title: t("happyValley"), value: "happyValley" },
        { title: t("kennedyTown"), value: "kennedyTown" },
        { title: t("midLevels"), value: "midLevels" },
        { title: t("northPoint"), value: "northPoint" },
        { title: t("pofulam"), value: "pofulam" },
        { title: t("quarryBay"), value: "quarryBay" },
        { title: t("repulseBay"), value: "repulseBay" },
        { title: t("saiWanHo"), value: "saiWanHo" },
        { title: t("saiYingPun"), value: "saiYingPun" },
        { title: t("shauKeiWan"), value: "shauKeiWan" },
        { title: t("shekO"), value: "shekO" },
        { title: t("shekTongTsui"), value: "shekTongTsui" },
        { title: t("sheungWan"), value: "sheungWan" },
        { title: t("stanley"), value: "stanley" },
        { title: t("taiHang"), value: "taiHang" },
        { title: t("taiTam"), value: "taiTam" },
        { title: t("taikoo"), value: "taikoo" },
        { title: t("thePeak"), value: "thePeak" },
        { title: t("tinHau"), value: "tinHau" },
        { title: t("wahFu"), value: "wahFu" },
        { title: t("wanChai"), value: "wanChai" },
        { title: t("westernDistrict"), value: "westernDistrict" },
        { title: t("wongChukHang"), value: "wongChukHang" },
        { title: t("shamWan"), value: "shamWan" },
        { title: t("hengFaChuen"), value: "hengFaChuen" },
        { title: t("chungHomKok"), value: "chungHomKok" },
      ] 
    },
    {
      headerId: "kowloon",
      title: t("kowloon"),
      options: [
        { title: t("chaKwoLing"), value: "chaKwoLing" },
        { title: t("cheungShaWan"), value: "cheungShaWan" },
        { title: t("choiHung"), value: "choiHung" },
        { title: t("diamondHill"), value: "diamondHill" },
        { title: t("hoManTin"), value: "hoManTin" },
        { title: t("hungHom"), value: "hungHom" },
        { title: t("jordan"), value: "jordan" },
        { title: t("kowloonBay"), value: "kowloonBay" },
        { title: t("kowloonCity"), value: "kowloonCity" },
        { title: t("kowloonTong"), value: "kowloonTong" },
        { title: t("kwunTong"), value: "kwunTong" },
        { title: t("laiChiKok"), value: "laiChiKok" },
        { title: t("lamTin"), value: "lamTin" },
        { title: t("lokFu"), value: "lokFu" },
        { title: t("maTauWai"), value: "maTauWai" },
        { title: t("meiFoo"), value: "meiFoo" },
        { title: t("mongKok"), value: "mongKok" },
        { title: t("ngauChiWan"), value: "ngauChiWan" },
        { title: t("ngauTauKok"), value: "ngauTauKok" },
        { title: t("princeEdward"), value: "princeEdward" },
        { title: t("sanPoKong"), value: "sanPoKong" },
        { title: t("sauMauPing"), value: "sauMauPing" },
        { title: t("shamShuiPo"), value: "shamShuiPo" },
        { title: t("shekKipMei"), value: "shekKipMei" },
        { title: t("stonecuttersIsland"), value: "stonecuttersIsland" },
        { title: t("shunLee"), value: "shunLee" },
        { title: t("taiKokTsui"), value: "taiKokTsui" },
        { title: t("toKwaWan"), value: "toKwaWan" },
        { title: t("tsimShaTsui"), value: "tsimShaTsui" },
        { title: t("tsimShaTsuiEast"), value: "tsimShaTsuiEast" },
        { title: t("tszWanShan"), value: "tszWanShan" },
        { title: t("wangTauHom"), value: "wangTauHom" },
        { title: t("wongTaiSin"), value: "wongTaiSin" },
        { title: t("yauMaTei"), value: "yauMaTei" },
        { title: t("yauTong"), value: "yauTong" },
        { title: t("austin"), value: "austin" },
      ]
    },
    {
      headerId: "newTerritories",
      title: t("newTerritories"),
      options: [
        { title: t("clearWaterBay"), value: "clearWaterBay" },
        { title: t("fanling"), value: "fanling" },
        { title: t("foTan"), value: "foTan" },
        { title: t("hangHau"), value: "hangHau" },
        { title: t("kamShan"), value: "kamShan" },
        { title: t("kwaiChung"), value: "kwaiChung" },
        { title: t("kwuTungSheungShui"), value: "kwuTungSheungShui" },
        { title: t("laiKing"), value: "laiKing" },
        { title: t("lauFauShan"), value: "lauFauShan" },
        { title: t("lionRock"), value: "lionRock" },
        { title: t("lungKwuTan"), value: "lungKwuTan" },
        { title: t("maLiuShui"), value: "maLiuShui" },
        { title: t("maOnShan"), value: "maOnShan" },
        { title: t("pingChe"), value: "pingChe" },
        { title: t("saiKung"), value: "saiKung" },
        { title: t("sciencePark"), value: "sciencePark" },
        { title: t("shamTseng"), value: "shamTseng" },
        { title: t("shatin"), value: "shatin" },
        { title: t("sheungShui"), value: "sheungShui" },
        { title: t("soKwanWat"), value: "soKwanWat" },
        { title: t("taKwuLing"), value: "taKwuLing" },
        { title: t("taiLam"), value: "taiLam" },
        { title: t("taiLamChung"), value: "taiLamChung" },
        { title: t("taiMoShan"), value: "taiMoShan" },
        { title: t("taiPo"), value: "taiPo" },
        { title: t("taiWai"), value: "taiWai" },
        { title: t("taiWo"), value: "taiWo" },
        { title: t("taiWoHau"), value: "taiWoHau" },
        { title: t("tinShuiWai"), value: "tinShuiWai" },
        { title: t("tingKau"), value: "tingKau" },
        { title: t("tiuKengLeng"), value: "tiuKengLeng" },
        { title: t("tseungKwanO"), value: "tseungKwanO" },
        { title: t("tsingYi"), value: "tsingYi" },
        { title: t("tsuenWan"), value: "tsuenWan" },
        { title: t("tuenMun"), value: "tuenMun" },
        { title: t("yuenLong"), value: "yuenLong" },
        { title: t("kamSheungRoad"), value: "kamSheungRoad" },
        { title: t("lokMaChau"), value: "lokMaChau" },
      ]
    },
    {
      headerId: "outlyingIslands",
      title: t("outlyingIslands"),
      options: [
        { title: t("chekLapKok"), value: "chekLapKok" },
        { title: t("cheungChau"), value: "cheungChau" },
        { title: t("discoveryBay"), value: "discoveryBay" },
        { title: t("hkDisneyland"), value: "hkDisneyland" },
        { title: t("lammaIsland"), value: "lammaIsland" },
        { title: t("maWan"), value: "maWan" },
        { title: t("muiWo"), value: "muiWo" },
        { title: t("ngongPing"), value: "ngongPing" },
        { title: t("pengChau"), value: "pengChau" },
        { title: t("taiO"), value: "taiO" },
        { title: t("tungChung"), value: "tungChung" },
      ]
    },
  ]

  if (id) {
    return (
      <Select
        id={id}
        title={t("district")}
        placeholder={t("selectOption")}
        options={GROUPED_DISTRICT_LIST}
        viewOnly={viewOnly}
        required
        multiLevel={true}
      />
    );
  } else {
    return (
      <CommonSelect
        title={t("district")}
        placeholder={t("selectOption")}
        options={GROUPED_DISTRICT_LIST}
        required
        value={value}
        error={error}
        helperText={helperText}
        onChange={onChange}
        multiLevel={true}
      />
    );
  }
};

export default DistrictSelect;

import SpecialFeeContent from "../../../components/OrderItems/SpecialFeeContent";
import { useTranslation } from "react-i18next";

import ShippingFeeIcon from "../../../assets/ic-shippingFee.svg";
import DiscountIcon from "../../../assets/ic-discount.svg";
import { ResponsiveUI } from "../../../utils/responsive";

export const SPECIAL_FEE_TYPE = {
    DISCOUNT: "discount",
    DELIVERY: "delivery",
};

const SpecialOrder = (props) => {
    const {
        type = "discount",
        data = null,
        cardBaseStyle = null,
        onDelete,
        extraWidthForButton = false,
    } = props;

    const { t } = useTranslation("orderContentItem");


    return (
        <SpecialFeeContent
            sectionTitle={type === SPECIAL_FEE_TYPE.DELIVERY ? t("shippingFee") : t("discountCode")}
            icon={
                //negative margin to offset the box-shadow of svg 
                <ResponsiveUI
                    mobile={

                        <img src={type === SPECIAL_FEE_TYPE.DELIVERY ? ShippingFeeIcon : DiscountIcon}
                            alt={type === SPECIAL_FEE_TYPE.DELIVERY ? "shopping-fee-discount-icon" : "percentange-discount-icon"}
                            style={{ transform: 'translateX(-15px)' }}
                        />

                    }
                    desktop={
                        <><img src={type === SPECIAL_FEE_TYPE.DELIVERY ? ShippingFeeIcon : DiscountIcon}
                            alt={type === SPECIAL_FEE_TYPE.DELIVERY ? "shopping-fee-discount-icon" : "percentange-discount-icon"}
                            style={{ transform: 'translate(-15px, -15px)', width: 120, height: 120 }} />
                        </>

                    }
                />


            }
            type={type}
            data={data}
            cardBaseStyle={cardBaseStyle}
            onDelete={onDelete}
            extraWidthForButton={extraWidthForButton}
            useHtml={false}
        // iconNeedDotDecoration={true}
        />
    )
}

export default SpecialOrder;
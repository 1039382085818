import styled from "styled-components";
import theme from "../theme";
import CommonCheckbox from "./Common/CommonCheckbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "./Typography";
import Price from "./Price/PriceText";
import PriceUnit from "./Price/PriceUnitText";
import OriginalPrice from "./Price/OriginalPrice";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CommonPriceText, formatCurrency } from "../utils/common";
import { ResponsiveUI, breakpoint } from "../utils/responsive";

const Tag = styled.div`
  // position: absolute;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  background: ${theme.paleYellow};
  height: 28px;
  min-width: 90px;
  max-width: 200px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  && {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    color: ${theme.lightBlack};
    font-size: ${theme.fontSize.smallTag};
    font-weight: bold;
  }

  @media ${breakpoint.mobile} {
    padding: 3px 20px; // TODO: to be confirmed
  }
`;

const TagContainer = styled.div`
  // position: relative;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

//TODO - hard code selected
const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  min-height: 120px;
  justify-content: ${props => props.hasTag ? 'space-between' : 'center'};
  box-shadow: ${(props) => props?.selected ? "0 0 10px 0 #dbbd00" : "rgb(201, 201, 201) 0 0 6px 0"};
  width: 100%;
`;

const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.hasTag ? '0 15px 30px' : '15px 30px 15px 15px'};

  @media ${breakpoint.mobile} {
    flex-direction: column;
    gap: 20px;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  @media ${breakpoint.mobile} {
    flex-grow: 1;
    align-items: flex-start;
  }
`;

const PriceLabel = styled(Typography)`
  text-align: right;

  @media ${breakpoint.mobile} {
    text-align: left;
  }
`

const IconContainer = styled.div`
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  // padding: 16px;
  border-radius: 23px;
  box-shadow: 0 0 6px 0 #c9c9c9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  overflow: hidden;

  .MuiSvgIcon-root {
    width: 100%;
    height: 100%;
  }

  img {
    width: 90%;
    height: 90%; 
    object-fit: contain;
    border-radius: 23px;
  }

  @media ${breakpoint.mobile} {
    margin: 0;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;

  @media ${breakpoint.mobile} {
    margin-left: 10px;
  }
`

const LabelItem = (props) => {
  const { label, detailsOnClick, t } = props;

  return (
    <LabelContainer>
      <Typography style={{ fontSize: 20, color: theme.lightBlack, fontWeight: 'bold' }}>
        {label}
      </Typography>
      <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
        <Typography color="dirtyYellow" style={{ cursor: "pointer" }} onClick={detailsOnClick}>
          {t("details")}
        </Typography>
      </div>
    </LabelContainer>
  )
}

const PriceItem = (props) => {
  const { price, priceUnit, originalPrice, t } = props;

  return (
    <PriceContainer>
      <PriceLabel fontSize={theme.fontSize.title4} condensed>
        + HK$ <Price fontSize={theme.fontSize.price}>{formatCurrency(price, 1)}</Price>
        {
          priceUnit && (
            <PriceUnit fontSize={theme.fontSize.priceUnit}>
              /{priceUnit}
            </PriceUnit>
          )
        }
      </PriceLabel>
      {
        originalPrice && (
          <PriceLabel
            color={theme.secondary}
            fontSize={theme.fontSize.originalPrice}
            textAlign="right"
          >
            {`${t("originalPrice")} `}
            <OriginalPrice fontSize={theme.fontSize.originalPrice}>
              HK${formatCurrency(originalPrice, 1)}
            </OriginalPrice>
          </PriceLabel>
        )
      }
    </PriceContainer>
  )
}

function DesktopOption({
  isRecommend=false,
  tagLabel="推薦",
  label,
  price,
  priceUnit,
  originalPrice,
  selected,
  CheckboxProps,
  icon,
  detailsOnClick,
  t,
}) {

  return (
    <Container selected={selected} hasTag={isRecommend}>
      {
        isRecommend && (
        <TagContainer>
          <Tag>{tagLabel}</Tag>
        </TagContainer>
        )
      }

      <BodyContainer hasTag={isRecommend}>
        <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
          <CommonCheckbox {...CheckboxProps} checked={selected} />
          { icon && (
            <IconContainer>
              {icon}
            </IconContainer>
          )}
          <LabelItem label={label} detailsOnClick={detailsOnClick} t={t} />
        </div>
        <PriceItem price={price} priceUnit={priceUnit} originalPrice={originalPrice} t={t} />
      </BodyContainer>
    </Container>
  );
}

function MobileOption({
  isRecommend=false,
  tagLabel="推薦",
  label,
  price,
  priceUnit,
  originalPrice,
  selected,
  CheckboxProps,
  icon,
  detailsOnClick,
  t,
}) {

  return (
    <Container selected={selected} hasTag={isRecommend}>
      {
        isRecommend && (
        <TagContainer>
          <Tag>{tagLabel}</Tag>
        </TagContainer>
        )
      }

      <BodyContainer hasTag={isRecommend}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <CommonCheckbox {...CheckboxProps} checked={selected} />
          <div style={{ display: 'flex', gap: 20, alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
            { icon && (
              <IconContainer>
                {icon}
              </IconContainer>
            )}
            <PriceItem price={price} priceUnit={priceUnit} originalPrice={originalPrice} t={t} />
          </div>
        </div>
        <LabelItem label={label} detailsOnClick={() => detailsOnClick && detailsOnClick()} t={t} />
      </BodyContainer>
    </Container>
  );
}

const Option = (props) => {
  const { t } = useTranslation('common');

  return (
    <ResponsiveUI 
      desktop={<DesktopOption t={t} {...props} />}
      mobile={<MobileOption t={t} {...props} />}
    />
  )
}

export default Option;

Option.propTypes = {
  isRecommend: PropTypes.bool,
  label: PropTypes.string,
  price: PropTypes.string,
  priceUnit: PropTypes.string,
  originalPrice: PropTypes.string,
  selected: PropTypes.bool,
  circle: PropTypes.bool,
};

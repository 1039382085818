import { createContext, useMemo } from "react";

import { useQueries } from "@tanstack/react-query";
import { findIndex } from "lodash";
import {
  fetchCalendarConfig,
  fetchCheckout,
  fetchDeliveryConfig,
  fetchTNC,
} from "../../../apis";

/**
 * @typedef {Object} CheckoutFormContextProps
 * @property {import("@tanstack/react-query").UseQueryResult<any>} quoteQuery
 * @property {import("@tanstack/react-query").UseQueryResult<any>} tncQuery
 * @property {import("@tanstack/react-query").UseQueryResult<any>} configQuery
 * @property {import("@tanstack/react-query").UseQueryResult<any>} calendarQuery
 * @property {boolean} isLoading
 * @property {boolean} isError
 * @property {boolean} isDeviceOnly
 * @property {boolean} hasDevice
 * @property {boolean} hasPlan
 */



/**
 * @type {CheckoutFormContextProps}
 */
const defaultContext = {}

export const CheckoutFormContext = createContext(defaultContext)

export default function CheckoutFormProvider({ children }) {

  const [quoteQuery, tncQuery, configQuery, calendarQuery] = useQueries({
    queries: [
      {
        queryKey: ["fetchQuote"],
        queryFn: fetchCheckout,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always'
      },
      {
        queryKey: ["fetchTnc"],
        queryFn: fetchTNC,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always'
      },
      {
        queryKey: ["fetchDeliveryConfig"],
        queryFn: fetchDeliveryConfig,
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["fetchCalendarConfig"],
        queryFn: fetchCalendarConfig,
        refetchOnWindowFocus: false
      },
    ],
  });

  const isDeviceOnly = useMemo(() => {
    return findIndex(quoteQuery?.data?.data?.items, ["type", "plan"]) < 0
  }, [quoteQuery?.data?.data?.items])

  const hasDevice = useMemo(() => {
    return findIndex(quoteQuery?.data?.data?.items, ["type", "product"]) >= 0
  }, [quoteQuery?.data?.data?.items])

  const hasPlan = useMemo(() => {
    return findIndex(quoteQuery?.data?.data?.items, ["type", "plan"]) >= 0
  }, [quoteQuery?.data?.data?.items])

  const isLoading = quoteQuery.isLoading || tncQuery.isLoading || configQuery.isLoading || calendarQuery.isLoading
  const isError = quoteQuery.isError || tncQuery.isError || configQuery.isError || calendarQuery.isError




  return (
    <CheckoutFormContext.Provider value={{
      quoteQuery,
      tncQuery,
      configQuery,
      calendarQuery,
      isLoading,
      isError,
      isDeviceOnly,
      hasDevice,
      hasPlan
    }}>
      {children}
    </CheckoutFormContext.Provider>
  );
}


import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";

const Container = styled.div`
    padding: 30px 50px;

    @media ${breakpoint.mobile} {
        padding: 30px 25px;
    }
`

const CardWrapper = (props) => {
    const { style, children } = props;

    return (
        <Container style={{...style}}>
            {children}
        </Container>
    )
}

export default CardWrapper;
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { removeTokens, setTokens } from "../utils/localStorage";
import { getAccessToken, getDecodedTokenValue, guestLoginIn, refreshAccessToken } from "./auth";

const axiosInstance = axios.create({});


axiosInstance.interceptors.request.use(async (request) => {
  try {
    const token = await getAccessToken();
    request.headers["Authorization"] = `Bearer ${token}`;
    return request;
  } catch (e) {
    return request;
  }
});

const refreshAuthLogic = async (failedRequest) => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const decodedValue = getDecodedTokenValue()
    const isGuest = decodedValue?.isGuest
    let result
    if (isGuest) {
      result = await guestLoginIn()
    } else {
      result = await refreshAccessToken({
        refresh_token: refreshToken
      })
    }


    if (result && result.msg === "success") {
      setTokens(result.data.accessToken, result.data.refreshToken);
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + result.data.accessToken;
    } else {
      throw new Error(JSON.stringify(result));
    }
  } catch (e) {
    console.log("refresh user error - ", e);
    removeTokens();
    window.location = "/";
  }
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);


export default axiosInstance; 
import { useState, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import CustomCardContainer from "./CustomCardContainer";
import Typography from "./Typography";
import theme from "../theme";
import Tag from "./TopCardTag";
import Space from "./Space";
import { ResponsiveUI } from "../utils/responsive";
import _ from "lodash";
import { GetWord } from "../utils/common";

const Face = styled.div`
  position: absolute;
  width: 100%;

  transition: transform 1s;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  bottom: 0;
`;

const FaceFront = styled(Face)``;

const FaceBack = styled(Face)`
  transform: rotateY(180deg);
`;

const Container = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  height: 600px;

  perspective: 800px;

  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const Content = styled(CustomCardContainer)`
  height: 550px;
  display: flex;
  flex-direction: column;
`;

function AnimatedCard({ renderFront, renderBack, height, tag }) {
  const [active, setActive] = useState(false);

  const flip = () => {
    setActive(!active);
  };

  const renderContent = (type) => {
    return (
      <ContentContainer>
        {!_.isEmpty(_.trim(tag?.["en"])) &&
          !_.isEmpty(_.trim(tag?.["zh"])) ? (
          <Tag>{GetWord(tag)}</Tag>
        ) : (
          <ResponsiveUI mobile={<div />} desktop={<Space size="32px" />} />
        )}
        <Content borderRadius={50}>
          {type === "front"
            ? renderFront && renderFront({ flip })
            : renderBack && renderBack({ flip })}
        </Content>
      </ContentContainer>
    );
  };

  return (
    <Container height={height}>
      <Card>
        <FaceFront style={{ transform: active && "rotateY(180deg)" }}>
          {renderContent("front")}
        </FaceFront>
        <FaceBack style={{ transform: active && "rotateY(360deg)" }}>
          {renderContent("back")}
        </FaceBack>
      </Card>
    </Container>
  );
}

export default AnimatedCard;

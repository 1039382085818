import Typography from "../../../components/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { breakpoint, pageMargin } from "../../../utils/responsive";
import EmptyOrderIcon from '../../../assets/ic-emptyOrder.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 100px 0;
    width: 100%;

    @media ${breakpoint.mobile} {
        padding: 70px 0;
    }
`;

const EmptyOrderHistory = () => {
    const { t } = useTranslation("profile");

    return (
        <Container justifyContent={"center"}>
            <Typography fontSize={24} fontWeight={500} style={{ marginBottom: '24px' }}>{t('orderDetails.emptyOrderHint')}</Typography>
            <img src={EmptyOrderIcon} alt="Empty Order History" />
        </Container>
    )
}

export default EmptyOrderHistory;
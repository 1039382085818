import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useState } from "react";
import { fetchDocUploadConfig } from "../../../../apis";

const DocConfigContext = createContext();

export const DocConfigProvider = ({ children }) => {
    const [docConfig, setDocConfig] = useState();

    const { data, isLoading, isError, refetch } = useQuery({
        queryFn: fetchDocUploadConfig,
        onSuccess: (res) => {
            setDocConfig(res?.data)
        },
    })

    return (
        <DocConfigContext.Provider value={{ docConfig }}>
            {children}
        </DocConfigContext.Provider>
    )
}

export const useDocConfig = () => useContext(DocConfigContext);

import { useState } from "react";
import styled from "styled-components";
import Typography from "../../../../components/Typography";
import theme from "../../../../theme";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from "../../../../assets/ic-edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/ic-delete.svg";
import EditAddressForm from "../EditAddressForm";
import EditAddressDialog from "../../../checkout/userInfo/EditAddressDialog";
import AlertDialog from "../../../../components/CommonDialog/AlertDialog";
import _ from "lodash";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
`

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .icon-button {
        cursor: pointer;
    }
`

const AddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 38px;
    flex: 1;
`

const FieldItem = ({ label, value, style }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '11px', ...style }}>
            <Typography style={{ fontSize: '16px', color: theme.lightBlack }}>{label || ``}</Typography>
            <Typography style={{ fontSize: '18px', color: theme.lightBlack, whiteSpace: "pre-line" }} breakWord>{value || ``}</Typography>
        </div>
    )
}

const AddressRecord = (props) => {
    const { data, onEdit=null, onDelete=null } = props;

    const { t } = useTranslation(['profile', 'checkout', 'common']);

    const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

    const handleOnClickEdit = () => {
        setEditDialogIsOpen(true);
    }

    const handleOnClickDelete = () => {
        setDeleteDialogIsOpen(true);
    }

    const handleEditItem = () => {
        onEdit && onEdit(data);
        setEditDialogIsOpen(false);
    }

    const handleDeleteItem = () => {
        onDelete && onDelete(data);
        setDeleteDialogIsOpen(false);
    }

    return (
        <>
        <Container>
            { (onEdit || onDelete) && (
                <ActionButtonContainer>
                    { onEdit && <EditIcon className="icon-button" onClick={handleOnClickEdit} /> }
                    { onDelete && <DeleteIcon className="icon-button" onClick={handleOnClickDelete} /> }
                </ActionButtonContainer>
            )}
            <AddressContainer>
                <FieldItem label={t('profile:accountProfile.address')} value={(data?.address && data?.address.length > 0) ? data?.address?.join("\n") : ""} style={{ flex: 1 }} />
                <FieldItem label={t('profile:accountProfile.district')} value={t(data?.district, { ns: 'checkout' })} />
            </AddressContainer>
        </Container>
        {/* Edit address dialog */}
        <EditAddressDialog
            isOpen={editDialogIsOpen}
            setIsOpen={setEditDialogIsOpen}
            addressId={data?.id}
            addressOne={data?.address?.[0]}
            addressTwo={data?.address?.[1]}
            district={data?.district}
            handleAutoSubmit={handleEditItem}
        />
        {/* Delete confirm dialog */}
        <AlertDialog
            title={t('accountProfile.deleteAddressDialogTitle')}
            isOpen={deleteDialogIsOpen}
            setIsOpen={setDeleteDialogIsOpen}
            type="warning"
            content={t('accountProfile.cannotRecoverAfterDelete')}
            btnLabel={t('common:confirm')}
            handleClose={() => setDeleteDialogIsOpen(false)}
            onNext={handleDeleteItem}
        />
        </>
    )
}

export default AddressRecord;
import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import styled from "styled-components";
import MyTypography from "../Typography";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import Space from "../Space";
import TextField from "@mui/material/TextField";

const MyActionBar = ({ onAccept, onCancel, className, t }) => {
  return (
    <DialogActions className={className}>
      <ActionContainer>
        <TimeContainer>
          <MyTypography bold>{t("time")}</MyTypography>
          <div>
            <TimeButton onClick={onCancel} isActive>
              1:00-4:00
            </TimeButton>
            <TimeButton onClick={onCancel}> 12:00-14:00 </TimeButton>
          </div>
        </TimeContainer>
        <div>
          <ActionButton onClick={onCancel} isActive>
            {t("cancel")}
          </ActionButton>
          <ActionButton onClick={onAccept}> Ok </ActionButton>
        </div>
      </ActionContainer>
    </DialogActions>
  );
};

export default function NewPicker({ title, required }) {
  const { t } = useTranslation("common");
  return (
    <div>
      {title && (
        <MyTypography style={{ display: "inline-block" }}>{title}</MyTypography>
      )}
      {required && (
        <MyTypography
          color={theme.orange}
          style={{ marginLeft: 5, marginRight: 5, display: "inline-block" }}
        >
          *
        </MyTypography>
      )}
      <Space size="7px" />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          style={{ width: "100%" }}
          slots={{
            actionBar: MyActionBar,
          }}
          slotProps={{
            actionBar: {
              t: t,
            },
            textField: {
              style: { width: "100%",  },
              sx: {
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                    borderColor: "#fff",
                    boxShadow: "0 0 6px 0 #c9c9c9",
                  },
                  "&:hover fieldset": {
                    borderColor: "#fff",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#fff",
                  },
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}

const TimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: -15px;
  padding: 0 15px;
`;

const TimeButton = styled(Button)`
  && {
    color: #000;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
    background-color: ${(props) =>
      props.isActive ? "#fff" : "rgba(118, 118, 128, 0.2)"};

    &:hover {
      background-color: rgba(128, 128, 128, 0.5);
    }
  }
`;

const ActionButton = styled(Button)`
  && {
    color: #000;
  }
`;

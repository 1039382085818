import React from 'react'
import { Helmet } from 'react-helmet'

const PageHelmet = (props) => {
    const {
        title,
    } = props

    if (!title) return null;
    return (
        <React.Fragment>
            <Helmet>
                <title>{title}</title>
            </Helmet>
        </React.Fragment>
    )
}

export default PageHelmet;
import styled from "styled-components";
import Container from "../../components/Container";
import Collapse from "../../components/Collapse";
import theme from "../../theme";
import { breakpoint } from "../../utils/responsive";

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 100%;

    @media ${breakpoint.mobile} {
        padding: 0 30px;
    }
`

const ContentBlock = styled.div`
    padding: 30px;
    padding-top: 0;

    * {
        color: ${theme.primary};
    }

    a {
        color: ${theme.dirtyYellow};
    }

    img, video {
        max-width: 100%;
        height: auto;
    }
`

const TabContainer = (props) => {
    const { data, key, locale } = props;

    return (
        <BodyContainer>
            { data?.items && data?.items.length > 0 && data.items.map((item, index) => (
                <Container key={`${key}-ans${index}`} style={{ gap: 0, padding: 0 }}>
                    <Collapse title={item.title?.[locale] || ""} titleChildrenGap={"30px"}>
                        <ContentBlock dangerouslySetInnerHTML={{ __html: item.content?.[locale] }}/>
                    </Collapse>
                </Container>
            ))}
        </BodyContainer>
    )
}

export default TabContainer;
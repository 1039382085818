import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import CommonBlock from "./CommonBlock";
import HKIDMask from "../../../components/HKIDMask";
import { GetWord } from "../../../utils/common";
import { fetchSensitiveInfoById } from "../../../apis";

const ClientInfo = (props) => {
    const { data, index=1, refetch } = props;

    const { t, i18n } = useTranslation(['profile', 'common']);

    const remarkText = useMemo(() => {
        return data?.document?.some((item) => item.status === 'pending') 
            ? `${t('profile:pendingDocument.documentHintPrefix')}${t("profile:pendingDocument.24hours")}${t('profile:pendingDocument.documentHintPostfix')}` 
            : null;
    }, [data?.document, i18n.language])

    return (
        <CommonBlock
            key={`clientInfo-${index}`}
            remarkText={remarkText}
            title={t('pendingDocument.clientInfo')}
            index={index}
            document={data?.document}
            items={[
                { 
                    label: t('pendingDocument.clientName'),
                    value: GetWord(data?.fullName) || data?.fullName?.en
                },
                { label: t('pendingDocument.idType'),
                    value: t(data?.idType, {ns: 'common'})
                },
                {
                    label: t(`pendingDocument.${data?.idType}Number`),
                    _renderField: () => (
                        <HKIDMask
                            maskedValue={data?.idNumber}
                            unMaskQueryKey={["fetchSensitiveInfoById", { id: data?.orderId }]}
                            unMaskQueryFn={() => fetchSensitiveInfoById(data?.orderId)}
                            dataKey="userForm.idNumber"
                        />
                    )},
            ]}
            refetch={refetch}
        />
    )
}

export default ClientInfo;
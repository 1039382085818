import styled from "styled-components";
import theme from "../../theme";
import Typography from "../../components/Typography";
import { breakpoint } from "../../utils/responsive";

const MainTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;
`

const Tag = styled.div`
    border-bottom-left-radius: 25px;
    border-top-right-radius: 25px;
    background: ${theme.paleYellow};
    height: 33px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    && {
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        color: ${theme.lightBlack};
        font-size: ${theme.fontSize.smallTag};
        font-weight: bold;
    }

    @media ${breakpoint.mobile} {
        padding: 3px 20px; // TODO: to be confirmed
    }
`;

const ProductTitle = (props) => {
    const { device, t, locale } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MainTitleContainer>
                <Typography fontSize={30} color={theme.grey} bold condensed>{device.productName?.[locale] || ""}</Typography>
                {/* <Tag>{t('qtyRemain', { qty: 11 })}</Tag> */}
            </MainTitleContainer>
            {/* <Typography style={{ fontSize: '12px', color: '#666666' }}>{`SKU#: ${device.sku}`}</Typography> */}
        </div>
    )
}

export default ProductTitle;
import {
  APPEAR_GLOBAL_ERROR,
  DISAPPEAR_GLOBAL_ERROR,
  UPDATE_USER_INFO,
  SHOW_COOKIE_CONSENT,
  HIDE_COOKIE_CONSENT,
  UPDATE_COOKIE_CONSENT_HEIGHT,
  UPDATE_CART_COUNT,
  HIDE_GREETING_DIALOG,
  SHOW_GREETING_DIALOG,
} from "../actions/globalAction";
import { fetchUserInfo, otpLogout } from "../apis";
import { getAccessToken } from "../apis/auth";
import { setCookie } from "../utils/common";
import { removeTokens } from "../utils/localStorage";

export const appearGlobalError = (dispatch, title, message, options) => {
  dispatch({ type: APPEAR_GLOBAL_ERROR, title, message, options });
};

export const disappearGlobalError = (dispatch) => {
  dispatch({ type: DISAPPEAR_GLOBAL_ERROR });
};


export const updateUserInfo = async (dispatch, userInfo) => {
  try {
    const accessToken = await getAccessToken();

    if (accessToken) {
      const result = await fetchUserInfo();
      dispatch({ type: UPDATE_USER_INFO, userInfo: result?.data });
    } else {
      dispatch({ type: UPDATE_USER_INFO, userInfo: {} });
    }
  } catch (e) {
    console.log("get user info error", e);
    dispatch({ type: UPDATE_USER_INFO, userInfo: {} });
    logout();
  }
};

export const logout = async () => {
  try {
    await otpLogout();


  } catch (e) {
    console.log("error signing out: ", e);
  } finally {
    removeTokens();
    window.location = "/";
  }
}

export const appearCookieConsent = (dispatch) => {
  dispatch({ type: SHOW_COOKIE_CONSENT });
}

export const acceptCookieConsent = (dispatch) => {
  const date = new Date();
  setCookie({ key: "cookieConsent", value: date.toString(), maxAge: 365 * 24 * 60 * 60 * 1000 });
  dispatch({ type: HIDE_COOKIE_CONSENT });
}

export const updateCookieConsentHeight = (dispatch, height) => {
  dispatch({ type: UPDATE_COOKIE_CONSENT_HEIGHT, cookieConsentHeight: height });
}

export const updateCartCount = async (dispatch, cartCount) => {
  dispatch({ type: UPDATE_CART_COUNT, cartCount });
}

export const hideGreetingDialog = (dispatch, lang="en") => {
  dispatch({ type: HIDE_GREETING_DIALOG, lang });
}

export const showGreetingDialog = (dispatch) => {
  dispatch({ type: SHOW_GREETING_DIALOG });
}
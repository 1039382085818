import styled from "styled-components";
import CommonCheckbox from "../Common/CommonCheckbox";
import Space from "../Space";
import { breakpoint, WindowSize } from "../../utils/responsive";

const BubbleCheckbox = ({
  label,
  disabled,
  disableChange,
  circle,
  checked,
  onChange,
  fullWidth,
  ...props
}) => {
  const isMobile = WindowSize() === "mobile";
  const labelFontSize = isMobile ? "16px" : "22px";

  return (
    <>
      {disabled ? (
        <DisableContaienr fullWidth={fullWidth}>
          <Space width="10px" />
          <CommonCheckbox
            label={label}
            disabled
            circle={circle}
            checked={checked}
            onChange={onChange}
            typographyProps={{ style: { fontSize: labelFontSize } }}
          />
        </DisableContaienr>
      ) : (
        <Container checked={checked} fullWidth={fullWidth} {...props}>
          <Space width="10px" />
          <CommonCheckbox
            label={label}
            circle={circle}
            checked={checked}
            onChange={onChange}
            disabled={disableChange}
            typographyProps={{ style: { fontSize: labelFontSize } }}
          />
        </Container>
      )}
    </>
  );
};

const CommonContainer = styled.div`
  height: auto;
  min-width: max-content;
  width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
  padding: 9px 0px;
  border-radius: 20px;
  display: flex;

  @media ${breakpoint.mobile} {
    width: 100%;
    padding: 0;
    border-radius: 12px;
  }
`

const Container = styled(CommonContainer)`
  box-shadow: ${(props) =>
    props.checked ? "0 0 10px 0 #dbbd00" : "0 0 10px 0 #c9c9c9"};
`;

const DisableContaienr = styled(CommonContainer)`
  box-shadow: 0 0 10px 0 #c9c9c9;
  background-color: #f2f2f2;
  opacity: 0.5;
`;

export default BubbleCheckbox;

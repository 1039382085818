import { useState, useEffect, useMemo, useContext } from "react";
import Header from "../components/Header";
import OrderList from "../pageComponents/shoppingcart/OrderList";
import Tab from "../components/Tab";
import styled from "styled-components";
import SuggestItem from "../pageComponents/shoppingcart/SuggestItem";
import TotalPriceBar from "../components/BottomBar/DevicePriceBar";
import ComboOffer from "../pageComponents/shoppingcart/comboOffer";
import PageHeader from "../components/Header";
import { useTranslation } from "react-i18next";
import { breakpoint } from "../utils/responsive";
import { useQueries } from "@tanstack/react-query";
import { fetchCart, prepareCheckout, fetchRecommendList, fetchDeliveryConfig } from "../apis";
import LoadingIndicator from "../components/LoadingIndicator";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { gaViewCart } from "../utils/ga";
import { gtmViewCart } from "../utils/gtm";
import { formatQuoteProductForGA } from "../utils/common";
import { gaAddPaymentInfo } from "../utils/ga";
import { gtmAddPaymentInfo } from "../utils/gtm";
import { appearGlobalError, updateCartCount } from "../requests/globalRequest";
import { GlobalContextStore } from "../context/GlobalProvider";

const ShoppingCart = () => {
  const { t } = useTranslation("shoppingCart");
  const navigate = useNavigate();

  const { globalDispatch } = useContext(GlobalContextStore);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [cartData, configData] = useQueries({
    queries: [
      {
        queryKey: ["fetchCart"],
        queryFn: () => {
          const cartPromise = fetchCart();
          cartPromise.then((result) => {
            if (isFirstLoad) {
              const payload = result?.data?.cart?.items.map((item, index) =>
                formatQuoteProductForGA({ ...item, sort: index })
              ) || [];

              gaViewCart(payload);
              gtmViewCart(payload);
              setIsFirstLoad(false);
            }

          });
          return cartPromise;
        },
        onSuccess: (data) => {
          // update cart count
          updateCartCount(
            globalDispatch,
            data?.data?.cart?.items?.length || 0
          );
        },
      },
      {
        queryKey: ["fetchDeliveryConfig"],
        queryFn: fetchDeliveryConfig,
      },
    ],
  });

  const handleClickCheckoutButton = async () => {
    try {
      const result = await prepareCheckout();
      if (result) {
        // trigger add_payment_info ga event
        const gaPayload = result?.data?.items.map((item, index) => formatQuoteProductForGA({ ...item, sort: index })) || [];
        gaAddPaymentInfo(gaPayload, result?.data?.promoCode);
        gtmAddPaymentInfo(gaPayload, result?.data?.promoCode);

        navigate("/checkout", { state: { enableAccess: true } });
      }
    } catch (e) {
      console.log("prepare checkout error - ", e);
      appearGlobalError(
        globalDispatch,
        e?.code,
        e?.message,
        { type: "fail" }
      )
    }
  };

  const deliveryFeeRequired = useMemo(() => {
    const cartItems = cartData?.data?.data?.cart?.items || [];
    if (cartItems.length === 0) return false;
    const isContainProduct = cartItems.filter(item => item?.type === "product").length > 0;
    const freeShippingMinSpent = configData?.data?.data?.find(item => item.keyPath === "delivery.freeShippingMinSpent")?.value;
    return isContainProduct ? cartData?.data?.data?.cart?.discountedTotalAmount < freeShippingMinSpent : false;
  }, [cartData?.data?.data?.cart, configData?.data?.data]);

  const shippingFee = configData?.data?.data?.find(item => item?.keyPath === "delivery.deliveryFee")?.value || 0;

  if (cartData.isLoading || configData.isLoading) {
    return <LoadingIndicator />;
  }

  if (cartData.isError || configData.isError) {
    //TODO - api error handle
    console.log("error - ", cartData.isError);
  }

  return (
    <div>
      <PageHeader>{t("shoppingCart")}</PageHeader>
      <Container>
        <Wrapper>
          <Tab
            tabs={[
              {
                label: `${t("allItem")} (${(cartData?.data?.data?.cart?.items || []).length
                  })`,
                render: () => (
                  <OrderList
                    data={cartData?.data?.data?.cart?.items || []}
                    refetch={cartData.refetch}
                    deliveryFeeRequired={deliveryFeeRequired}
                    shippingFee={shippingFee}
                    shippingName={configData?.data?.data?.find(item => item?.keyPath === "delivery.displayName")?.value}
                  />
                  // <div style={{ width: 1000, height: 500, background: 'yellow' }}>
                  //   mock item
                  // </div>
                ),
              },
            ]}
            tabStyle={{
              fontSize: "18px",
            }}
            tabGap={0}
          />
        </Wrapper>
      </Container>
      {/* <ComboOffer /> */}
      {!_.isEmpty(cartData?.data?.data?.recommendItems) && (
        <SuggestItem data={cartData?.data?.data?.recommendItems} />
      )}
      <TotalPriceBar
        totalPrice={cartData?.data?.data?.cart?.discountedTotalAmount + (deliveryFeeRequired ? shippingFee : 0)}
        onClick={handleClickCheckoutButton}
        isCheckout
        disabled={(cartData?.data?.data?.cart?.items || []).length === 0}
        titleShowTotal
      />
    </div>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;

  @media ${breakpoint.desktop} {
    padding: 50px 100px;
  }

  @media ${breakpoint.mobile} {
    margin-top: 50px;
  }
`;

const Wrapper = styled.div`
  align-self: center;
  width: 100%;
  max-width: 1440px;
`

export default ShoppingCart;

import { Link } from "@mui/material";
import styled from "styled-components";
import theme from "../theme";

const MuiLink = styled(Link)`

    &.MuiLink-root {
        font-family: 'Roboto';
        color: ${theme.dirtyYellow} !important;
        text-decoration-color: ${theme.dirtyYellow} !important;
        cursor: pointer;
    }
`

const StyledLink = (props) => {
    return (
        <MuiLink color={'primary'} {...props} />
    )
}

export default StyledLink;
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Grid, List, ListItem, ListItemText } from "@mui/material";
import DetailButton from "../../../components/Buttons/DetailButton";
import Form from "./Form";
import CardBase from "../../../components/Card/CardBase";
import MyTypography from "../../../components/Typography";
import theme from "../../../theme";

const StyledListItemText = styled(ListItemText)`
  font-size: 16px;
  color: ${theme.primary};
`;

const DocumentList = [
  { id: "hkid", label: "supportingDocumentHKID" },
  { id: "addressProof", label: "supportingDocumentAddressProof" },
]

const SupportingDoc = () => {
  const { t } = useTranslation("checkout");
  return (
    <Form title={t("supportingDocument")} isCollapsible={false}>
      <Grid paddingX={{ xs: "25px", md: "30px" }}>
        <MyTypography>{t("supportingDocumentRemark")}</MyTypography>
        <List sx={{ listStyle: "disc", pl: 4 }}>
          { DocumentList.map((item) => (
            <ListItem sx={{ display: "list-item", p: 0 }} key={item.id}>
              <StyledListItemText>{t(item.label)}</StyledListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Form>
  );
};

export default SupportingDoc;

import ItemTableRow from "../../components/OrderItems/ItemTableRow";
import { Divider } from "@mui/material";
import styled from "styled-components";
import Space from "../../components/Space";
import { ResponsiveUI, WindowSize } from "../../utils/responsive";
import { GetWord, formatCurrency, getItemColumnWidth } from "../../utils/common";
import _ from "lodash";
import { useTranslation } from "react-i18next";




const PlanDetail = ({ data, extraWidthForButton }) => {
  const { t } = useTranslation("common");

  const colWidth = getItemColumnWidth("plan");

  const getContract = () => {
    const contractPeriodId = data?.options?.contractPeriodId;
    const contractInfo = data?.plan?.contractPeriodDetails;

    return _.find(contractInfo, ["_id", contractPeriodId]);
  };

  return (
    <div>
      <ResponsiveUI
        desktop={
          <DividerContainer>
            <Divider style={{ width: "calc(100% - 170px)" }} />
          </DividerContainer>
        }
        mobile={
          <>
            <Divider />
            <Space size="20px" />
          </>
        }
      />

      {/* Plan */}
      <ItemTableRow
        row={[
          GetWord(data?.plan?.planName),
          GetWord(data?.plan?.localData),
          `${getContract()?.period} ${t("months")}`,
          data?.quantity,
          `HK$ ${formatCurrency(getContract()?.discountedPrice, 1)}`,
          `HK$ ${formatCurrency(
            getContract()?.discountedPrice * data?.quantity,
            1
          )}`,
        ]}
        colWidth={colWidth}
        extraWidthForButton={extraWidthForButton}
        extraIconWidth={true}
      />

      {/* Free vas */}
      {data?.options?.freeVas?.map((vas) => {
        return (
          <ItemTableRow
            row={[
              GetWord(vas?.name),
              GetWord(vas?.detail),
              `${getContract()?.period} ${t("months")}`,
              data?.quantity,
              `HK$ ${formatCurrency(0, 1)}`,
              `HK$ ${formatCurrency(0, 1)}`,
            ]}
            colWidth={colWidth}
            extraWidthForButton={extraWidthForButton}
            extraIconWidth={true}
          />
        );
      })}

      {/* Paid vas */}
      {data?.options?.paidVas?.map((vas) => {
        return (
          <ItemTableRow
            row={[
              GetWord(vas?.name),
              GetWord(vas?.detail),
              `${getContract()?.period} ${t("months")}`,
              data?.quantity,
              `HK$ ${formatCurrency(vas?.discountedPrice, 1)}`,
              `HK$ ${formatCurrency((vas?.discountedPrice || 0) * data?.quantity, 1)}`,
            ]}
            colWidth={colWidth}
            extraWidthForButton={extraWidthForButton}
            extraIconWidth={true}
          />
        );
      })}
    </div>
  );
};

const DividerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px 30px 0px;
`;

export default PlanDetail;

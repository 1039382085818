import { request } from '.';
import { jwtDecode } from "jwt-decode";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getAccessToken = async () => {

  return localStorage.getItem("accessToken");
};

export const getDecodedTokenValue = async () => {
  try {
    const token = await getAccessToken();
    return jwtDecode(token)
  } catch (e) {
    return null
  }
}

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

const guestLoginIn = () => {
  return request({
    method: 'post',
    url: `${API_ENDPOINT}/user/loginGuest`,
  });

  // return Promise.resolve({
  //   data: {
  //     accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0d1ZXN0Ijp0cnVlLCJpYXQiOjE3MjQzMDc3ODV9.pjLqmyatQQ-r5w01K1_u610JzUV9pfALez5VQmFR4fU',
  //        refreshToken: 'foo'
  //   }

  // })
};

const requestLoginOtp = (body) => {
  return request({
    method: 'post',
    url: `${API_ENDPOINT}/user/requestLoginOTP`,
    data: body,
  });
};

const otpLogin = (body) => {
  return request({
    method: 'post',
    url: `${API_ENDPOINT}/user/loginWithOTP`,
    data: body,
  });
};
const refreshAccessToken = (body) => {
  return request({
    method: 'post',
    url: `${API_ENDPOINT}/user/oauth/token`,
    data: body,
  });
};


export { guestLoginIn, requestLoginOtp, otpLogin, refreshAccessToken };

import { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { breakpoint } from "../utils/responsive";
import { fetchTutorial } from "../apis";
import { useQuery } from "@tanstack/react-query";

import PageHeader from "../components/Header";
import Tab from "../components/Tab";
import LoadingIndicator from "../components/LoadingIndicator";

import TabContainer from "../pageComponents/shoppingGuide/TabContainer";

const Container = styled.div`
    padding: 50px 100px;

    @media ${breakpoint.mobile} {
        padding: 30px 0;
    }
`

const TABS = [
    "account",
    "delivery",
    "payment",
    "readyToBuy",
]

const ShoppingGuide = () => {
    const { t, i18n } = useTranslation("shoppingGuide");

    const { data, isLoading, isError } = useQuery({
        queryKey: ["tutorial"],
        queryFn: fetchTutorial,
    }) 

    const findDataByKey = (key) => {
        if (data?.data?.length > 0) {
            return data.data?.find((item) => item?._id === key);
        } else return [];
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }
    
    if (isError) {
        //TODO - api error handle
        console.log("error - ", isError);
    }

    return (
        <div>
            <PageHeader>{t("shoppingGuide")}</PageHeader>
            <Container>
                <Tab 
                    tabs={TABS.map((key) => ({
                        label: t(key),
                        render: () => <TabContainer locale={i18n.language} key={key} data={findDataByKey(key)} />
                    }))}
                    tabStyle={{ fontSize: "16px" }}
                    tabGap={"35px"}
                />
            </Container>
        </div>
    )
}



export default ShoppingGuide;
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContextStore } from "../context/GlobalProvider"
import { useTranslation } from "react-i18next";
import { hideGreetingDialog, showGreetingDialog } from "../requests/globalRequest";
import { isEmpty } from "lodash";

export const useGreetingDialog = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const { i18n } = useTranslation();
    const lang = i18n.language;

    const { globalState, globalDispatch } = useContext(GlobalContextStore);
    const isDialogOpen = globalState?.isGreetingDialogOpen || false;

    const handleOpen = (language=lang) => {
        // if not yet initialized or logged in, do nothing
        if (!globalState?.isInitialized || !isEmpty(globalState?.userInfo)) return;

        // if dialog is already previously opened, do nothing
        if (globalState?.greetingDialogLastOpen?.[language]) return;

        showGreetingDialog(globalDispatch);
    }

    const handleClose = () => {
        hideGreetingDialog(globalDispatch, lang);
    }

    const handleClickLogin = () => {
        handleClose();
        navigate("/login", {
            replace: true,
            state: { from: location },
            search: location?.search || ""
        });
    }

    // when user scroll page and greeting dialog is never opened, open the dialog
    useEffect(() => {
        if (!globalState?.greetingDialogLastOpen?.[lang] && !isDialogOpen) {
            window.addEventListener("scroll", handleOpen);
            return () => {
                window.removeEventListener("scroll", handleOpen);
            }
        }
    }, [globalState.greetingDialogLastOpen, isDialogOpen]);

    return {
        isDialogOpen,
        handleClickLogin,
        handleOpenDialog: handleOpen,
        handleCloseDialog: handleClose
    }
}
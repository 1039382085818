import React, { useEffect, useState } from 'react'
import MyButton from '../Button'

const CountdownButton = ({
  interval = 60,
  onClick,
  onError,
  disabled,
  children,
  ...otherProps
}) => {
  const [timer, setTimer] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1)
      }
    }, 1000)
    return () => clearTimeout(timeout)
  }, [timer])

  const handleClickWithTimeout = async () => {
    try {
      if (onClick) {
        setLoading(true)
        await onClick()
        setTimer(interval)
      }
    } catch (e) {
      console.log("error on requesting, shouldnt trigger timer", e)
      onError && onError(e);
    } finally {
      setLoading(false)
    }

  }
  return (
    <MyButton
      fullWidth
      onClick={handleClickWithTimeout}
      disabled={disabled || loading || timer > 0}
      {...otherProps}
    >
      { timer > 0 ? `${timer}s` : children }
    </MyButton>
  )
}

export default CountdownButton
import { useEffect } from 'react';
import styled from 'styled-components';
import { Divider, Grid } from '@mui/material';
import Space from '../../../../components/Space';
import { useFormikContext, useField } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '../../../../components/Typography';
import { TIME_SLOT } from '../../form/DeliveryInformation';
import _ from 'lodash';
import { ResponsiveUI, WindowSize, breakpoint } from '../../../../utils/responsive';

const PreviewNumber = ({ numbers, isNewNumber=false, newNumberEffectiveDate, t }) => {

  const isMobile = WindowSize() === 'mobile'

  return (
    <Grid
      container
      item
      xs={12}
      md={6}
      style={{
        borderRight: isNewNumber && !isMobile ? '1px solid #e0e0e0' : 'none',
        padding: isMobile ? "30px 25px" : 40,
        alignItems: 'flex-start',
        minHeight: isMobile ? undefined: 300
      }}
      gap={"50px"}
    >
      <Grid container item xs={12}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={true}>
            <Typography fontSize="20" mFontSize={16} fontWeight={500}>
              {isNewNumber ? t('newNumber') : t('mobilePortin')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize="20" fontWeight={500}>{t('qty')}</Typography>
          </Grid>
          <Grid item>
            <Typography fontSize="46" fontWeight={500} style={{ marginLeft: 15 }}>
              {numbers?.length}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item spacing={2} sx={{ marginTop: 0 }}>
          {numbers?.map((num) => {
            return (
              <Grid item xs="auto" align="flex-start">
                <Number>
                  <Typography fontSize="22" bold>
                    {num?.number}
                  </Typography>
                </Number>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

const PreviewPlanDetail = ({ name }) => {
  const { t } = useTranslation('checkout');
  const [field, meta, helpers] = useField(`${name}`);
  const { values } = useFormikContext();
  const isMobile = WindowSize() === 'mobile';

  return (
    <Container>
      <Divider />
      <Grid container flexDirection={{ xs: "column", "md": "row" }}>
        <PreviewNumber
          numbers={field.value.newNumbers}
          t={t}
          isNewNumber
          newNumberEffectiveDate={field?.value?.newNumberEffectiveDate}
        />
        <Divider />
        <PreviewNumber numbers={field.value.mnpNumbers} t={t} />
      </Grid>

      <ResponsiveUI
        desktop={null}
        mobile={(field.value?.newNumbers && field.value.newNumbers?.length > 0) && (
          <>
          <Divider />
          <EffectiveDate>
            <Typography>{t("effectiveDateTime")}</Typography>
            <Space width="35px" />
            <div style={{ display: "flex" }}>
              <Typography fontSize={18} bold>{field?.value?.newNumberEffectiveDate}</Typography>
              {/* <Space width="5px" />
              <Typography>
                {
                  _.find(TIME_SLOT, [
                    "value",
                    field?.value?.newNumberEffectiveTime,
                  ])?.title
                }
              </Typography> */}
            </div>
          </EffectiveDate>
          </>
        )}
      />

      {
        field.value.isAutoRenewal && <Grid item>
          <Divider />
          <Typography style={{ fontWeight: 600, padding: isMobile? "30px 25px" : "35px" }}>
            {t('autoRenewal')}
          </Typography>
        </Grid>
      }

      {/**check this paymentMethod */}
      <Grid item xs>
        <Divider />
        <Space size="35px" />
        <Typography style={{ padding: isMobile ? "0 25px" : "0 35px" }}>
          <Trans
            i18nKey="checkout:creditCardAutoRenew"
            components={[<b style={{ textDecoration: 'underline' }} />]}
          />
        </Typography>
        <Space size="35px" />
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;

  @media ${breakpoint.desktop} {
    margin-top: 40px;
  }
`;

const Number = styled(Grid)`
  height: auto;
  width: 100%;
  padding: 15px 50px;
  box-shadow: 0 0 10px 0 #c9c9c9;
  border-radius: 20px;
  max-width: 200px;
`;

const Content = styled.div`
  padding: 50px;
`;

const EffectiveDate = styled.div`
  display: flex;
  padding: 35px;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    padding: 30px 25px;
  }
`;

export default PreviewPlanDetail;

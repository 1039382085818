import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import styled from "styled-components";
import theme from "../theme";

export const GeneralButton = styled(Button)`
  && {
    color: #fff;
    border-radius: 0px;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 25px;
    font-size: ${(props) => props.fontSize ? props.fontSize : `${theme.fontSize.button}`}px;
    text-transform: none;
    padding: 8px 50px;
    width: ${(props) => (props.fullWidth ? "100%" : props?.width || "auto")};
    height: ${(props) => props.size ? (
    props.size === "small" ? "40px"
      : props.size === "medium" ? "42px"
        : props.size === "large" ? "52px"
          : "48px"    // standard size
  ) : "48px"};
  }
`;

const GradientButton = styled(GeneralButton)`
  && {
    background: ${(props) => (props.bg_Color ? props.bg_Color : "#3e3e3e")};
    text-shadow: 0px 0px 6px rgba(0,0,0,0.16);
    white-space: nowrap;
    width: ${(props) => (props.fullWidth ? "100%" : props?.width || "auto")};

    ${props => props?.gradientColor === "yellow" && `color: ${theme.lightBlack};`}
  }
`;

const DisabledButton = styled(GradientButton)`
  && {
    opacity: 0.3;
    // color: #fff;
    cursor: not-allowed;
    white-space: nowrap;

    :hover {
      background: ${(props) => (props.bg_Color ? props.bg_Color : "#3e3e3e")};
    }
  }
`;

const SecondaryButton = styled(GradientButton)`
  && {
    color: #333;
    background: #ebebeb;

    :hover {
      background: #c4c4c4;
    }
  }
`;

const colors = {
  black: theme.blackGradientColor,
  white: theme.white,
  yellow: theme.tagGradientColor,
};

const MyButton = ({ disabled, color, type, ...props }) => {
  const getColor = (color) => {
    if (color) {
      return colors[color];
    } else {
      return "linear-gradient(to right, #333, #747678)";
    }
  };

  if (disabled) {
    return <DisabledButton gradientColor={color} bg_Color={getColor(color)} {...props} onClick={() => { }} />;
  } else if (type === "secondary") {
    return <SecondaryButton {...props} />;
  }
  return <GradientButton gradientColor={color} bg_Color={getColor(color)} {...props} />;
};

export default MyButton;

Button.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

// Button.defaultProps = {
//   onClick: undefined,
// };

import { Grid, createTheme, ThemeProvider } from "@mui/material";
import Typography from "../../components/Typography";
import theme from "../../theme";
import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0;

    @media ${breakpoint.mobile} {
        margin: 0 30px;
    }
`

const sample_long_content = `(support dual 5G)

2G GSM: GSM850,GSM900,DCS1800,PCS1900

3G WCDMA: B1,B2, B5,B8

4G TDD-LTE: B34,B38,B39,B40,B41

5G Sub 6 FDD - N1, N5`

const SpecFieldItemContainer = styled(Grid)`
    padding: 28px 0;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
        border-bottom: none;
    }

    @media (min-width: ${theme.size.desktop}px) {
        &:nth-last-child(-n+2) {
            border-bottom: none;
        }
    }
`

const StyledTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            md: theme.size.desktop,
        }
    }
})

const SpecFieldItem = (props) => {
    const {
        label="",
        value="",
    } = props;

    return (
        <SpecFieldItemContainer item md={5.5} container gap={{ xs: 1, md: '43px' }}>
            <Grid item xs={3}><Typography style={{ fontSize: '18px', fontWeight: 'bold', color: theme.lightBlack }}>{label}</Typography></Grid>
            <Grid item xs><Typography style={{ fontSize: '16px', color: theme.lightBlack, lineHeight: '30px' }}>{value}</Typography></Grid>
        </SpecFieldItemContainer>
    )
}

const ProductSpec = (props) => {
    const { data } = props;

    const sample_spec_field_item = [
        { label: '品牌', value: 'Apple' },
        { label: '網絡制式', value: sample_long_content },
        { label: '內置記憶體 (RAM)', value: '3088 x 1440' },
        { label: '螢幕尺寸', value: '6.7"' },
    ]
    //TODO - use api data
    return (
        <ThemeProvider theme={StyledTheme}>
            <Container>
                <Grid item>
                    <Typography style={{ fontSize: '22px', fontWeight: 'bold', color: theme.lightBlack }}>產品規格</Typography>
                </Grid>
                <Grid item container columnGap={1} justifyContent={'space-between'} direction={{ xs: 'column', md: 'row' }} padding={{ xs: '0', md: '0 25px' }}>
                    {
                        sample_spec_field_item.map((item, index) => {
                            return (
                                <SpecFieldItem key={`product_spec-${index}`} label={item.label} value={item.value} />
                            )
                        })
                    }
                </Grid>
            </Container>
        </ThemeProvider>
    )
}

export default ProductSpec;
import { Divider, Grid } from '@mui/material';
import classNames from 'classnames';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import usePlanViewModel from '../../../hooks/usePlanViewModel';
import PreviewPlanDetail from '../orderList/planDetail/PreviewPlanDetail';
import theme from '../../../theme';
import { isHtml } from '../../../utils/common';
import { ResponsiveUI, breakpoint } from '../../../utils/responsive';
import CardBase from '../../../components/Card/CardBase';
import Icon from '../../../components/Icon';
import Typography from '../../../components/Typography';
import DownIcon from '../../../assets/ic-downYellow.svg';
import MobileIcon from '../../../assets/ic-mobilePlanCard.svg';
import UpIcon from '../../../assets/ic-upYellow.svg';
import ItemFieldDetails from '../../../components/OrderItems/ItemFieldDetails';

const HeaderWithNumber = ({ data, itemSeq }) => {
  const { planNumberRows } = usePlanViewModel(data);
  const { t } = useTranslation(['orderContentItem', 'common']);
  return (
    <ContainerPadding>
      <ResponsiveUI
        desktop={
          <>
            <StyledTitle>{t('item', { index: itemSeq + 1 })}</StyledTitle>
            <GridContainer className="header-with-number">
              <Typography>{t('orderContentItem:mobileNumber')}</Typography>
              <Typography>{t('selectedDetails')}</Typography>
              <Typography>{t('contractPeriod')}</Typography>
              <Typography>{t('quantity')}</Typography>
              <Typography>{t('monthlyFee')}</Typography>
              <Typography className="last-col">{t('total')}</Typography>

              <GridHeaderDivider />

              <PlanNumberContainer>
                {planNumberRows.map((item, index) => (
                  <PlanNumberRow key={index}>
                    <Typography style={{ paddingRight: 40 }}>{item.title}</Typography>
                    <Typography style={{ fontWeight: 700, fontSize: 18 }}>{item.number}</Typography>
                  </PlanNumberRow>
                ))}
              </PlanNumberContainer>
            </GridContainer>
          </>
        }
        mobile={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 32, alignItems: "center" }}>
              <StyledTitle>{t('item', { index: itemSeq + 1 })}</StyledTitle>
              <Typography style={{ marginRight: 25 }}>{t('orderContentItem:mobileNumber')}</Typography>
            </div>
            <Divider />
              <PlanNumberContainer>
                {planNumberRows.map((item, index) => (
                  <PlanNumberRow key={index}>
                    <Typography style={{ paddingRight: 40 }}>{item.title}</Typography>
                    <Typography style={{ fontWeight: 700 }}>{item.number}</Typography>
                  </PlanNumberRow>
                ))}
              </PlanNumberContainer>
          </div>
        }
      />
    </ContainerPadding>
  );
};

const SubtotalPrice = ({ data }) => {
  const {
    summationRow,
    planRow,
    freeVasRows,
    paidVasRows,
    planMobileRow,
    freeVasMobileRows,
    paidVasMobileRows,
  } = usePlanViewModel(data);
  const { t } = useTranslation(['orderContentItem', 'common']);
  const [showDetail, setShowDetail] = useState(false)
  return (
    <>
      <ResponsiveUI
        desktop={
          <ContainerPadding>
            {' '}
            <IconColumn>
              <Icon size={90} borderRadius={30}>
                {data?.plan?.flipCardIconImageUrl ? (
                  <img
                    src={data?.plan?.flipCardIconImageUrl}
                    style={{ objectFit: 'cover' }}
                    alt={data?.plan?.filpCardLabel ?? 'plan icon'}
                  />
                ) : (
                  <img
                    src={MobileIcon}
                    style={{ objectFit: 'cover' }}
                    alt="plan default icon"
                  />
                )}
              </Icon>
            </IconColumn>
            <GridContainer className="subtotal-grid">
              {Object.values(summationRow).map((item, index) =>
                index === 0 ? (
                  <StyledTitle>{item}</StyledTitle>
                ) : (
                  <Typography
                    key={index}
                    className={classNames(
                      'summation-row',
                      index === Object.values(summationRow).length - 1 ? 'last-col' : ''
                    )}
                  >
                    {item}
                  </Typography>
                )
              )}
              <GridHeaderDivider />
              {Object.values(planRow).map((item, index) => (
                <Typography
                  key={index}
                  className={classNames(
                    index === Object.values(planRow).length - 1 ? 'last-col' : ''
                  )}
                >
                  {item}
                </Typography>
              ))}
              {freeVasRows.map((row, rowIndex) =>
                Object.values(row).map((item, index) =>
                  isHtml(item) ? (
                    <ItemFieldDetails item={item} />
                  ) : (
                    <Typography
                      key={index}
                      className={classNames(
                        index === Object.values(row).length - 1 ? 'last-col' : ''
                      )}
                    >
                      {item}
                    </Typography>
                  )
                )
              )}
              {paidVasRows.map((row, rowIndex) =>
                Object.values(row).map((item, index) =>
                  isHtml(item) ? (
                    <ItemFieldDetails item={item} />
                  ) : (
                    <Typography
                      key={index}
                      className={classNames(
                        index === Object.values(row).length - 1 ? 'last-col' : ''
                      )}
                    >
                      {item}
                    </Typography>
                  )
                )
              )}
            </GridContainer>
          </ContainerPadding>
        }
        mobile={
          <>
            <MobileSection style={{ display: 'flex', gap: 20 }}>
              <Icon size={70} borderRadius={25}>
                {data?.plan?.flipCardIconImageUrl ? (
                  <img
                    src={data?.plan?.flipCardIconImageUrl}
                    style={{ objectFit: 'cover' }}
                    alt={data?.plan?.filpCardLabel ?? 'plan icon'}
                  />
                ) : (
                  <img
                    src={MobileIcon}
                    style={{ objectFit: 'cover' }}
                    alt="plan default icon"
                  />
                )}
              </Icon>
              <StyledTitle>{summationRow.planName}</StyledTitle>
            </MobileSection>

            <MobileSection>
              <MobileItemContainer>
                <Typography>{t('selectedDetails')}</Typography>
                <Typography>{summationRow.localData}</Typography>
              </MobileItemContainer>
              <MobileItemContainer>
                <Typography>{t('contractPeriod')}</Typography>
                <Typography>{summationRow.period}</Typography>
              </MobileItemContainer>
              <MobileItemContainer>
                <Typography>{t('quantity')}</Typography>
                <Typography>{summationRow.quantity}</Typography>
              </MobileItemContainer>
              <MobileItemContainer>
                <Typography>{t('monthlyFee')}</Typography>
                <Typography>{summationRow.unitPrice}</Typography>
              </MobileItemContainer>
              <MobileItemContainer>
                <Typography bold>{t('total')}</Typography>
                <Typography bold>{summationRow.totalPrice}</Typography>
              </MobileItemContainer>
            </MobileSection>

            <DetailButton onClick={() => setShowDetail((prev) => !prev)}>
              {
                showDetail ? (
                  <img src={UpIcon} />
                ) : (
                  <img src={DownIcon} />
                )
              }
              <Typography color={theme.dirtyYellow}>
                {t("details")}
              </Typography>
            </DetailButton>
            {showDetail &&
              <>
                <Divider />
                <MobileSection>
                  <MobileItemContainer>
                    <MobileNameItem>
                      <Typography style={{ marginBottom: 10 }}>{planMobileRow.planName}</Typography>
                      <Typography>{planMobileRow.localData}</Typography>
                    </MobileNameItem>
                    <MobilePriceItem>{planMobileRow.totalPrice}</MobilePriceItem>
                  </MobileItemContainer>
                  {freeVasMobileRows.map((row, rowIndex) => (
                    <MobileItemContainer>
                      <MobileNameItem>
                        <Typography style={{ marginBottom: 10 }}>{row.name}</Typography>
                        {isHtml(row.detail) ? <ItemFieldDetails item={row.detail} /> : <Typography>{row.detail}</Typography>}
                      </MobileNameItem>
                      <MobilePriceItem>{row.totalPrice}</MobilePriceItem>
                    </MobileItemContainer>
                  ))}
                  {paidVasMobileRows.map((row, rowIndex) => (
                    <MobileItemContainer>
                      <MobileNameItem>
                        <Typography style={{ marginBottom: 10 }}>{row.name}</Typography>
                        {isHtml(row.detail) ? <ItemFieldDetails item={row.detail} /> : <Typography>{row.detail}</Typography>}
                      </MobileNameItem>
                      <MobilePriceItem>{row.totalPrice}</MobilePriceItem>
                    </MobileItemContainer>
                  ))}
                </MobileSection>
              </>
            }

          </>

        }
      />
    </>
  );
};

const CheckoutPlanContent = ({
  renderDetail,
  viewOnly,
  orderRecord,
  onEdit,
  onDelete,
  remarkProps,
  iconNeedDotDecoration,
  data,
  idx,
  cardBaseStyle,
  extraWidthForButton = false,
}) => {
  const { t } = useTranslation(['orderContentItem', 'common']);
  const [showDetail, setShowDetail] = useState(true);

  return (
    <>
      <CardBase style={{ ...cardBaseStyle }}>
        <HeaderWithNumber data={data} itemSeq={idx} />
        <Divider />
        <SubtotalPrice data={data} />
        <PreviewPlanDetail name={`order_list[${idx}]`} />
      </CardBase>
    </>
  );
};

const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: ${theme.grey} !important;
`;

const PlanNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${breakpoint.mobile} {
    align-items: end;
    margin-top: 32px;
    margin-right: 25px;
  }
`

const GridHeaderDivider = styled(Divider)`
  grid-column: 1 / span 6;
`;

const ContainerPadding = styled.div`
  padding: 26px 50px;
  padding-right: 0;
  display: flex;

  @media ${breakpoint.desktop}{ 
    > :first-child {
      width: 120px;
    }
  }
 

  @media ${breakpoint.mobile} {
    padding: 30px 25px;
    padding-right: 0;
    display: block;
  }
`;

const GridContainer = styled.section`
  flex: 1;
  display: grid;
  //grid-template-columns: 4fr repeat(5, 10%);
  grid-template-columns: 45% 10% 10% 7% 14% 14%;
  align-items: start;
  row-gap: 30px;
  //column-gap: 20px;

  .subtotal-grid {
    grid-template-rows: 80px auto;
  }

  .summation-row {
    font-size: 18px;
    min-height: 90px;
  }
  > * {
    padding-right: 5px;
    color: ${theme.primary}
  }
  .last-col {
    font-weight: bold;
    padding-right: 40px;
  }
`;

const IconColumn = styled.span`
  display: flex;
  width: 120px;
`;

const PlanNumberRow = styled.div`
  display: flex;
  align-items: center;
`;

const MobileItemContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  gap: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DetailButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  @media ${breakpoint.mobile} {
    justify-content: center;
    margin-bottom: 30px;
  }
`;

const MobileNameItem = styled.div`
  width: 60%;
`

const MobilePriceItem = styled(Typography)`
  font-weight: bold;
  white-space: nowrap;
`

const MobileSection = styled.section`
  padding: 30px 25px;
`

export default CheckoutPlanContent;

import { useState } from "react";
import { Grid } from "@mui/material";
import Typography from "../../../../components/Typography";
import styled from "styled-components";
import theme from "../../../../theme";
import { WindowSize } from "../../../../utils/responsive";

const NumberContainer = styled.div`
    padding: 15px 50px;
    border-radius: 15px;
    box-shadow: ${props => props?.selected ? "0 0 10px 0 #dbbd00" : "0 3px 10px 0 #c9c9c9"};
    background-color: #fff;

    cursor: ${props => props?.viewOnly ? "default" : "pointer"}};
    user-select: ${props => props?.viewOnly ? "auto" : "none"};
`

const PlanNumberItem = (props) => {
    const { key, number="98765432", selected=false, onClick, viewOnly } = props;

    const handleOnClick = () => {
        onClick && onClick(number);
    }
    
    const prettifyNumber = (number) => {
        return number.replace(/(\d{4})/g, "$1 ");
    }

    return (
        <NumberContainer key={key} selected={selected} onClick={handleOnClick} viewOnly={viewOnly}>
            <Typography color={theme.lightBlack} fontSize={24} style={{ fontWeight: 500, textWrap: 'nowrap' }}>{prettifyNumber(number)}</Typography>
        </NumberContainer>
    )
}

const NumberBlock = (props) => {
    const { type, data, viewOnly=false, title, description, t } = props;

    const [selectedNumber, setSelectedNumber] = useState([]);

    const isMobile = WindowSize() === 'mobile';

    const handleNumberOnClick = (number) => {
        if (selectedNumber.includes(number)) {
            setSelectedNumber(selectedNumber.filter(item => item !== number));
        } else {
            setSelectedNumber([...selectedNumber, number]);
        }
    }

    return (
        <Grid item container direction='column' style={{ padding: isMobile ? "30px" : "30px 50px" }} gap={'50px'} justifyContent={'space-between'}>
            <Grid item container gap={'30px'}>
                <Grid item container alignItems={'center'}>
                    <Grid item xs><Typography fontSize={24} style={{ fontWeight: 500, color: theme.lightBlack }}>{title}</Typography></Grid>
                    <Grid item xs="auto" container alignItems={'center'} gap={1}>
                        <Grid item><Typography fontSize={20} style={{ color: theme.lightBlack }}>{t('orderDetails.quantity')}</Typography></Grid>
                        <Grid item><Typography fontSize={46} style={{ color: theme.lightBlack }}>{data.numberList.length || 0}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item container direction='column' gap={2}>
                    <Grid item><Typography style={{ fontSize: '18px', fontWeight: 500, color: theme.grey }}>{description}</Typography></Grid>
                    <Grid item>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '22px' }}>
                            { data.numberList.map((item, index) => ( 
                                <PlanNumberItem 
                                    key={`new-number-${index}`} 
                                    number={item} 
                                    onClick={!viewOnly ? handleNumberOnClick : null} 
                                    selected={selectedNumber.includes(item)} 
                                    viewOnly={viewOnly}
                                /> 
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {
                data?.expectedDate && !isMobile && (
                    <Grid container alignItems={'flex-start'} gap={2}>
                        <Grid item>
                            <Typography fontSize={16} style={{ color: theme.lightBlack }}>{t('orderDetails.expectedValidDateTime')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontSize={18} style={{ fontWeight: 500, color: theme.lightBlack }}>{data.expectedDate}</Typography>
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    )
}

export default NumberBlock;
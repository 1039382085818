import { Trans } from 'react-i18next';

const Title = () => {
  return (
    <h2 style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
      <span>
        <Trans
          i18nKey={'home:dialogPreText'}
          components={{
            companyEmail: (
              <span style={{ fontSize: '32px', color: '#ffda11' }} />
            ),
            wbr: <wbr />,
          }}
        />
      </span>
      <wbr />
      <span>
        <Trans i18nKey={'home:dialogPostText'} />
      </span>
    </h2>
  );
};

export default Title;

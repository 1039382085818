import MyTextField from "../TextField";
import { WindowSize } from "../../utils/responsive";

const FormTextField = (props) => {
    const isMobile = WindowSize() === "mobile";

    return (
        <MyTextField 
            {...props} 
            containerStyle={{
                height: "100%",
            }}
            emptyHelperText={isMobile ? "" : " "}
        />
    )
}

export default FormTextField;
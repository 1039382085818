import styled from "styled-components";
import CardBase from "../../../components/Card/CardBase";
import Form from "./Form";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import MyTextField from "../../../components/TextField";
import MyTypography from "../../../components/Typography";
import BubbleSelect from "../../../components/Selects/BubbleSelect";
import VisaIcon from "../../../assets/ic-visa.svg";
import MasterCardIcon from "../../../assets/ic-mastercard.svg";
import Space from "../../../components/Space";
import { breakpoint, WindowSize } from "../../../utils/responsive";

const CreditCardLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${breakpoint.desktop} {
    flex-direction: ${props => props.viewOnly ? 'column' : 'row'};
    ${props => props.viewOnly && `align-items: flex-start;`}
    column-gap: 130px;
  }

  @media ${breakpoint.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }

  .icon {
    width: 50%;
  }
`;

const PaymentMethod = ({ viewOnly, hasPlan=false }) => {
  const { t, i18n } = useTranslation("checkout");

  const isMobile = WindowSize() === "mobile";

  const CreditCardLabelProp = () => {
    return (
      <CreditCardLabelContainer viewOnly={viewOnly}>
        <MyTypography style={{ whiteSpace: "nowrap", width: "max-content" }} fontSize={viewOnly ? 20 : undefined} bold={viewOnly}>{t("creditCard")}</MyTypography>
        <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
          <img src={VisaIcon} alt="visa" className="icon" />
          <img src={MasterCardIcon} alt="mastercard" className="icon" />
        </div>
      </CreditCardLabelContainer>
    )
  }

  return (
    <Form title={t("paymentMethods")} isCollapsible={false}>
      <Grid
        container
        spacing={4}
        direction={viewOnly && !isMobile ? 'row' : 'column'}
        alignItems={"stretch"}
        style={{
          padding: isMobile ? "0 25px" : "0 30px",
        }}
      >
        <Grid item xs={viewOnly ? 4 : 12}>
          <BubbleSelect
            id="payment_method"
            viewOnly={viewOnly}
            disableUnchecked
            options={[
              {
                id: "creditCard",
                value: "creditCard",
                label: <CreditCardLabelProp />,
              },
            ]}
          />
        </Grid>
        {
          hasPlan && (
            <Grid item xs={12} md={6}>
              <MyTextField
                id="customer_engName"
                title={t("cardOwner")}
                viewOnly={viewOnly}
                disabled={!viewOnly}
                required
                containerStyle={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: isMobile ? undefined : "440px",
                }}
              />
            </Grid>
          )
        }
        {
          (hasPlan && !viewOnly) && (
            <Grid item xs={12}>
              <MyTypography>{t("paymentMethodRemark")}</MyTypography>
            </Grid>
          )
        }
      </Grid>
    </Form>
  );
};

export default PaymentMethod;

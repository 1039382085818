
export const TOKEN_UPDATE_EVENT = "tokenUpdate"


const setLocalStorageWithEvent = (key, value) => {
  window.dispatchEvent(
    new Event(TOKEN_UPDATE_EVENT, { key, value })
  );
  localStorage.setItem(key, value);
}
const removeLocalStorageWithEvent = (key) => {
  window.dispatchEvent(
    new Event(TOKEN_UPDATE_EVENT, { key })
  );
  localStorage.removeItem(key);
}


export const removeTokens = () => {
  removeLocalStorageWithEvent("refreshToken");
  removeLocalStorageWithEvent("accessToken");
}

export const setTokens = (accessToken, refreshToken) => {
  setLocalStorageWithEvent("accessToken", accessToken);
  setLocalStorageWithEvent("refreshToken", refreshToken);
}
import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import styled from "styled-components";
import Typography from "./Typography";
import theme from "../theme";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { WindowSize } from "../utils/responsive";

const CustomTextField = styled(TextField)`
  width: ${(props) => (props.width ? props.width : "100%")};
  border-radius: 5px;
  & label.Mui-focused {
    color: ${theme.white};
  }
  & .MuiOutlinedInput-root {
    background-color: ${theme.white};
    box-shadow: ${props => props?.error ? 'none' : '0 0 6px 0 #c9c9c9'};
    border-radius: 10px;

    input {
      padding: 12.5px 14px !important;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px rgb(255,250,248) inset !important;
      -webkit-text-fill-color: ${theme.primary} !important;
    }

    &.Mui-focused fieldset {
      border-color: ${theme.white};
    }
    &.Mui-disabled {
      background-color: #f2f2f2;
    }
    &.Mui-disabled fieldset {
      border-color: #c9c9c9;
    }
    &:hover fieldset {
      border-color: ${theme.white};
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${theme.error3};
      box-shadow: ${theme.error3} 0 0 6px 0;
    }
  }
  & .MuiFormHelperText-root {
    line-height: 1.3;
    margin-top: 8px;
    &.Mui-error {
      color: ${theme.error2};
    }
  }
`;

const small = {
  padding: `10.5px 14px`,
  fontSize: 14,
};

const medium = {
  padding: `16.5px 14px`,
  fontSize: 16,
};

const MyTextField = ({
  title,
  subTitle,
  titleColor,
  titleSize,
  size = "medium",
  labelProps,
  required,
  viewOnly,
  id,
  error,
  helperText,
  emptyHelperText="",
  containerStyle=null,
  ...props
}) => {
  const { t } = useTranslation("error");
  const [field, meta, helpers] = useField(id);
  const inputStyle = size === "medium" ? medium : small;
  const isMobile = WindowSize() === "mobile";

  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", ...containerStyle}}>
      <div style={{ display: "flex", alignItems: "stretch", flex: 1 }}>
        <div style={{ alignSelf: "end" }}>
          {title && (
            <Typography
              color={titleColor}
              header
              {...labelProps}
              style={{ display: "inline-block" }}
            >
              {title}
            </Typography>
          )}
          {required && (
            <Typography
              color={theme.orange}
              style={{ marginLeft: 5, marginRight: 5, display: "inline-block" }}
            >
              *
            </Typography>
          )}
          {subTitle && (
            <Typography
              color={titleColor}
              header
              {...labelProps}
              style={{ display: "inline-block" }}
            >
              {subTitle}
            </Typography>
          )}
        </div>
      </div>
      {viewOnly ? (
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Typography style={{ fontSize: "18px", color: theme.lightBlack }}>
            {field?.value || ""}
          </Typography>
        </div>
      ) : (
        <div style={{ alignSelf: "stretch" }}>
          <CustomTextField
            {...field}
            error={error || meta.error}
            helperText={
              (error || meta.error) && helperText ? helperText
                : meta?.error ? t ? t(meta.error) : meta?.error : (isMobile ? "" : emptyHelperText)
            }
            sx={{
              fieldset: { borderColor: "#fff" },
            }}
            inputProps={{
              ...props.inputProps,
              style: {
                ...inputStyle,
                ...props?.inputProps?.style,
              },
            }}
            variant="outlined"
            margin="none"
            {...props}
          />
        </div>
      )}
    </div>
  );
};

export default MyTextField;

MyTextField.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  titleColor: PropTypes.string,
  titleSize: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  required: PropTypes.bool,
};

import styled from "styled-components";

const CardBase = ({ children, style=null }) => {
  return <Card style={{ ...style }}>{children}</Card>;
};

export default CardBase;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 15px 0 #c9c9c9;
  border-radius: 25px;
  margin-bottom: 50px;
  margin-top: 35px;
  overflow: hidden;
`;

import { Divider } from "@mui/material";
import styled from "styled-components";
import theme from "../theme";

const CustomDivider = styled(Divider)`
    && {
        border-color: ${theme.divider};
    }
`

export default CustomDivider;
import { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Typography from "../../../../components/Typography";
import theme from "../../../../theme";
import { ReactComponent as EditIcon } from "../../../../assets/ic-edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/ic-delete.svg";
import HKIDMask from "../../../../components/HKIDMask";
import { isEmpty } from "lodash";

const IconWrapper = styled.div`
    cursor: pointer;
    height: fit-content;
`;

const FieldItem = ({ title="", value="", renderCell=null, ...props }) => {
    return (
        <Grid container item flexDirection={"column"} gap={2} justifyContent={'space-between'} {...props}>
            <Typography color={theme.lightBlack} style={{ wordBreak: 'keep-all' }}>{title}</Typography>
            { renderCell ? renderCell() 
                : <Typography fontSize={18} color={theme.lightBlack} style={{ whiteSpace: 'nowrap' }}>{value}</Typography>
            }
        </Grid>
    )
}

const InfoRecord = (props) => {
    const { data, type, onClickEdit=null, onClickDelete=null } = props;

    const { t } = useTranslation(['profile', 'common']);

    const isClientInfo = type === 'clientInfo';

    const getHKIDMaskedValue = (value="") => {
        return value?.split("").map((char, index) => {
            if ([" "].includes(char)) return ""
            if (["(", ")"].includes(char) || index <= 3) return char;
            return '*';
        })
    }

    const getBirthdayMaskedValue = (value="") => {
        return value?.split("").map((char, index) => {
            if ([" "].includes(char)) return ""
            if (["/", "-"].includes(char)) return char;
            return '*'
        })
    }

    const CLIENT_INFO_DATA = useMemo(() => (
        [
            { id: "title", dataKey: "title", valueGetter: (value) => t(`common:${value}`) },
            { id: "engFullName", dataKey: "name.en" },
            { id: "chiFullName", dataKey: "name.zh" },
            { id: "idType", dataKey: "idType", valueGetter: (value) => t(`common:${value}`) },
            { id: "idNumber", dataKey: "idNumber", renderCell: (value) => !isEmpty(value) && <HKIDMask useApi={false} maskValueFormatter={getHKIDMaskedValue} unMaskValue={value} /> },
            { id: "dateOfBirth", dataKey: "dateOfBirth", renderCell: (value) => !isEmpty(value) && <HKIDMask useApi={false} maskValueFormatter={getBirthdayMaskedValue} unMaskValue={value} /> },
            { id: "companyName", dataKey: "companyName" },
            { id: "jobPosition", dataKey: "position" },
            { id: "phoneNo", dataKey: "mobile" },
            { id: "billingEmailAddress", dataKey: "email" },
        ]
    ), [data])

    const CONTACT_INFO_DATA = useMemo(() => (
        [
            { id: "name", dataKey: "name.en" },
            { id: "email", dataKey: "email" },
            { id: "phoneNo", dataKey: "mobile" },
        ]
    ), [data])

    const dataKeyCollection = isClientInfo ? CLIENT_INFO_DATA : CONTACT_INFO_DATA;

    return (
        <Grid container flexDirection={{ xs: 'column-reverse', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'flex-end' }}>
            <Grid item container xs spacing={'30px'}>
                { dataKeyCollection.map((item) => {
                    const value = item?.dataKey.split('.').reduce((acc, key) => acc[key], data);

                    return (
                        <FieldItem
                            md={isClientInfo ? 2 : 4}
                            key={`${type}-${item?.id}`}
                            title={t(`profile:accountProfile.${item?.id}`)}
                            value={item?.valueGetter ? item.valueGetter(value) : value}
                            renderCell={item?.renderCell ? () => item?.renderCell(value) : null}
                        />
                    )
                })}
            </Grid>
            { (onClickEdit || onClickDelete) && (
                <Grid md={1} item container justifyContent={'flex-end'} columnGap={1}>
                    { onClickEdit && <IconWrapper><EditIcon onClick={() => onClickEdit && onClickEdit()} /></IconWrapper> }
                    { onClickDelete && <IconWrapper><DeleteIcon onClick={() => onClickDelete && onClickDelete()} /></IconWrapper> }
                </Grid>
            )}
        </Grid>
    )
}

export default InfoRecord;
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Grid } from "@mui/material";
import { fetchDeliveryConfig } from "../../apis";
import { useQueries } from "@tanstack/react-query";
import Collapse from "../../components/Collapse";
import Typography from "../../components/Typography";
import theme from "../../theme";

const ShippingCollapse = (props) => {
    const { t, allowDelivery=false, allowPickup=false } = props;

    const [isOpen, setIsOpen] = useState(true);
    const shippingRef = useRef(null);
    const bothShippingAllowed = allowDelivery && allowPickup;

    const [deliveryConfig] = useQueries({
        queries: [
            {
                queryKey: 'deliveryConfig',
                queryFn: fetchDeliveryConfig,
                enabled: allowDelivery,
            }
        ]
    })

    useLayoutEffect(() => {
        if (shippingRef.current?.offsetWidth) setIsOpen(shippingRef.current.offsetWidth > 530);
    }, [shippingRef.current?.offsetWidth])

    const getTitleText = () => {
        const deliveryText = allowDelivery === true ? t('deliveryTitle') : '';
        const pickupText = allowPickup === true ? t('pickUpTitle') : '';
        const bothText = bothShippingAllowed ? ' / ' : '';
        return deliveryText + bothText + pickupText;
    }

    const estimatedDeliveryDay = deliveryConfig?.data?.data?.find(item => item.keyPath === "delivery.estimatedDeliveryDay")?.value || 0;
    const minSpent = deliveryConfig?.data?.data?.find(item => item.keyPath === "delivery.freeShippingMinSpent")?.value || 1000;

    return (
        <Grid item ref={shippingRef}>
            <Collapse
                title={getTitleText()}
                isOpen={isOpen}
                autoCollapseBaseOnWidth={true}
                style={{ padding: 0 }}
            >
                <Typography style={{ fontSize: '16px', color: theme.lightBlack, fontWeight: '500' }}>
                    { allowDelivery && <>{t('deliveryPre')}<span style={{color: theme.dirtyYellow}}>{t('deliveryDay', {day: estimatedDeliveryDay})}</span>{t('deliveryPost', {minSpent: minSpent})}</> }
                    { bothShippingAllowed && ' / ' }
                    { allowPickup && t('pickUpHint') }
                </Typography>
            </Collapse>
        </Grid>
    )
}

export default ShippingCollapse;
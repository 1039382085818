import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";
import { DESKTOP_NAVBAR_HEIGHT, MOBILE_NAVBAR_HEIGHT } from "../../components/NavBar/constant";

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  scroll-margin-top: ${DESKTOP_NAVBAR_HEIGHT}px;
  @media ${breakpoint.mobile} {
    // scroll-margin-top: ${MOBILE_NAVBAR_HEIGHT}px;
    scroll-margin-top: 93px;  // height of step line container
  }
`;
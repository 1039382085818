import { jwtDecode } from "jwt-decode";
import { useMemo, useSyncExternalStore } from "react";
import { TOKEN_UPDATE_EVENT } from "../utils/localStorage";






const subscribe = (callback) => {
  window.addEventListener(TOKEN_UPDATE_EVENT, callback)

  return () => {
    window.removeEventListener(TOKEN_UPDATE_EVENT, callback)
  }
}




const getSnapshotAccessToken = () => {
  const token = localStorage.getItem('accessToken')
  return token
}





/**
 * custom hook handle token type checking
 * @returns 
 */
const useGlobalAuth = () => {

  const token = useSyncExternalStore(subscribe, getSnapshotAccessToken)
  // const refreshToken = useSyncExternalStore(subscribe, getSnapshotRefreshToken)
  const decodedToken = useMemo(() => {
    try {
      return jwtDecode(token)
    } catch (e) {
      return null
    }
  }, [token])
  const isGuest = decodedToken ? !!decodedToken.isGuest : false
  const isApiAccessible = !!decodedToken








  return { token, isGuest, isApiAccessible }

}


export default useGlobalAuth;
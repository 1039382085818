import styled from "styled-components";
import { Grid } from "@mui/material";
import UpIcon from "../../../assets/ic-upYellow.svg";
import DownIcon from "../../../assets/ic-downYellow.svg";
import MyTypography from "../../../components/Typography";
import { useState } from "react";
import CardBase from "../../../components/Card/CardBase";
import { breakpoint } from "../../../utils/responsive";

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 30px;

  ${props => props?.onClick && props?.isCollapsible && `
    cursor: pointer;
  `}

  ${props => props?.isOpen && `padding-bottom: 30px;`}

  @media ${breakpoint.mobile} {
    padding: 25px;
    ${props => props?.isOpen && `padding-bottom: 30px;`}
  }
`

const Form = ({ title, children, isCollapsible=true }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <CardBase style={{ marginBottom: "15px", paddingBottom: isOpen ? "30px" : undefined }}>
      <div>
        <TitleContainer
          isOpen={isOpen}
          isCollapsible={isCollapsible}
          onClick={() => isCollapsible && setIsOpen(!isOpen)}
        >
          <MyTypography fontSize="24" bold>
            {title}
          </MyTypography>
          {isCollapsible && <div>
            <img src={isOpen ? UpIcon : DownIcon} />
          </div>}
        </TitleContainer>

        {isOpen && children}
      </div>
    </CardBase>
  );
};

export default Form;

/**
 * @typedef {Object} UsePreviewOrderTncReturn
 * @property {string[]} productOverallTncIds
 * @property {import("../../../apis").AcceptedTncStatusValue[]} overallTncStatus
 * @property {any[]} allProductOverallTncs
 * @property {any[]} allPlanOverallTncs
 * @property {boolean} isPreviewRequiredTncsAccepted
 * 
 */

import { useContext, useMemo } from 'react';
import { CheckoutFormContext } from '../context/CheckoutFormContext';
import useCheckoutGlobalTnc from './useCheckoutGlobalTnc';
import { ACCEPTED_TNCS_KEYS } from './useCheckoutInitialRequiredTncs';
import { differenceWith, intersectionWith } from 'lodash';

/**
 * @returns {UsePreviewOrderTncReturn}
 */
export const usePreviewOrderTnc = () => {
  const { acceptedTNCStatus } = useCheckoutGlobalTnc();
  const overallTncStatus = acceptedTNCStatus[ACCEPTED_TNCS_KEYS.overallTncs];
  const checkoutFormCtx = useContext(CheckoutFormContext);

  const allProductOverallTncs =
    checkoutFormCtx.quoteQuery?.data?.data?.allProductOverallTncs || [];


  const allPlanOverallTncs = useMemo(() => {
    const isSameTnc = (a, b) => a._id === b._id;

    const allPlanOverallTncData =
      checkoutFormCtx.quoteQuery?.data?.data?.allPlanOverallTncs || [];

    return differenceWith(
      allPlanOverallTncData,
      intersectionWith(allProductOverallTncs, allPlanOverallTncData, isSameTnc),
      isSameTnc
    ) || [];
  }, [checkoutFormCtx.quoteQuery?.data?.data])

  const productOverallTncIds = useMemo(() => {
    return (
      checkoutFormCtx.quoteQuery?.data?.data?.allProductOverallTncs.map(
        (item) => item._id
      ) || []
    );
  }, [checkoutFormCtx.quoteQuery?.data?.data]);

  /**
   * @type {boolean} isPreviewTncsAccepted
   */
  const isPreviewRequiredTncsAccepted = useMemo(() => {
    return acceptedTNCStatus[ACCEPTED_TNCS_KEYS.overallTncs]?.every((item) => item.accepted)
  }, [acceptedTNCStatus])

  // const planOverallTncIds = checkoutFormCtx.quoteQuery?.data?.data?.allPlanOverallTncs.map(item => item.tncId)

  return {
    overallTncStatus,
    productOverallTncIds,
    allProductOverallTncs,
    allPlanOverallTncs,
    isPreviewRequiredTncsAccepted
  };
};

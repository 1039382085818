import styled from "styled-components";
import { useTranslation } from "react-i18next";
import StyledContainer from "../../../components/Container";
import MyTypography from "../../../components/Typography";
import { ReactComponent as DeleteIcon } from "../../../assets/ic-delete.svg";
import theme from "../../../theme";

const PADDING_VALUE = '38px';

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    cursor: pointer;
`;

const DeleteAll = (props) => {
    const {
        onDelete,
    } = props;
    
    const { t } = useTranslation(['profile', 'common']);

    const handleClickDelete = () => {
        onDelete && onDelete();
    }

    return (
        <StyledContainer style={{ padding: `${PADDING_VALUE} 0`, flexDirection: 'row', width: '100%', boxShadow: theme.outerContainerShadow }}>
            <ButtonContainer onClick={handleClickDelete}>
                <DeleteIcon color="#3E3E3E" />
                <MyTypography fontSize={18} bold>{t('accountProfile.deleteAllBtn')}</MyTypography>
            </ButtonContainer>
        </StyledContainer>
    )
}

export default DeleteAll;
import styled from "styled-components";
import { Divider, Grid } from "@mui/material";
import DeviceWithVasContent from "../../../../components/OrderItems/DeviceWithVasContent";
import ItemTableRow from "../../../../components/OrderItems/ItemTableRow";
import Space from "../../../../components/Space";
import Typography from "../../../../components/Typography";
import theme from "../../../../theme";
import RemarkFieldItem from "../RemarkFieldItem";
import RemarkSectionContainer from "../RemarkSectionContainer";
import { useTranslation } from "react-i18next";
import { WindowSize, breakpoint } from "../../../../utils/responsive";

const RemarkSection = ({ t }) => {
    return (
        <RemarkSectionContainer>
            {/** Installation Address */}
            <RemarkFieldItem label={t('installationAddress')} value="34/F No.979 King’s Road, Quarry Bay, Hong Kong" />

            {/** Installation Date */}
            <RemarkFieldItem label={t('installationDate')} value="31/12/2022" noDivider={true} />
        </RemarkSectionContainer>
    )
}

const getDeviceValue = (data) => {
    return {
        quantity: data?.product?.quantity || 1,
        externalRemark: data?.externalRemark || "",
        options: {
            variationProduct: {
                options: data?.product?.selectedOptions?.map((item) => {
                    return {
                        optionKey: item?.option?.optionKey,
                        valueKey: item?.value?.value,
                    }
                }),
            },
            freeVas: data?.freeVas || [],
            paidVas: data?.paidVas || [],
        },
        product: {
            productName: data?.product?.name,
            // sellingPrice: data?.product?.discountedPrice || 0,
            flipCardIconImageUrl: data?.product?.flipCardIconImageUrl,
            productOptions: data?.product?.selectedOptions?.map((item) => {
                return {
                    option: item?.option,
                    values: [item?.value],
                }
            }),
        },
        discountedPricePerItem: data?.discountedPricePerItem || 0,
    }
}

const DeviceOrder = (props) => {
    const { data, index, cardBaseStyle=null } = props;

    const { t } = useTranslation('profile', { keyPrefix: 'orderDetails' });

    return (
        <DeviceWithVasContent
            idx={index}
            // remarkProps={<RemarkSection t={t} />}
            orderRecord={[
                { sku: data?.product?.productSku },
            ]}
            iconNeedDotDecoration={true}
            data={getDeviceValue(data)}
            cardBaseStyle={cardBaseStyle}
        />
    )
}

export default DeviceOrder;
import { createContext, useReducer } from 'react';
import GlobalReducer from '../reducers/globalReducer';
import { useState, useEffect } from 'react';
import {
  fetchCart,
  fetchUserInfo,
  guestLoginIn,
} from '../apis';
import {
  UPDATE_USER_INFO,
  UPDATE_INIT,
  SHOW_COOKIE_CONSENT,
  APPEAR_GLOBAL_ERROR,
  UPDATE_CART_COUNT,
} from '../actions/globalAction';
import { getCookie } from '../utils/common';
import { useQueries, useQuery } from '@tanstack/react-query';
import useGlobalAuth from './useGlobalAuth';
import { setTokens } from '../utils/localStorage';
import { isEmpty } from 'lodash';

const globalInitState = {
  globalError: null,
  userInfo: {},
  isInitialized: false,
  showCookieConsent: false,
  cookieConsentHeight: 0,
  cartCount: 0,
  isGreetingDialogOpen: false,
  greetingDialogLastOpen: {
    en: undefined,
    zh: undefined,
  },
};

export const GlobalContextStore = createContext(globalInitState);

const GlobalProvider = ({ children }) => {
  const { token, isGuest, isApiAccessible } = useGlobalAuth();
  const [globalState, globalDispatch] = useReducer(GlobalReducer, {
    ...globalInitState,
  });

  // execute on init mount only (align with original useEffect fetch)
  // this should only be inited after refresh token from user's token failed
  const guestQuery = useQuery({
    queryKey: 'guestLogin',
    queryFn: guestLoginIn,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isEmpty(globalState.userInfo) && (!isApiAccessible || isGuest),
    onSuccess: (result) => {
      setTokens(result?.data?.accessToken, result?.data?.refreshToken);
      globalDispatch({ type: UPDATE_INIT, isInitialized: true });
    },
    onError: (err) => {
      console.log('guest login failed');
      globalDispatch({ type: UPDATE_INIT, isInitialized: true });
      globalDispatch({
        type: APPEAR_GLOBAL_ERROR,
        title: err?.result?.code || 'Login Error',
        message: err?.result?.errorMessage ?? 'Guest login Currently unavailable', //use api error message
        options: {
          isDismissible: false,
        }
      });
    },
  });

  const sessionValidUserQueries = useQueries({
    queries: [
      {
        queryKey: 'userInfo',
        queryFn: fetchUserInfo,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: isApiAccessible && !isGuest,
        onSuccess: (result) => {
          globalDispatch({
            type: UPDATE_USER_INFO,
            userInfo: result?.data,
            init: true,
          });
        },
        onError: () => {
          globalDispatch({ type: UPDATE_INIT, init: true });
        },
      },
      {
        queryKey: 'fetchCart',
        queryFn: fetchCart,
        enabled: isApiAccessible && !isGuest,
        onSuccess: (result) => {
          if (result?.data?.cart?.items) {
            globalDispatch({
              type: UPDATE_CART_COUNT,
              cartCount: result?.data?.cart?.items.length,
            });
          } else {
            throw new Error();
          }
        },
        onError: (err) => {
          console.log('fetch cart failed', err);
          globalDispatch({ type: UPDATE_CART_COUNT, cartCount: 0 });
        },
      },
    ],
  });

  // check cookie consent
  useEffect(() => {
    const cookieConsent = getCookie('cookieConsent');
    if (!cookieConsent) {
      globalDispatch({ type: SHOW_COOKIE_CONSENT });
    }
  }, []);

  return (
    <GlobalContextStore.Provider
      value={{ globalState, globalDispatch, isApiAccessible }}
    >
      {children}
    </GlobalContextStore.Provider>
  );
};
export default GlobalProvider;

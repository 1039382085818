import { useContext } from "react";
import CustomerInformation from "../form/CustomerInformation";
import PaymentMethod from "../form/PaymentMethod";
import ContactInformation from "../form/ContactInformation";
import DeliveryInformation from "../form/DeliveryInformation";
import SupportingDoc from "../form/SupportingDoc";
import PreviewTermAndCondition from "../form/PreviewTermAndCondition";
import PreviewForm from "./PreviewForm";
import OrderItem from "../orderList/OrderItems";
import Coupon from "../Coupon";
import DeliveryContactInformation from "../form/DeliveryContactInformation";
import MnpNumberPreivew from "../form/MnpNumberPreview";
import { CheckoutFormContext } from "../context/CheckoutFormContext";

const PreviewOrder = ({
  viewOnly,
  setIsPreview,
  data,
  acceptedTNC,
  setAcceptedTNC,
}) => {
  const totalTncCount = data?.allProductOverallTncs?.length + data?.allPlanOverallTncs?.length;
  const { isDeviceOnly, hasDevice, configQuery } = useContext(CheckoutFormContext)

  return (
    <PreviewForm
      data={data}
      acceptedTNC={acceptedTNC}
      setIsPreview={setIsPreview}
    >
      <OrderItem viewOnly={viewOnly} />
      {data?.promoCode && <Coupon data={data} isViewOnly={viewOnly} />}
      <MnpNumberPreivew data={data} />
      <CustomerInformation viewOnly={viewOnly} />
      <DeliveryContactInformation
        viewOnly={viewOnly}
        allowDelivery={data?.isAllowedDelivery}
        allowPickup={data?.isAllowedPickup}
        configData={configQuery?.data?.data}
        hasDevice={hasDevice}
      />
      <PaymentMethod viewOnly={viewOnly} hasPlan={!isDeviceOnly} />
      { !isDeviceOnly && <SupportingDoc /> }
      {totalTncCount > 0 && <PreviewTermAndCondition
        acceptedTNC={acceptedTNC}
        setAcceptedTNC={setAcceptedTNC}
      />}
    </PreviewForm>
  );
};
export default PreviewOrder;

import styled from "styled-components";
import { Divider, Grid } from "@mui/material";
import PlanContent from "../../../../components/OrderItems/PlanContent";
import ItemTableRow from "../../../../components/OrderItems/ItemTableRow";
import Space from "../../../../components/Space";
import Typography from "../../../../components/Typography";
import theme from "../../../../theme";
import PlanNumberComponent from "./PlanNumberContainer";
import RemarkFieldItem from "../RemarkFieldItem";
import RemarkSectionContainer from "../RemarkSectionContainer";
import { useTranslation } from "react-i18next";
import { ResponsiveUI, WindowSize, breakpoint } from "../../../../utils/responsive";
import { GetWord, getItemColumnWidth, formatCurrency } from "../../../../utils/common";
import dayjs from "dayjs";
import { TIME_SLOT } from "../../../checkout/form/DeliveryInformation";

const DividerContainer = styled.div`
    display: flex;
    margin: 20px 0px 30px 0px;
    justify-content: flex-end;

    @media ${breakpoint.mobile} {
        margin: 0;
    }
`;

const getPlanValue = (data) => {
    // console.log('==plan data', data)

    return {
        quantity: data?.quantity || 1,
        externalRemark: data?.externalRemark || "",
        plan: {
            ...data?.plan,
            planName: data?.plan?.name,
            contractPeriodDetails: [data?.plan?.contractPeriod] || [],
        },
        options: {
            contractPeriodId: data?.plan?.contractPeriod?._id || "",
        },
        discountedSubtotal: data?.plan?.total || 0,
        freeVas: data?.freeVas || [],
        paidVas: data?.paidVas || [],
    }
}

const PlanDetail = (props) => {
    const { data, t } = props;

    const isMobile = WindowSize() === 'mobile';

    const colWidth = getItemColumnWidth("plan");

    return (
        <div>
            <ResponsiveUI
                desktop={
                <DividerContainer>
                    <Divider style={{ width: 'calc(100% - 170px)' }} />
                </DividerContainer>
                }
                mobile={
                <>
                    <Divider />
                    <Space size="20px" />
                </>
                }
            />

            <div style={{ padding: isMobile && '30px 0' }}>
                <ItemTableRow 
                    row={[
                        GetWord(data?.plan?.planName),
                        GetWord(data?.plan?.localData),
                        `${data?.plan?.contractPeriod?.period} ${t("common:months")}`,
                        data?.quantity,
                        `HK$ ${formatCurrency(data?.plan?.contractPeriod?.discountedPrice, 1)}`,
                        `HK$ ${formatCurrency(data?.plan?.contractPeriod?.discountedPrice*(data?.quantity || 1), 1)}`,
                    ]} 
                    colWidth={colWidth} 
                    extraIconWidth={true} 
                />
                { [...data?.freeVas, ...data?.paidVas].map((item) => (
                    <ItemTableRow 
                        row={[
                            GetWord(item?.name),
                            GetWord(item?.detail),
                            `${data?.plan?.contractPeriod?.period} ${t("common:months")}`,
                            data?.quantity || 1,    // Missing
                            `HK$ ${formatCurrency(item?.discountedPrice, 1)}`,
                            `HK$ ${formatCurrency(item?.discountedPrice*(data?.quantity || 1), 1)}`,
                        ]} 
                        colWidth={colWidth} 
                        extraIconWidth={true} 
                    />
                ))}
            </div>
        </div>
    )
}

const RemarkSection = (props) => {
    const { data, t } = props;

    const isMobile = WindowSize() === 'mobile';

    const getEffectiveDateTimeString = () => {
        const isMnp = data?.plan?.mnpNumbers?.length > 0;

        const timeSlotLabel = TIME_SLOT.find((item) => {
            if (!isMnp) return undefined;
            return item.value === data?.plan.mnpNumber.effectiveTime
        })?.title;

        return `${isMnp ? data?.plan.mnpNumber.effectiveDate : data?.plan.newNumberEffectiveDate} ${isMnp ? timeSlotLabel : ""}`;
    }

    return (
        <RemarkSectionContainer>
            <PlanNumberComponent data={data} t={t} />
            { isMobile && <RemarkFieldItem label={t('orderDetails.expectedValidDateTime') } value={getEffectiveDateTimeString()} /> }
            {/* <ResponsiveUI 
                desktop={<RemarkFieldItem label={t('orderDetails.autoRenewal')} value={t(data?.plan?.isAutoRenewal ? 'yes' : 'no', {ns: 'common'})} />}
                mobile={data?.plan?.isAutoRenewal ? <RemarkFieldItem value={t('orderDetails.autoRenewal')} /> : null}
            /> */}
            <RemarkFieldItem label={t('orderDetails.autoRenewal')} value={t(data?.plan?.isAutoRenewal ? 'yes' : 'no', {ns: 'common'})} />
            <RemarkFieldItem value={t('orderDetails.autoInflate')} noDivider={true} valueFontsize={18} />
        </RemarkSectionContainer>
    )
}

const PlanOrder = (props) => {
    const { data, index, cardBaseStyle=null } = props;

    const { t } = useTranslation(['profile', 'common']);

    const planData = getPlanValue(data);

    //TODO - external remark
    return (
        <PlanContent
            idx={index}
            renderDetail={<PlanDetail t={t} data={planData} />}
            remarkProps={<RemarkSection t={t} data={planData} />}
            orderRecord={[
                ...planData?.plan?.newNumbers?.map((item) => { return { number: item?.number } }),
                ...planData?.plan?.mnpNumbers?.map((item) => { return { number: item?.number } }),
            ]}
            // iconNeedDotDecoration={true}
            cardBaseStyle={cardBaseStyle}
            data={planData}
        />
    )
}

export default PlanOrder;
import styled from "styled-components";
import theme from "../../theme";

const CommonTag = styled.div`
background: ${props => props.bgColor ? props.bgColor : theme.tagGradientColor};
color: ${props => props.color ? props.color : '#3e3e3e'};
font-size: ${props => props.fontSize ? props.fontSize : '#3e3e3e'};
font-weight: bold;
width: max-content;
padding: 5px 20px;
border-radius: 15px;
`

export default CommonTag;
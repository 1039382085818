import { useState, useMemo, forwardRef, useImperativeHandle } from "react";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import Typography from "./Typography";
import { ReactComponent as VisibleIcon } from "../assets/ic-visible.svg";
import { ReactComponent as HiddenIcon } from "../assets/ic-hidden.svg";
import theme from "../theme";

const toggleIconStyle = {
    cursor: "pointer",
    fill: "#333",
}

const HKIDMask = forwardRef((props, ref) => {
    const {
        maskedValue,
        unMaskQueryKey,
        unMaskQueryFn,
        unMaskValue,
        dataKey,
        useApi=true,
        maskValueFormatter=null,
    } = props;
    const [isMasked, setIsMasked] = useState(true);
    const [unMaskedValue, setUnMaskedValue] = useState(null);

    const { data, isLoading, isFetching, refetch } = useQuery({
        queryKey: unMaskQueryKey,
        queryFn: () => unMaskQueryFn && unMaskQueryFn(),
        onSuccess: (data) => {
            const value = dataKey.split('.').reduce((acc, key) => acc[key], data?.data);
            setUnMaskedValue(value);
            setIsMasked(false);
        },
        enabled: false,
    })

    const displayValue = useMemo(() => {
        if (!isMasked) return unMaskedValue;
        else return maskedValue || (maskValueFormatter ? maskValueFormatter(unMaskValue) : "");
    }, [maskedValue, unMaskedValue, isMasked])

    const onClickToggle = () => {
        if (isMasked) {
            if (useApi) return refetch();
            else {
                setUnMaskedValue(unMaskValue);
                return setIsMasked(false);
            }
        }

        setUnMaskedValue(null);
        setIsMasked(true);
    }

    return (
        <div style={{ display: "flex", alignItem: "center", gap: 10, }}>
            <Typography fontSize={18}>{displayValue}</Typography>
            <div>
                { isFetching ? (
                    <CircularProgress size={18} style={{ color: theme.lightBlack }} />
                ) : (
                    isMasked ? (
                        <HiddenIcon style={toggleIconStyle} onClick={onClickToggle} /> 
                    ) : (
                        <VisibleIcon style={toggleIconStyle} onClick={onClickToggle} />
                    )
                )}
            </div>
        </div>
    )
});

export default HKIDMask;
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import { Grid } from "@mui/material";
import MyTypography from "../../components/Typography";
import IconDiscount from "../../assets/ic-homeDiscount.svg";
import IconBundle from "../../assets/ic-homeBundle.svg";
import IconPayment from "../../assets/ic-homePayment.svg";
import IconDelivery from "../../assets/ic-homeDelivery.svg";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import Space from "../../components/Space";

const PlanBenefit = () => {
  const { t } = useTranslation("home");

  const BENEFIT_LIST = [
    { img: IconDiscount, label: t("planBenefit1") },
    { img: IconBundle, label: t("planBenefit2") },
    { img: IconPayment, label: t("planBenefit3") },
    { img: IconDelivery, label: t("planBenefit4") },
  ];

  const _renderDesktop = () => {
    return (
      <Container container gap={"70px"} alignItems="center" justifyContent={"center"}>
        <Wrapper container gap={"20px"} alignItems="center" justifyContent={"space-between"}>
          {BENEFIT_LIST.map((item, index) => {
            return (
              <Grid container item xs={2} alignItems="center" key={`desktop-benefit-${index}`} flexWrap={"nowrap"}>
                <Grid item xs="auto" style={{ marginRight: 10 }}>
                  <Icon>
                    <img src={item.img} />
                  </Icon>
                </Grid>
                <Grid item xs>
                  <MyTypography bold fontSize="18">
                    {item.label}
                  </MyTypography>
                </Grid>
              </Grid>
            );
          })}
        </Wrapper>
      </Container>
    );
  };

  const _renderMobile = () => {
    return (
      <MobileContainer>
        <MobileWrapper>
          {BENEFIT_LIST.map((item, index) => {
            return (
              <MobileBenefit key={`mobile-benefit-${index}`}>
                <Icon>
                  <img src={item.img}/>
                </Icon>
                <Space width="10px" />
                <MyTypography fontSize="16" bold>
                  {item.label}
                </MyTypography>
              </MobileBenefit>
            );
          })}
        </MobileWrapper>
      </MobileContainer>
    );
  };

  return <ResponsiveUI desktop={_renderDesktop()} mobile={_renderMobile()} />;
};

const Container = styled(Grid)`
  background-color: ${theme.yellow};
  width: 100%;
  height: auto;
  padding: 15px 30px;
  margin-top: -3px;
`;

const Wrapper = styled(Grid)`
  max-width: ${theme.maxWidth.desktop};
`

const MobileContainer = styled.div`
  background-color: ${theme.yellow};
  padding: 0px 30px;
`;

const MobileWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  overflow: auto;
  margin-top: -3px;
  padding: 15px 0;
`

const MobileBenefit = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 200px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const Icon = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 15px 0 #e5c80a;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${breakpoint.mobile} {
    // width: 52px;
    // min-width: 52px;
    // padding: 0px 3px;
    width: 56px;
    min-width: 56px;
    height: 50px;
    border-radius: 15px;

    img {
      max-width: 44.5px;
    }
  }

  @media ${breakpoint.desktop} {
    width: 68px;
    height: 60px;
  }
`;

export default PlanBenefit;

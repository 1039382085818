import { Divider } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import DevicePriceBar from '../components/BottomBar/DevicePriceBar';
import PageHeader from '../components/Header';
import LoadingIndicator from '../components/LoadingIndicator';
import Space from '../components/Space';
import StepGroup from '../components/StepGroup';
import CheckoutFormProvider, {
  CheckoutFormContext
} from '../pageComponents/checkout/context/CheckoutFormContext';
import TncProvider from '../pageComponents/checkout/context/GlobalTncContext';
import Step3 from '../pageComponents/checkout/OrderList';
import Step1 from '../pageComponents/checkout/TNC';
import Step2 from '../pageComponents/checkout/UserInfo';
import useCheckoutGlobalTnc from '../pageComponents/checkout/hooks/useCheckoutGlobalTnc';
import PreviewOrder from '../pageComponents/checkout/previewOrder';
import { formatQuoteProductForGA } from '../utils/common';
import { gaAddShippingInfo } from '../utils/ga';
import { gtmAddShippingInfo } from '../utils/gtm';


const STEP_LIST = [
  {
    id: 1,
    isActive: true,
    isCompleted: false,
  },
  {
    id: 2,
    isActive: false,
    isCompleted: false,
    onSaveAction: (data) => {
      const gaPayload =
        data?.items.map((item, index) =>
          formatQuoteProductForGA({ ...item, sort: index })
        ) || [];
      gaAddShippingInfo(gaPayload);
      gtmAddShippingInfo(gaPayload);
    },
  },
  {
    id: 3,
    isActive: false,
    isCompleted: false,
  },
];

const DEVICE_ONLY_STEP_LIST = [
  {
    id: 1,
    isActive: true,
    isCompleted: false,
    onSaveAction: (data) => {
      const gaPayload =
        data?.items.map((item, index) =>
          formatQuoteProductForGA({ ...item, sort: index })
        ) || [];
      gaAddShippingInfo(gaPayload);
      gtmAddShippingInfo(gaPayload);
    },
  },
  {
    id: 2,
    isActive: false,
    isCompleted: false,
    // onSaveAction: gaAddPaymentInfo,
  },
];



export const next = (id, step, setStep, data = {}) => {
  const newSteps = step.map((item) => {
    if (item.id === id - 1) {
      if (item.isActive !== (item.id === id)) {
        item?.onSaveAction && item.onSaveAction(data);
      }
      return { ...item, isActive: item.id == id, isCompleted: true };
    } else return { ...item, isActive: item.id === id };
  });
  setStep(newSteps);
};




const Checkout = () => {
  return (
    <CheckoutFormProvider>
      <TncProvider>
        <CheckoutPage />
      </TncProvider>
    </CheckoutFormProvider>
  );
};

const CheckoutPage = () => {
  const { t } = useTranslation('checkout');
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [autoSave, setAutoSave] = useState({
    1: false,
    2: false,
  });
  const {
    quoteQuery: quoteData,
    tncQuery: tncData,
    configQuery: configData,
    calendarQuery: calendarData,
    isDeviceOnly,
    isLoading,
    isError,
  } = useContext(CheckoutFormContext);
  const stepRefs = useRef([]);

  const { acceptedTNCStatus, setAcceptedTNCStatus } = useCheckoutGlobalTnc()

  const handleClickPreview = (status) => {
    setIsPreview(status);
    window.scrollTo(0, 0);
  }

  const step1PlanTnc =
    tncData?.data?.data?.find((item) => item.keyPath === 'tnc.planBeforeBuy')
      ?.value || [];

  useEffect(() => {
    if (!step && quoteData?.isFetchedAfterMount) {
      if (isDeviceOnly) {
        setStep(DEVICE_ONLY_STEP_LIST);
      } else {
        setStep(STEP_LIST);
      }
    }
  }, [quoteData?.data?.data]);

  useEffect(function scrollOnActiveStepChange() {
    const activeStep = step?.find((item) => item.isActive);
    if (activeStep) {
      stepRefs.current[activeStep.id - 1]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [step]);

  useEffect(() => {
    console.log('quoteData', quoteData, step);
    if (!location?.state?.enableAccess) {
      navigate('/shoppingcart');
    }
  }, [quoteData?.data?.data, tncData?.data?.data]);

  if (isLoading || !step) {
    return <LoadingIndicator />;
  }

  if (isError) {
    //TODO - api error handle
    console.log(
      'error - ',
      quoteData.errors,
      tncData.errors,
      configData.erorrs
    );
  }

  return (
    <div>
      <PageHeader>
        {isPreview ? t('preivewOrder') : t('registerAndPayment')}
      </PageHeader>
      <Space size="30px" />
      {isPreview ? (
        <PreviewOrder
          viewOnly={isPreview}
          setIsPreview={handleClickPreview}
          data={quoteData?.data?.data}
          acceptedTNC={acceptedTNCStatus}
          setAcceptedTNC={setAcceptedTNCStatus}
        />
      ) : (
        <div style={{ marginBottom: '50px' }}>
          <StepGroup data={step} />
          <Divider />
          {/* step1 for plan only */}
          {!isDeviceOnly && (
            <Step1
              setStep={setStep}
              step={step}
              currentStep={step[0]}
              data={step1PlanTnc}
              acceptedTNC={acceptedTNCStatus}
              setAcceptedTNC={setAcceptedTNCStatus}
              ref={(el) => (stepRefs.current[0] = el)}
            />
          )}
          <Step2
            setStep={setStep}
            step={step}
            currentStep={isDeviceOnly ? step[0] : step[1]}
            data={quoteData?.data?.data}
            tncData={tncData?.data?.data}
            configData={configData?.data?.data}
            calendarData={calendarData?.data?.data}
            refetch={quoteData?.refetch}
            acceptedTNC={acceptedTNCStatus}
            setAcceptedTNC={setAcceptedTNCStatus}
            autoSave={autoSave}
            setAutoSave={setAutoSave}
            ref={(el) => {
              const stepId = isDeviceOnly ? 0 : 1;
              stepRefs.current[stepId] = el;
            }}
          />
          <Step3
            setStep={setStep}
            step={step}
            currentStep={isDeviceOnly ? step[1] : step[2]}
            data={quoteData?.data?.data}
            tncData={tncData?.data?.data}
            refetch={quoteData?.refetch}
            setIsPreview={handleClickPreview}
            autoSave={autoSave}
            setAutoSave={setAutoSave}
            ref={(el) => {
              const stepId = isDeviceOnly ? 1 : 2;
              stepRefs.current[stepId] = el;
            }}
          />

          <DevicePriceBar
            totalPrice={quoteData?.data?.data?.calculatedOrderPrice}
            onClick={() => handleClickPreview(true)}
            isCheckout
            disabled={step.some((i) => i.isCompleted === false)}
            titleShowTotal
          />
        </div>
      )}
    </div>
  );
};

export default Checkout;

import { forwardRef, useContext, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import dayjs from "dayjs";
import StepContainer from "../StepContainer";
import { useTranslation } from "react-i18next";
import { addNumberToPlan, processPayment } from "../../../apis";
import _, { isBoolean, isEmpty } from "lodash";
import { appearGlobalError } from "../../../requests/globalRequest";
import { GlobalContextStore } from "../../../context/GlobalProvider";
import * as Yup from "yup";
import { checkoutFormDataContext } from "../context/CheckoutFormContext";
import { GetWord } from "../../../utils/common";


export const CART_ITEM_TYPE = {
  PLAN: "plan",
  DEVICE: "device",
  DELIVERY: "delivery",
}

const getInitialValues = (data) => {
  const orderList = data?.items || [];
  const initialValues = orderList?.map((item) => {
    return {
      ...item,
      // only newNumQty shared for both plan and device
      order_newNumQty:
        item?.newNumbers?.length > 0
          ? item?.newNumbers?.length
          : item?.quantity - item?.mnpNumbers?.length,
      // exclusive to plan only, should be default value if the type is plan

      order_newNumList: item?.newNumbers ? item.newNumbers.map(num => ({ ...num, _id: num.numberId })) : [],
      order_newNumbersEffectiveDate: item?.newNumberEffectiveDate || "",
      order_mnpNumQty: item?.mnpNumbers?.length || 0,
      order_mnpNumbers: item?.mnpNumbers || [],
      order_isAutoRenewal: isBoolean(item?.plan?.planAutoRenewalOption) ? item?.plan?.planAutoRenewalOption : true,
    };
  });

  return {
    order_list: initialValues,
    coupon_code: data?.promoCode || "",
    helperFields: {
      shouldRedirect: false
    }
  };
};

const processBody = (data) => {
  const body = data?.map((item) => {
    let mnpList = [];

    if (item.order_mnpNumbers) {
      mnpList = item?.order_mnpNumbers.map((nums) => {
        let mnpNumberBody = {
          number: nums.number,
          simType: nums.simType,
          realNameRegistered: nums.realNameRegistered,
          effectiveDate: nums.effectiveDate,
          effectiveTime: nums.effectiveTime,
          agreeChangeOwner: nums.agreeChangeOwner,
          acceptedPlanMnpTnc: nums.acceptedPlanMnpTnc,
          unableToProvideOTP: nums.unableToProvideOTP,
          phoneOtpId: nums.phoneOtpId,
          networkOperator: nums.networkOperator,
        };

        if (nums.simType === "postpaid") {
          delete mnpNumberBody.acceptedPrepaidTnc;
          delete mnpNumberBody.realNameRegistered;
        } else {
          delete mnpNumberBody.acceptedPostpaidTnc;
          if (nums.realNameRegistered !== true) {
            delete mnpNumberBody.acceptedPrepaidTnc;
          }
        }

        return mnpNumberBody;
      });
    }

    let newNumList = {};

    if (item.order_newNumQty > 0) {
      newNumList = {
        newNumbersEffectiveDate: item.order_newNumbersEffectiveDate,
      };
    }

    return {
      ...newNumList,
      newNumberPoolIds: item.order_newNumQty > 0 ?
        item.order_newNumList ?
          item.order_newNumList.map((item) => item._id)
          : []
        : [],
      orderId: item?._id,
      isAutoRenewal: item?.order_isAutoRenewal || false,
      mnpNumbers: mnpList,
    };
  });

  return body;
};






const validationSchema = Yup.object().shape({
  order_list: Yup.array().of(
    Yup.object({
      type: Yup.string(),
      order_newNumList: Yup.array().when(["type", "order_newNumQty"], (data, schema) => {
        const type = data?.[0];
        const val = data?.[1];
        return type === CART_ITEM_TYPE.PLAN && val > 0
          ? schema.min(val, "checkout:warnPleaseChooseNumber").required("error:required")
          : schema;
      }),
      order_mnpNumbers: Yup.array().when(["type", "order_mnpNumQty"], (data, schema) => {
        const type = data?.[0];
        const val = data?.[1];
        return type === CART_ITEM_TYPE.PLAN && val > 0
          ? schema.of(
            Yup.object().shape({
              number: Yup.string().required("error:required"),
            })
          ).min(val, "checkout:warnPleaseChooseNumber").required("error:required")
          : schema;
      }),
      order_newNumbersEffectiveDate: Yup.string().when(["type", "order_newNumQty"], {
        is: (type, val) => type === CART_ITEM_TYPE.PLAN && val > 0,
        then: (schema) => schema.required("error:required"),
      }),
    })

  )
}
)

const OrderListForm = forwardRef(({
  children,
  data,
  step,
  currentStep,
  setStep,
  refetch,
  setIsPreview,
  autoSave,
  setAutoSave,
}, ref) => {
  const { globalDispatch } = useContext(GlobalContextStore);
  const { t, i18n } = useTranslation("checkout");



  useEffect(() => {
    if (autoSave[currentStep?.id]) {
      formik.submitForm();
    }
  }, [autoSave]);



  const formik = useFormik({
    enableReinitialize: false,
    initialValues: getInitialValues(data),
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload, { validateForm }) => {
      console.log("submit order list", payload);

      // setAutoSave({...autoSave, 1: true});
      const havePlan = _.findIndex(payload.order_list, ["type", "plan"]) > -1

      if (havePlan) {
        const numberBody = processBody(
          _.filter(payload.order_list, ["type", "plan"])
        );
        console.log("save order list", numberBody);

        let result = await Promise.all(
          numberBody.map(async (item) => {
            const { orderId, ...remaining } = item;
            return addNumberToPlan(remaining, orderId);
          })
        );

        const errorsInAddNumber = result.filter((item) => !!item?.result?.errorMessage);
        if (errorsInAddNumber.length > 0) {
          throw new Error({
            code: errorsInAddNumber[0].result.code,
            message: GetWord(errorsInAddNumber[0].result.errorMessage),
          })
        }

        if (result) {
          refetch();
        }
        if (payload.helperFields.shouldRedirect) {
          setIsPreview(true);
        }
      } else {
        setIsPreview(true);
      }
    },
  });

  const handleSave = async (isPreview) => {
    try {
      await formik.setValues({ ...formik.values, helperFields: { shouldRedirect: isPreview } })
      await formik.submitForm()
    } catch (e) {
      appearGlobalError(globalDispatch, e?.result?.code, e?.result?.errorMessage, {
        type: "fail",
      });
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepContainer
          step={step}
          setStep={setStep}
          currentStep={currentStep}
          title={t("orderInformation")}
          lastStep
          onSave={() => handleSave()}
          onProceedNextStep={() => handleSave(true)}
          isLastStep={true}
          ref={ref}
        >
          {children}
        </StepContainer>
      </Form>
    </FormikProvider>
  );
});

export default OrderListForm;

import styled from 'styled-components';
import theme from '../theme';
import CommonCheckbox from './Common/CommonCheckbox';
import Typography from './Typography';
import Price from './Price/PriceText';
import PriceUnit from './Price/PriceUnitText';
import OriginalPrice from './Price/OriginalPrice';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SimpleDialog from './CommonDialog/SimpleDialog';
import { useState } from 'react';
import { GetWord, formatCurrency, isHtml } from '../utils/common';

const Tag = styled.div`
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  background: ${theme.paleYellow};
  height: 28px;
  width: fit-content;
  padding: 0 30px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  && {
    font-size: 14px;
    text-shadow: 0 3px 6px #c7b645;
    color: #333;
    font-size: ${theme.fontSize.smallTag};
    font-weight: bold;
  }
`;

const Container = styled.div`
  border-radius: 25px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #fff;
  box-shadow: ${(props) =>
    props?.selected ? '0 0 10px 0 #dbbd00' : '0 3px 10px 0 #c9c9c9'};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 140px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${(props) => !props?.isRecommend && `opacity: 0`};
`;

const PriceContainer = styled.div``;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

function Option({
  isRecommend,
  id,
  label,
  price,
  priceUnit,
  originalPrice,
  detail,
  tnc,
  selected,
  CheckboxProps,
  onClick,
  tagLabel,
  isBasePrice = false,
}) {
  const { t, i18n } = useTranslation('common');
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isTncsOpen, setIsTncsOpen] = useState(false);

  const handleOpenDetails = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDetailsOpen((prev) => !prev);
  };

  const handleOpenTncs = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTncsOpen((prev) => !prev);
  };

  return (
    <>
      <Container selected={selected} onClick={() => onClick(id)}>
        <TagContainer isRecommend={isRecommend}>
          <Tag>{tagLabel}</Tag>
        </TagContainer>
        <SubContainer style={{ padding: 20, paddingTop: 0 }}>
          <CommonCheckbox
            checked={selected}
            label={label}
            {...CheckboxProps}
            typographyProps={{ ...CheckboxProps.typographyProps, fontSize: 20 }}
          />
          <div style={{ flex: 1 }} />
          <Bottom>
            <ActionContainer>
              {detail && (
                <Typography
                  color="dirtyYellow"
                  style={{ cursor: 'pointer' }}
                  onClick={handleOpenDetails}
                >
                  {t('details')}
                </Typography>
              )}
              {tnc && (
                <Typography
                  color="dirtyYellow"
                  style={{ cursor: 'pointer' }}
                  onClick={handleOpenTncs}
                >
                  {t('mobile:termsAndConditions')}
                </Typography>
              )}
            </ActionContainer>
            <PriceContainer>
              <Typography
                fontSize={theme.fontSize.title4}
                textAlign="right"
                condensed
              >
                {`${!isBasePrice ? '+ ' : ''}HK$`}{' '}
                <Price fontSize={theme.fontSize.price}>{price}</Price>
                {priceUnit && (
                  <PriceUnit fontSize={theme.fontSize.priceUnit}>
                    /{priceUnit}
                  </PriceUnit>
                )}
              </Typography>
              {!isNaN(originalPrice) && (
                <Typography
                  color={theme.secondary}
                  fontSize={theme.fontSize.originalPrice}
                  textAlign="right"
                  condensed
                >
                  {`${t('originalPrice')} `}
                  <OriginalPrice fontSize={theme.fontSize.originalPrice}>
                    {`HK${formatCurrency(originalPrice)}`}
                  </OriginalPrice>
                </Typography>
              )}
            </PriceContainer>
          </Bottom>
        </SubContainer>
      </Container>
      {isDetailsOpen && (
        <SimpleDialog isOpen={isDetailsOpen} setIsOpen={setIsDetailsOpen} title={label}>
          {isHtml(GetWord(detail)) ? (
            <div dangerouslySetInnerHTML={{ __html: GetWord(detail) }} />
          ) : (
            <Typography>{GetWord(detail)}</Typography>
          )}
        </SimpleDialog>
      )}
      {isTncsOpen && (
        <SimpleDialog isOpen={isTncsOpen} setIsOpen={setIsTncsOpen} title={GetWord(tnc?.title) || label}>
          {isHtml(GetWord(tnc?.description)) ? (
            <div dangerouslySetInnerHTML={{ __html: GetWord(tnc?.description) }} />
          ) : (
            <Typography>{GetWord(tnc?.description)}</Typography>
          )}
        </SimpleDialog>
      )}
    </>
  );
}

export default Option;

Option.propTypes = {
  isRecommend: PropTypes.bool,
  label: PropTypes.string,
  price: PropTypes.string,
  priceUnit: PropTypes.string,
  originalPrice: PropTypes.string,
  selected: PropTypes.bool,
  circle: PropTypes.bool,
};

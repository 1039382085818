import { useState } from "react";
import styled from "styled-components";
import MyTypography from "../../../../components/Typography";
import Space from "../../../../components/Space";
import { useTranslation } from "react-i18next";
import IconAddMnp from "../../../../assets/ic-addMnp.svg";
import IconEdit from "../../../../assets/ic-edit.svg";
import IconDelete from "../../../../assets/ic-delete.svg";
import ExistingNumberDialog from "./ExistingNumberDialog";
import { useField } from "formik";
import ErrorText from "../../../../components/Typographies/ErrorText";
import { isEmpty } from "lodash";
import theme from "../../../../theme";

const AddNumberBox = ({ name, tncData, idx, onDelete, error }) => {
  const { t } = useTranslation("checkout");
  const [field, meta, helpers] = useField(name);
  const [isOpen, setIsOpen] = useState(false);

  const [savedNumber, setSavedNumber] = useState(null);

  const handleDeleteNumber = () => {
    setSavedNumber(null);
    onDelete && onDelete(idx);
  }

  const _addNumber = () => {
    return (
      <Container error={!isEmpty(error)}>
        <MyTypography fontSize="18" color="#a7a7a7">
          {t("mobileNumber")}
        </MyTypography>
        <AddButton onClick={() => setIsOpen(true)}>
          <img src={IconAddMnp} />
          <Space width="5px" />
          <MyTypography fontSize="18" bold>
            {t("add")}
          </MyTypography>
        </AddButton>
      </Container>
    );
  };

  const _editNumber = () => {
    return (
      <Container error={!isEmpty(error)}>
        <MyTypography fontSize="18">{field?.value?.number}</MyTypography>
        <div style={{ display: "flex" }}>
          <IconButton onClick={() => setIsOpen(true)}>
            <img src={IconEdit} />
          </IconButton>
          <Space width="15px" />
          <IconButton onClick={handleDeleteNumber}>
            <img src={IconDelete} />
          </IconButton>
        </div>
      </Container>
    );
  };

  return (
    <div>
      {field?.value?.number ? _editNumber() : _addNumber()}
      <ErrorText>{t(error)}</ErrorText>
      <ExistingNumberDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        name={name}
        tncData={tncData}
        savedData={savedNumber}
        setSavedData={setSavedNumber}
      />
    </div>
  );
};

const Container = styled.div`
  border-radius: 15px;
  box-shadow: 0 0 10px 0 #c9c9c9;
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props?.error && `
      box-shadow: 0 0 10px 0 ${theme.error3} !important;
  `}
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default AddNumberBox;

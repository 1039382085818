import styled from "styled-components";
import Typography from "../components/Typography";
import theme from "../theme";
import ImageTitleBg from "../assets/image-titleBg.svg";
import ImageTitleBgMobile from "../assets/image-titleBgMobile.svg";
import { breakpoint } from "../utils/responsive";

const Container = styled.div`
  height: 172px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media ${breakpoint.desktop} {
    background-image: url(${ImageTitleBg});
  }

  @media ${breakpoint.mobile} {
    background-image: url(${ImageTitleBgMobile});
  }
`;

const CustomTypography = styled(Typography)`
  && {
    text-shadow: 0 3px 6px #c7b645;
    font-weight: bold;
  }
`;

function Header({ children }) {
  return (
    <Container>
      <CustomTypography
        color={theme.lightBlack}
        fontSize={theme.fontSize.header}
      >
        {children}
      </CustomTypography>
    </Container>
  );
}

export default Header;

import ItemTableRow from "../../components/OrderItems/ItemTableRow";
import { Divider } from "@mui/material";
import styled from "styled-components";
import Space from "../../components/Space";
import { ResponsiveUI } from "../../utils/responsive";
import { GetWord, getItemColumnWidth } from "../../utils/common";

const DeviceDetail = ({ data, extraWidthForButton }) => {
  const colWidth = getItemColumnWidth("product");

  return (
    <div>
      <ResponsiveUI
        desktop={
          <DividerContainer>
            <Divider style={{ width: 'calc(100% - 170px)' }} />
          </DividerContainer>
        }
        mobile={
          <>
            <Divider />
            <Space size="20px" />
          </>
        }
      />

      {data &&
        data.map((item) => {
          return (
            <ItemTableRow
              row={[GetWord(item?.optionLabel), GetWord(item?.label)]}
              colWidth={colWidth}
              extraWidthForButton={extraWidthForButton}
              extraIconWidth={true}
            />
          );
        })}
    </div>
  );
};

const DividerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px 30px 0px;
`;

export default DeviceDetail;

import { Grid } from "@mui/material";
import Divider from "../../components/Divider";
import Typography from "../../components/Typography";
import Breadcrumbs from "../../components/Breadcrumbs";
import theme from "../../theme";
import { useTranslation } from "react-i18next";

const Header = ({ label, productId="", ...props }) => {    
    const { t } = useTranslation(['device', 'home']);

    const getBreadcrumbsData = () => {
        return [
            { label: t('home', { ns: 'home' }), href: "/" },
            { label: t('electronicDevices'), href: `/device/${productId}` },
        ]
    }

    return (
        <Grid container gap={'16.98px'} flexDirection={'column'}>
            <Grid item container gap={'2px'} flexDirection={'column'}>
                <Grid item>
                    <Breadcrumbs data={getBreadcrumbsData()} />
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: '24px', color: theme.lightBlack, fontWeight: 'bold' }}>{label || ""}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Divider orientation="horizontal" flexItem />
            </Grid>
        </Grid>
    )
}

export default Header;
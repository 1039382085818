import { useState } from 'react'
import styled, { keyframes } from 'styled-components'

const HeaderIconContainer = styled.div`
    height: ${props => props.size};
    width: ${props => props.size};
    border-radius: ${props => props.borderRadius};
    box-shadow: 0 3px 10px 0 #c9c9c9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: ${props => `calc(${props.size} - 13.5%)`};
        width: ${props => `calc(${props.size} - 13.5%)`};
        border-radius: 22px;
    }
`

const YellowCircle = styled.div`
    height: ${props => props.size};
    width: ${props => props.size};
    border-radius: 50%;
    background-color: #ffe200;
    position: absolute;
    top: -3px;
    right: -3px;
`

const BlueCircle = styled.div`
    height: ${props => props.size};
    width: ${props => props.size};
    border-radius: 50%;
    background-color: #666666;
    position: absolute;
    bottom: -3px;
    left: -3px;
`

function Icon({ size = 50, borderRadius = 50, children }) {
    return (
        <div style={{ position: 'relative', height: size, width: size }}>
            <HeaderIconContainer size={`${size}px`} borderRadius={`${borderRadius}px`}>
                {children}
            </HeaderIconContainer>
            <YellowCircle size={`${size / 5}px`} />
            <BlueCircle size={`${size / 5}px`} />
        </div>
    )
}

export default Icon
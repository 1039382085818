import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useLocalStorageFormData = ({ formik, initialValues={}, formKey }) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;

    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        const storedValues = JSON.parse(localStorage.getItem(formKey));
        if (storedValues && storedValues?.lang && storedValues?.lang !== lang) {
            setFormValues(storedValues?.values);
        }
    }, [formKey]);

    useEffect(() => {
        if (formik.values) {
            const formValue = { lang, values: formik.values }
            localStorage.setItem(formKey, JSON.stringify(formValue));
        }
    }, [formik.values, formKey]);

    return {
        formValues,
        setFormValues,
    }
}
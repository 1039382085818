import styled from "styled-components";

const Container = styled.div`
    ${props => props.borderRadius && `border-bottom-left-radius: ${props.borderRadius}px`};
    ${props => props.borderRadius && `border-top-right-radius: ${props.borderRadius}px`};
    ${props => props.width && `width: ${props.width}px`};
    ${props => props.height && `height: ${props.height}px`};
    box-shadow: 0 3px 15px 0 #c9c9c9;
    background-color: #fff;
`

export default Container;
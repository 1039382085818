import Container from "../../../components/Container";
import { useTranslation } from "react-i18next";
import { deleteAddress } from "../../../apis";
import { updateUserInfo } from "../../../requests/globalRequest";
import AddressSection from "./common/AddressSection";
import theme from "../../../theme";

const PADDING_VALUE = '38px';

const ShippingAddress = (props) => {
    const { data, globalDispatch, onAfterAction=null } = props;

    const { t } = useTranslation('profile');

    const handleEditItem = async (item) => {
        console.log('==onClick edit item', item)
        // handleSave is handled by dialog, so nothing to do here.
        onAfterAction && onAfterAction();
    }

    const handleDeleteItem = async (item) => {
        console.log('==onClick confirm delete button', item)
        try {
            const result = await deleteAddress(item?.id);

            if (result) {
                onAfterAction && onAfterAction();
                globalDispatch && updateUserInfo(globalDispatch);
            }
        } catch (e) {
            console.log("==delete address error - ", e);
        }
    }

    return (
        <Container style={{ padding: `${PADDING_VALUE} 0`, flexDirection: 'row', width: '100%', boxShadow: theme.outerContainerShadow }}>
            <AddressSection 
                data={data} 
                title={t('accountProfile.shippingAddress')}
                onEdit={handleEditItem}
                onDelete={handleDeleteItem}
                showSameAsMailingCheckbox={true}
            />
        </Container>
    )
}

export default ShippingAddress;
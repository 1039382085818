export const APPEAR_GLOBAL_ERROR = "APPEAR_GLOBAL_ERROR";
export const DISAPPEAR_GLOBAL_ERROR = "DISAPPEAR_GLOBAL_ERROR";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export const UPDATE_INIT = "UPDATE_INIT";

export const SHOW_COOKIE_CONSENT = "SHOW_COOKIE_CONSENT";
export const HIDE_COOKIE_CONSENT = "HIDE_COOKIE_CONSENT";
export const UPDATE_COOKIE_CONSENT_HEIGHT = "UPDATE_COOKIE_CONSENT_HEIGHT";

export const UPDATE_CART_COUNT = "UPDATE_CART_COUNT";

export const SHOW_GREETING_DIALOG = "SHOW_GREETING_DIALOG";
export const HIDE_GREETING_DIALOG = "HIDE_GREETING_DIALOG";
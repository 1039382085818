import { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import theme from "../theme";
import SimpleDialog from "./CommonDialog/SimpleDialog";
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { breakpoint } from "../utils/responsive";
import { ReactComponent as ZoomInIcon } from '../assets/ic-zoomLens.svg';
import { ReactComponent as LeftOutlinedIcon } from '../assets/ic-smallArrowLeft.svg'
import { ReactComponent as RightOutlinedIcon } from '../assets/ic-smallArrowRight.svg'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const MainImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: zoom-in;
    width: 100%;

    @media ${breakpoint.mobile} {
        align-self: center;
        text-align: center;
    }
`;

const MainImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: min(100%, 455px);
    min-height: 455px;
    max-width: 455px;
    max-height: 455px;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    @media ${breakpoint.mobile} {
        min-height: min(455px, calc(100vw - 60px));
    }
`

const CustomZoomInIcon = styled(ZoomInIcon)`
    margin-top: 8px;
    margin-bottom: 20px;
    color: ${theme.blackGradientColor};
`

const ZoomInImageContainer = styled.div`
    text-align: center;
    max-height: calc(100vh - 200px);
`

const ImageListContainer = styled(Grid)`
    justify-content: center;
    position: relative;
    align-items: center;

    .swiper {
        padding: 10px 0;
        // max-width: 420px;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .control-btn {
        position: absolute;
        cursor: pointer;
        z-index: 99;
        margin: -20px;
    }
`

const ImageListItem = styled.div`
    width: 4rem;
    height: 4rem;
    box-shadow: ${props => props.isCurrent ? `rgb(219, 189, 0) 0 0 6px 0` : (props.optionSelected ? `rgb(219, 189, 0, 0.5) 0 0 6px 0` : `rgb(0, 0, 0, 0.25) 0 0 6px 0`)};
    /* border: ${props => props.isCurrent ? `1px solid ${theme.paleYellow}` : '1px solid #c9c9c9'}; */
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        border: 1px solid ${theme.paleYellow};
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    @media ${breakpoint.mobile} {
        // width: 3rem;
        // height: 3rem;
        width: calc((100vw - 60px - 100px - 30px)/5);
        height: calc((100vw - 60px - 100px - 30px)/5);
    }
`

const CustomLeftOutlinedIcon = styled(LeftOutlinedIcon)`
    display: ${props => props.show ? 'block' : 'none !important'};
    left: 0;
`

const CustomRightOutlinedIcon = styled(RightOutlinedIcon)`
    display: ${props => props.show ? 'block' : 'none !important'};
    right: 0;
`

const ImageList = (props) => {
    const { images=[], currentImg, setCurrentImg, optionSelected } = props;

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const handleItemOnSelect = (e) => {
        setCurrentImg(e.index);
    }

    const _renderImageListItem = (img, index, isCurrent) => (
        <div style={{ padding: '0 5px' }}>
            <ImageListItem 
                id={`${img.url}-${index}`} 
                onMouseOver={() => handleItemOnSelect(img)} 
                onClick={() => handleItemOnSelect(img)}
                isCurrent={isCurrent}
                optionSelected={optionSelected}
            >
                <img src={img.url} alt={`Product Image ${index} Preview`} />
            </ImageListItem>
        </div>
    )

    return (
        <ImageListContainer item container>
            <CustomLeftOutlinedIcon className="control-btn" ref={prevRef} show={images.length > 5} />
            <Swiper
                navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                }}
                observer={true}
                watchSlidesProgress={true}
                modules={[Navigation]}
                // centeredSlides={true}
                slidesPerView={images.length >= 5 ? 5 : images.length}
            >
                {   
                    images.map((img, index) => (
                        <SwiperSlide key={`${img.url}-${index}`}>
                            {_renderImageListItem(img, index, index === currentImg)}
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <CustomRightOutlinedIcon className="control-btn" ref={nextRef} show={images.length > 5} />
        </ImageListContainer>
    )
}

const ImagePreviewer = (props) => {
    const { images, optionSelected=false } = props;

    const [currentImg, setCurrentImg] = useState(0);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const handleOnClickImage = () => {
        if (images.length === 0) return;
        setDialogIsOpen(true);
    }

    useEffect(() => {
        if (images.length === 0) return;
        setCurrentImg(0);
    }, [images])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '5px' }}>
            <MainImageContainer onClick={handleOnClickImage}>
                {/* <ReactImageMagnify
                    {...{
                        smallImage: {
                            alt: images[currentImg].alt || 'Product Image',
                            isFluidWidth: true,
                            src: images[currentImg].url,
                        },
                        largeImage: {
                            src: images[currentImg].url,
                            width: 900,
                            height: 900
                        },
                        enlargedImageContainerStyle: {
                            zIndex: 9999
                        },
                        isHintEnabled: true,
                        shouldHideHintAfterFirstActivation: false,
                        hintComponent: () => <CustomZoomInIcon style={{ paddingBottom: '10px' }} />,
                        isEnlargedImagePortalEnabledForTouch: true,
                        style: { cursor: 'zoom-in' }
                    }}
                /> */}
                <MainImageWrapper>
                    <img src={images?.[currentImg]?.url} alt={images?.[currentImg]?.alt || ""} />
                </MainImageWrapper>
                <CustomZoomInIcon />
            </MainImageContainer>

            <ImageList 
                images={images && images.map((img, index) => {return {url: img?.url || "", index: index}})} 
                setCurrentImg={setCurrentImg} 
                currentImg={currentImg}
                optionSelected={optionSelected}
            />

            { dialogIsOpen && (
                <SimpleDialog isOpen={dialogIsOpen} setIsOpen={setDialogIsOpen} hasSpace={false} containerStyle={{ overflow: 'hidden' }}>
                    <ZoomInImageContainer>
                        <img src={images[currentImg]?.url} alt={images[currentImg]?.alt || `Product Image ${currentImg}`} style={{ maxWidth: '100%', maxHeight: 'inherit' }} />
                    </ZoomInImageContainer>
                </SimpleDialog>
            )}
        </div>
    )
} 

export default ImagePreviewer;
import CardBase from "../Card/CardBase";
import ItemTable from "./ItemTable";
import { useTranslation } from "react-i18next";
import { GetWord, getItemColumnWidth } from "../../utils/common";
import RedditIcon from "@mui/icons-material/Reddit";

const CommonContent = ({ title, row, extraWidthForButton, icon, iconNeedDotDecoration, cardBaseStyle=null }) => {
  const { t } = useTranslation("orderContentItem");
  return (
    <CardBase style={{ ...cardBaseStyle }}>
      <ItemTable
        type={"product"}
        colWidth={getItemColumnWidth("product")}
        header={[title, t("selectedDetails"), t("quantity"), t("price")]}
        row={row}
        icon={<img src={icon} />}
        iconNeedDotDecoration={iconNeedDotDecoration}
        extraWidthForButton={extraWidthForButton}
      />
    </CardBase>
  );
};

export default CommonContent;

import ReactGA from "react-ga4"
import { getGAFormattedProduct, getGATotalValue, getGAFormattedProductList } from "./common";

export const getGACode = () => {
    return 'G-W5R979TJTM';
}

export const initGA = () => {
    // ReactGA.initialize(getGACode());
}

const gaEvent = (category, event_name, label, data=null) => {
    let event_params = {
        // category,
        // label,
        ...data
    }

    // ReactGA.event(event_name, event_params);
}

export const gaNavigationBar = (data) => {
    gaEvent('Click', 'navigation_bar', 'Page Navigation', {...data});
}

export const gaSocialClick = (data) => {
    gaEvent('Click', 'social_click', 'Page Navigation', {...data});
}

export const gaProductCategoryClick = (data) => {
    gaEvent('Click', 'product_categories_click', 'Page Navigation', {...data});
}

export const gaSignUpSubmit = () => {
    gaEvent('Click', 'signup_submit', 'Sign Up Form Submit');
}

export const gaSignUpFail = () => {
    gaEvent('Click', 'signup_fail', 'Submit Sign Up Form Fail');
}

export const gaLoginClick = () => {
    gaEvent('Click', 'login_click', 'Page Navigation');
}

export const gaLoginFail = () => {
    gaEvent('Click', 'login_fail', 'Login Fail');
}

export const gaOutOfStock = (data) => {
    // Onclick ok button in out of stock dialog
    gaEvent('Click', 'out_of_stock_ok', 'Out of Stock', {...data});
}

// Ecormmerce related GA events
const gaEcommerceEvent = (event_name, data) => {
    const payload = {
        ...data,
        currency: 'HKD',
        value: data?.items ? data?.items.reduce((acc, item) => acc + item.price * item?.quantity || 1, 0) : 0,
        items: data?.items && data.items.length > 0 ? data?.items : []
    }

    if (data?.value) {
        payload.value = data.value;
    }

    gaEvent('Ecommerce', event_name, 'Ecommerce', payload);
}

export const gaApplyPromoCode = (coupon) => {
    gaEcommerceEvent('apply_promocode', { coupon });
}

export const gaViewItemList = (products, listInfo) => {
    const payload = getGAFormattedProductList(products, listInfo);
    gaEcommerceEvent('view_item_list', {
        value: getGATotalValue(products),
        items: payload,
        ...listInfo,
    });
}

export const gaSelectItem = (product) => {
    const payload = getGAFormattedProduct(product);
    gaEcommerceEvent('select_item', {
        value: getGATotalValue([product]),
        items: [payload],
    });
}

export const gaViewItem = (product) => {
    const payload = getGAFormattedProduct(product);
    gaEcommerceEvent('view_item', {
        value: getGATotalValue([product]),
        items: [payload],
    });
}

export const gaAddToCart = (product) => {
    const payload = getGAFormattedProduct(product);
    gaEcommerceEvent('add_to_cart', {
        value: getGATotalValue([product]),
        items: [payload],
    });
}

export const gaViewCart = (products) => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gaEcommerceEvent('view_cart', {
        value: getGATotalValue(products),
        items: payload,
        coupon: "NA",
    });
}

export const gaRemoveFromCart = (product) => {
    const payload = getGAFormattedProduct(product);
    gaEcommerceEvent('remove_from_cart', {
        value: product?.discountedSubtotal,
        items: [payload],
    });
}

export const gaBeginCheckout = (products, coupon="NA") => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gaEcommerceEvent('begin_checkout', {
        value: getGATotalValue(products),
        items: payload,
        coupon,
    });
}

export const gaAddShippingInfo = (products) => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gaEcommerceEvent('add_shipping_info', {
        value: getGATotalValue(products),
        items: payload,
        coupon: "NA",
    });
}

export const gaAddPaymentInfo = (products, coupon="NA") => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gaEcommerceEvent('add_payment_info', {
        value: getGATotalValue(products),
        items: payload,
        coupon,
        payment_type: "credit_card",
    });
}

export const gaPurchase = (products, transactionInfo) => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gaEcommerceEvent('purchase', {
        value: getGATotalValue(products),
        items: payload,
        ...transactionInfo,
    });
}

export const gaContinueShopping = (products) => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gaEcommerceEvent('continue_shopping', {
        value: getGATotalValue(products),
        items: payload,
        coupon: "NA",
    });
}

// Ecommerce related GA events (banner/promotion)
export const gaSelectPromotion = (value) => {
    gaEcommerceEvent('select_promotion', {
        items: [],
        value,
    });
}

export const gaViewPromotion = (value) => {
    gaEcommerceEvent('view_promotion', {
        items: [],
        value,
    });
}
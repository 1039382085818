import { Divider, Grid } from "@mui/material";
import Typography from "../../../components/Typography";
import theme from "../../../theme";
import { WindowSize } from "../../../utils/responsive";

const RemarkFieldItem = ({ label="", value="", noDivider=false, valueFontsize=null }) => {
    const isMobile = WindowSize() === 'mobile';

    return (
        <Grid item container>
            <Grid 
                item 
                container 
                alignItems={isMobile ? 'flex-start' : 'center'} 
                style={{ padding: isMobile ? "30px" : "30px 50px" }} 
                direction={isMobile ? 'column' : 'row'}
                rowGap={'8px'}
            >
                { label !== "" && (
                    <Grid item style={{ width: isMobile ? "100%" : '12%' }}>
                        <Typography fontSize={isMobile ? 18 : 16} style={{ color: theme.lightBlack }}>{label}</Typography>
                    </Grid>
                )}
                <Grid item>
                    <Typography fontSize={valueFontsize ? valueFontsize : (isMobile ? 20 : 18)} style={{ fontWeight: 500, color: theme.lightBlack }}>{value}</Typography>
                </Grid>
            </Grid>
            { !noDivider && <Divider style={{ width: "100%" }} /> }
        </Grid>
    )
}

export default RemarkFieldItem;
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useField, useFormikContext } from 'formik';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fetchNewNumberActivationConfig } from '../../../../apis';
import YellowBubbleCheckbox from '../../../../components/Checkboxs/YellowBubbleCheckbox';
import DatePicker from '../../../../components/DateTimePicker/DatePicker';
import NumberInput from '../../../../components/NumberInput';
import Select from '../../../../components/Select';
import Space from '../../../../components/Space';
import ErrorText from '../../../../components/Typographies/ErrorText';
import MyTypography from '../../../../components/Typography';
import theme from '../../../../theme';
import { DateRangeHelper } from '../../../../utils/dateRange';
import { breakpoint } from '../../../../utils/responsive';
import { CheckoutFormContext } from '../../context/CheckoutFormContext';
import NewNumberDialog from './NewNumberDialog';


export const NEW_NUMBER_EFFECTIVE_TIMESLOT = [
  {
    title: '9am - 1pm',
    value: 'am',
  },
  {
    title: '2pm - 6pm',
    value: 'pm',
  },
];






const NewNumber = ({ name, totalPlanNum, idx, required }) => {
  const { t } = useTranslation('checkout');
  const [field, meta, helpers] = useField(`${name}`);
  const { values } = useFormikContext();
  const [isOpenNemNum, setIsOpenNewNum] = useState(false);
  const { calendarQuery } = useContext(CheckoutFormContext)

  const activationQuery = useQuery({
    queryKey: ["fetchNewNumberActivationConfig"],
    queryFn: fetchNewNumberActivationConfig,
    refetchOnWindowFocus: false
  })





  const dateRangeHelper = useMemo(() => {
    const newNumberActivationMinDay = activationQuery.data?.data?.find(e => e.keyPath === "plan.newNumberActivationMinDay")?.value || 5
    const newNumberActivationMaxDay = activationQuery.data?.data?.find(e => e.keyPath === "plan.newNumberActivationMaxDay")?.value || 14
    const obj = new DateRangeHelper(
      dayjs().startOf('day'),
      newNumberActivationMinDay,
      newNumberActivationMaxDay,
      {
        day: [0, 6], // weekend
        otherDates: calendarQuery.data?.data ?? []
      }
    )
    return obj

  }, [activationQuery.data, calendarQuery.data?.data])




  //update new number list if mnp number change
  useEffect(() => {
    const mnpNumQty = field.value?.order_mnpNumQty;
    const remain = totalPlanNum - mnpNumQty;
    const tempNumList =
      field.value.order_newNumList.length > remain
        ? _.dropRight(field.value.order_newNumList)
        : field.value.order_newNumList;

    helpers.setValue({
      ...field.value,
      order_newNumQty: remain,
      order_newNumList: tempNumList,
    });
  }, [field.value?.order_mnpNumQty]);

  // const newNumberFirstEffectiveDate = 

  const handleNumberOnChange = (number) =>
    helpers.setValue({ ...field.value, order_newNumQty: number });

  return (
    <Container>
      <SubContainer>
        <Quantity>
          <MyTypography bold>{t('newNumber')}</MyTypography>
          <NumberInput
            min={0}
            max={totalPlanNum}
            value={field?.value?.order_newNumQty}
            onChange={handleNumberOnChange}
          />
        </Quantity>
        {field?.value?.order_newNumQty > 0 && (
          <div>
            <MyTypography fontSize="18" fontWeight="bold">
              <span>{t('chooseYourNewNumber')}</span>
              {required && <span style={{ color: theme.orange }}> *</span>}
            </MyTypography>
            <Space size="20px" />
            <Numbers>
              {field?.value?.order_newNumList?.map((num) => {
                return (
                  <YellowBubbleCheckbox
                    label={num?.number}
                    checked={true}
                    disableChange
                    style={{
                      // margin: 10,
                      width: 230,
                    }}
                  />
                );
              })}
            </Numbers>
            <Space size="25px" />
            <ChangeNumberButton onClick={() => setIsOpenNewNum(true)}>
              {t('changeNumber')}
            </ChangeNumberButton>
            {meta?.error?.order_newNumList && (
              <ErrorText>{t(meta?.error?.order_newNumList, { num: field?.value?.order_newNumQty })}</ErrorText>
            )}
            <Space size="50px" />
            <DatePicker
              id={`${name}.order_newNumbersEffectiveDate`}
              title={t('effectiveDateTime')}
              minDate={dateRangeHelper.startDate}
              maxDate={dateRangeHelper.endDate}
              shouldDisableDate={(date) => dateRangeHelper.shouldDisableDate(date)}
            />
            <Space size="10px" />

          </div>
        )}

        <NewNumberDialog
          name={name}
          isOpen={isOpenNemNum}
          setIsOpen={setIsOpenNewNum}
        />
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  // height: 100%;

  @media ${breakpoint.desktop} {
    border-right: 1px solid #e0e0e0;
  }
`;

const SubContainer = styled.div`
  padding: 25px;
  @media ${breakpoint.desktop} {
    padding: 50px;
  }
`;

const Quantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media ${breakpoint.mobile} {
    margin-bottom: 15px;
  }
`;

const ChangeNumberButton = styled(MyTypography)`
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
`;

const Numbers = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`;

export default NewNumber;

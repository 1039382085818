import styled from "styled-components";
import Form from "./Form";
import { Grid } from "@mui/material";
import MyTypography from "../../../components/Typography";
import TNCItem from "../tnc/TncItem";
import { useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import theme from "../../../theme";
import { ACCEPTED_TNCS_KEYS } from "../hooks/useCheckoutInitialRequiredTncs";
import { usePreviewOrderTnc } from "../hooks/usePreviewOrderTnc";
import Divider from "../../../components/Divider";

const PreviewTermAndCondition = ({ acceptedTNC, setAcceptedTNC }) => {
  const { t } = useTranslation("checkout");
  const { errors } = useFormikContext();

  const { allProductOverallTncs, allPlanOverallTncs } = usePreviewOrderTnc();
  const [field, meta] = useField('tnc_accept_status');

  const totalTncCount = allProductOverallTncs?.length + allPlanOverallTncs?.length;

  return (
    <Form title={t("termsAndConditions")} isCollapsible={false}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {[...allProductOverallTncs, ...allPlanOverallTncs]?.map((item, index) => {
        const isLast = index === totalTncCount - 1;
        return (
          <Grid item xs={12}>
            <TNCItem
              id={item?._id}
              keyPath={ACCEPTED_TNCS_KEYS.overallTncs}
              title={item?.title}
              buttonLabel={item?.shortDescription}
              detail={item?.description}
              acceptedTNC={acceptedTNC}
              setAcceptedTNC={setAcceptedTNC}
              detailsTitle={item?.shortDescription}
              disableDivider
            />
            {!isLast && (<Divider />)}
          </Grid>
        );
      })}

      {errors.accepted_tnc && (
        <Grid item xs={12} paddingX={{ xs: "25px", md: "30px" }}>
          <MyTypography color={theme.error2}>
            {t(errors.accepted_tnc)}
          </MyTypography>
        </Grid>
      )}
    </Form>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default PreviewTermAndCondition;

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ImagePresent from "../../assets/image-present.png";
import MyTypography from "../../components/Typography";
import MyButton from "../../components/Button";
import { Link } from "react-router-dom";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import Space from "../../components/Space";
import theme from "../../theme";

const CompanyRegister = () => {
  const { t } = useTranslation("home");
  return (
    <Background>
      <Container>
        <Content>
          <Image src={ImagePresent} />
          <Title>
            <MyTypography bold fontSize="38" mFontSize="30" style={{ marginBottom: '8px' }}>
              {t("limitedOffer")}
            </MyTypography>
            <MyTypography fontSize="26" mFontSize="18">
              {t("notYetOurCustomer")}
            </MyTypography>
          </Title>
        </Content>
        <ResponsiveUI mobile={<Space size="30px" />} desktop={<div />} />
        <Link to={`/contactUs`} style={{ textDecoration: "none" }}>
          <MyButton>{t("register")}</MyButton>
        </Link>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  padding: 50px 30px;
  background-image: linear-gradient(to bottom, #fdfdfd, #efefef);
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${breakpoint.desktop} {
    align-items: center;
    flex-direction: row;
    max-width: ${theme.maxWidth.desktop};
  }

  @media ${breakpoint.mobile} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;

  @media ${breakpoint.desktop} {
    align-items: center;
    flex-direction: row;
  }

  @media ${breakpoint.mobile} {
    flex-direction: column;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoint.desktop} {
    margin-left: 20px;
  }
`;

const Image = styled.img`
  @media ${breakpoint.desktop} {
    height: 145px;
    width: 130px;
  }

  @media ${breakpoint.mobile} {
    height: 110px;
    width: 100px;
    margin-bottom: 30px;
  }
`;

export default CompanyRegister;

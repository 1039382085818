import { Divider, Grid } from "@mui/material";
// import { WindowSize } from "../../../utils/responsive";

const RemarkSectionContainer = (props) => {
    // const isMobile = WindowSize() === 'mobile';
    return (
        <div>
            <Divider style={{ width: "100%" }} />
            <Grid container direction={'column'}>
                {props.children}
            </Grid>
        </div>
    )
}

export default RemarkSectionContainer;
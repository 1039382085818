import styled from "styled-components";
import ExternalRemark from "./ExternalRemark";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 3px 8px 0 #c9c9c9;
  padding: 30px;
  overflow: hidden;
  gap: 38px;

  ${props => props.fullWidth && `width: 100%;`}
`;

const StyledContainer = (props) => {
  const {
    children,
    style=null,
    fullWidth=false,
    remark=null,
    remarkComponent,
    remarkIcon=null,
    remarkStyle=null
  } = props;

  return (
    <div>
      { remark && (<ExternalRemark value={remark} remarkComponent={remarkComponent} icon={remarkIcon} style={remarkStyle} />) }
      <Container 
        style={{...style}} 
        fullWidth={fullWidth}
      >
        {children}
      </Container>
    </div>
  )
}

export default StyledContainer;
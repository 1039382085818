import { useContext } from "react";
import { Grid } from "@mui/material";
import Container from "../../../components/Container";
import Typography from "../../../components/Typography";
import { useTranslation } from "react-i18next";
import InfoSection from "./common/InfoSection";
import { updateUserInfoContent } from "../../../apis";
import { appearGlobalError, updateUserInfo } from "../../../requests/globalRequest";
import { GlobalContextStore } from "../../../context/GlobalProvider";
import theme from "../../../theme";

const PADDING_VALUE = '38px';

const ContactInfo = (props) => {
    const { data, onAfterAction=null } = props;

    const { t } = useTranslation(['profile', 'common']);

    const { globalState, globalDispatch } = useContext(GlobalContextStore);

    const handleOnEdit = async (payload) => {
        console.log('==handleEditContactInfo', payload)

        const result = await updateUserInfoContent({ contactUserInfo: payload });
        if (result && result?.msg === 'success') {
            console.log('==edit contact info success')

            onAfterAction && onAfterAction();

            // update global state
            updateUserInfo(globalDispatch);
        } else {
            console.log('==edit contact info failed', result)
            appearGlobalError(
                globalDispatch, 
                result?.result?.code, 
                result?.result?.errorMessage, 
                {}
            )
            onAfterAction && onAfterAction();
        }
    }

    const handleOnDelete = async (item) => {
        // TODO
        console.log('==handleDeleteContactInfo', item)

        // onAfterAction && onAfterAction();
    }

    return (
        <Container style={{ padding: `${PADDING_VALUE} 0`, flexDirection: 'row', width: '100%', boxShadow: theme.outerContainerShadow }}>
            <Grid container direction="column" gap={PADDING_VALUE} style={{ width: '100%' }}>
                <Grid item>
                    <Typography fontSize={24} bold style={{ padding: `0 ${PADDING_VALUE}` }}>{t('accountProfile.contactInfo')}</Typography>
                </Grid>
                <InfoSection 
                    type={"contactInfo"}
                    data={data}
                    onEdit={handleOnEdit}
                    // onDelete={handleOnDelete}
                />
            </Grid>
        </Container>
    )
}

export default ContactInfo;
import { FieldArray, useFormikContext } from "formik";
import PlanContent from "../../../components/OrderItems/PlanContent";
import PlanDetail from "./planDetail";
import DeviceWithVasContent from "../../../components/OrderItems/DeviceWithVasContent";
import SpecialOrder from "../../profile/orderInfo/SpecialOrder";
import PreviewPlanDetail from "./planDetail/PreviewPlanDetail";
import CheckoutPlanContent from "../previewOrder/CheckoutPlanContent";

const OrderItem = ({ tncData, viewOnly }) => {
  const { values } = useFormikContext();

  return (
    <FieldArray name="order_list">
      {({ remove }) => (
        <div>
          {values?.order_list?.map((item, idx) => {
            if (item.type === "plan") {
              if (viewOnly) {
                return (
                  <CheckoutPlanContent
                    idx={idx}
                    data={item}
                  />
                );
              }
              return (
                <PlanContent
                  idx={idx}
                  data={item}
                  viewOnly={viewOnly}
                  renderDetail={
                    viewOnly ? (
                      <PreviewPlanDetail
                        name={`order_list[${idx}]`}
                      />
                    ) : (
                      <PlanDetail
                        name={`order_list[${idx}]`}
                        idx={idx}
                        tncData={tncData}
                      />
                    )
                  }
                />
              );
            }

            if (item.type === "product") {
              return <DeviceWithVasContent idx={idx} data={item} />;
            }

            if (item.type === "delivery") {
              return (
                <SpecialOrder
                  type={"delivery"}
                  data={{
                    itemName: item.deliveryInfo._displayName,
                    subtotal: item.discountedSubtotal,
                    quantity: item.quantity,
                  }}
                />
              );
            }
          })}
        </div>
      )}
    </FieldArray>
  );
};

export default OrderItem;

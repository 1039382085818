import styled from "styled-components";
import Typography from "../../../components/Typography";
import UploadIcon from "./UploadIcon";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/common";

const BodyContainer = styled.div`
    display: flex;
    gap: 10px;
    // align-items: center;
    font-size: 16px;
`

// FIXME: to be removed
const DeadlineHint = (props) => {
    const {
        prepaidInfo=false,
        date=null,
    } = props;

    const { t } = useTranslation('profile', { keyPrefix: 'pendingDocument' });

    return (
        <BodyContainer>
            <UploadIcon />
            <Typography style={{ fontSize: '16px', color: '#000' }}>
                {`${prepaidInfo ? t('bringNumberHintPrefix') : t('documentHintPrefix')}`}
                <span style={{ fontWeight: 'bold' }}>{prepaidInfo ? formatDate(date) : t('24hours')}</span>
                {`${prepaidInfo ? t('bringNumberHintPostfix') : t('documentHintPostfix')} `}
            </Typography>  
        </BodyContainer>
    )
}

export default DeadlineHint;
import styled from 'styled-components';
import CustomCardContainer from '../CustomCardContainer';
import Tag from '../TopCardTag';
import { Grid } from '@mui/material';
import RedditIcon from '@mui/icons-material/Reddit';
import AdbIcon from '@mui/icons-material/Adb';
import { Divider } from '@mui/material';
import BasicPlanInfo from './BasicPlanInfo';
import Space from '../Space';
import { ResponsiveUI } from '../../utils/responsive';
import Button from '../Button';
import { GetWord } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const CardContainer = styled(CustomCardContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const RootContainer = styled.div`
  width: 300px;
`;

const ColorBox = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
  width: 16px;
  height: 16px;
  border-radius: 6px;
  box-shadow: rgba(185, 185, 185, 0.8) 0px 2px 2px 0px inset,
    rgba(185, 185, 185, 1) 0px 0px 0px 0px, rgb(255, 255, 255) 0px 0px 0px 5px,
    rgb(229, 229, 229) 0px 0px 0px 6px;
  border: 1px solid rgb(220, 220, 220);
`;

const DeviceCard = ({ tag, data, handleClick, enlargedIcon, ...props }) => {
  const { t } = useTranslation(['common', 'home']);

  return (
    <div>
      {!_.isEmpty(_.trim(data?.promotionTitle?.['en'])) &&
      !_.isEmpty(_.trim(data?.promotionTitle?.['zh'])) ? (
        <Tag>{GetWord(data?.promotionTitle)}</Tag>
      ) : (
        <ResponsiveUI mobile={<div />} desktop={<Space size="32px" />} />
      )}
      <RootContainer {...props}>
        <CardContainer borderRadius={50}>
          <BasicPlanInfo
            noDesc
            yellowTag={data?.flipCardLabel}
            title={data?.brandName}
            subTitle={data?.productName}
            originalPrice={data?.markedPrice}
            discountedPrice={data?.sellingPrice}
            quantityDesc={t('common:up')}
            icon={data?.flipCardIconImageUrl}
            enlargedIcon={enlargedIcon}
            enableZoomInIcon={true}
          />
          <Divider />
          {/* <Space size="20px" /> */}
          {/* FIXME: price section will not align the same if more than 10 colors */}
          <Grid
            container
            style={{ padding: '28px 14px 0px 28px', minHeight: '80px' }}
            alignItems={'flex-start'}
          >
            <Grid item xs container spacing={2} justifyContent="center">
              {data?.flipCardColors?.map((color) => {
                return (
                  <Grid item key={`device-${data?.productName}-${color}`}>
                    <ColorBox backgroundColor={color} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <div style={{ flex: 1 }} />
          <Button
            color="yellow"
            style={{ margin: `0px 30px 24px 30px` }}
            onClick={handleClick}
            size="medium"
            fontSize={16}
          >
            {t('home:selectDevice')}
          </Button>
        </CardContainer>
      </RootContainer>
    </div>
  );
};

export default DeviceCard;

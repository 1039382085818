import styled from "styled-components";
import MyTypography from "../Typography";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import EditIcon from "../../assets/ic-edit.svg";
import ItemSKUField from "./ItemSKUField";
import OrderNoDetail from "./OrderNoDetail";
import { useTranslation } from "react-i18next";
import { ResponsiveUI, WindowSize } from "../../utils/responsive";
import CardWrapper from "../Card/CardWrapper";

const ItemHeader = (props) => {
  const {
    colWidth,
    header,
    onEdit,
    itemId,
    orderRecord = [],
    type,
    editId,
    extraWidthForButton = false,
    children
  } = props;
  const { t } = useTranslation(["orderContentItem", "common"]);
  const [showOrderNoDetail, setShowOrderNoDetail] = useState(false);

  const isMobile = WindowSize() === "mobile";

  useEffect(() => {
    if (!isMobile && showOrderNoDetail) setShowOrderNoDetail(false);
  }, [isMobile])

  const handleOnClickEdit = () => {
    onEdit && onEdit({
      type: type === "plan" ? "mobile" : "device",
      itemId: itemId,
      editId: editId,
    })
  }

  const _renderDesktop = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: '100%',
          padding: "26px 50px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            // padding: "26px 50px",
            alignItems: "center",
          }}
        >
          {colWidth.map((item, index) => {
            const isMainTitle = index === 0;
            return (
              <div
                style={{
                  width: item,
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <MyTypography
                  fontSize={isMainTitle ? 24 : undefined}
                  bold={isMainTitle}
                  style={{
                    whiteSpace: isMainTitle ? 'nowrap' : 'normal',
                  }}
                >
                  {header[index]}
                </MyTypography>
                {(isMainTitle && orderRecord.length > 0) && (
                  <ItemSKUField
                    type={type}
                    orderRecord={orderRecord}
                    showDetail={showOrderNoDetail}
                    setShowDetail={setShowOrderNoDetail}
                    label={t(type === "plan" ? "mobileNumber" : "productSku")}
                    isHeader={type === "product" ? false : true}
                  />
                )}
              </div>
            )
          })}
          {(onEdit || extraWidthForButton) && (
            <div
              style={{
                width: "7%",
                minWidth: 80
              }}
            >
              {onEdit && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    cursor: "pointer",
                  }}
                  onClick={handleOnClickEdit}
                >
                  <img src={EditIcon} style={{ marginRight: 5 }} />
                  <MyTypography fontSize="18" bold>
                    {t("edit", { ns: "common" })}
                  </MyTypography>
                </div>
              )}
            </div>
          )}
        </div>
        {(showOrderNoDetail && orderRecord.length > 0) && (
          <ExpandOrderInfoContainer>
            <OrderNoDetail orderRecord={orderRecord} type={type} />
          </ExpandOrderInfoContainer>
        )}
        {children}
      </div>
    );
  };

  const _renderMobile = () => {
    return (
      <CardWrapper style={{ width: '100%', padding: '30px 0' }}>
        <MobileContainer>
          <MyTypography fontSize="24" bold >
            {header[0]}
          </MyTypography>
          {orderRecord.length > 0 && (
            <ItemSKUField
              type={type}
              orderRecord={orderRecord}
              showDetail={showOrderNoDetail}
              setShowDetail={setShowOrderNoDetail}
              label={t(type === "plan" ? "mobileNumber" : "productSku")}
              isHeader={type === "product" ? false : true}
            />
          )}
          {onEdit && (
            <EditButton onClick={handleOnClickEdit}>
              <img src={EditIcon} style={{ marginRight: 5 }} />
              <MyTypography fontSize="18" bold>
                {t("edit", { ns: "common" })}
              </MyTypography>
            </EditButton>
          )}
        </MobileContainer>
        {showOrderNoDetail && orderRecord.length > 0 && (
          <OrderNoDetail orderRecord={orderRecord} type={type} />
        )}

        {children}
      </CardWrapper>
    );
  };

  return <ResponsiveUI mobile={_renderMobile()} desktop={_renderDesktop()} />;
};

const ItemImage = styled.div`
  box-shadow: 0 3px 10px 0 #c9c9c9;
  background-color: #fff;
  height: 90px;
  width: 90px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExpandOrderInfoContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 195px;
`;

const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export default ItemHeader;

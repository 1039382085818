import styled from "styled-components";
import { Grid } from "@mui/material";
import Typography from "../Typography";
import Divider from "../Divider";
import AdbIcon from "@mui/icons-material/Adb";
import { useTranslation } from "react-i18next";
import Space from "../Space";
import { ResponsiveUI, breakpoint, WindowSize } from "../../utils/responsive";
import FacebookIcon from "../../assets/ic-facebook.svg";
import LinkedInIcon from "../../assets/ic-linkedin.svg";
import YoutubeIcon from "../../assets/ic-youtube.svg";
import ImageButton from "../Buttons/ImageButton";
// import { ReactComponent as ArrowRightIcon } from "../../assets/ic-arrowRight.svg";
import ArrowRightIcon from "../../assets/ic-arrowRight.svg";
import Button from "../Button";
import { gaSocialClick } from "../../utils/ga";
import { gtmSocialClick } from "../../utils/gtm";

const Footer = () => {
  const { t, i18n } = useTranslation("navBar");
  const isMobile = WindowSize() === "mobile";

  // TODO - to be confirm tnc + customerService
  const MENUS = [
    {
      label: t("footer.websiteTermsOfUse"),
      url: {
        en: "https://www.1010corporate.com/1010Corp/media/Doc/Terms-of-Use.pdf",
        zh: "https://www.1010corporate.com/1010Corp/media/Doc/TnC/terms_of_use_tc.pdf",
      },
    },
    {
      label: t("footer.privacyStatement"),
      url: {
        en: "http://www.hkt.com/privacy-statement?locale=en",
        zh: "http://www.hkt.com/privacy-statement?locale=zh",
      },
    },
    {
      label: t("footer.1010eshopTandC"),
      url: {
        en: "https://www.1010corporate.com/1010Corp/media/Doc/eshop-terms-and-conditions-en.pdf",
        zh: "https://www.1010corporate.com/1010Corp/media/Doc/eshop-terms-and-conditions-tc.pdf",
      },
    },
    {
      label: t("footer.deliveryPolicy"),
      url: {
        en: "https://www.1010corporate.com/1010Corp/media/Doc/eshop-delivery-policy-en.pdf",
        zh: "https://www.1010corporate.com/1010Corp/media/Doc/eshop-delivery-policy-tc.pdf",
      },
    },
    {
      label: t("footer.returnAndRefundPolicy"),
      url: {
        en: "https://www.1010corporate.com/1010Corp/media/Doc/eshop-return-and-refund-policy-en.pdf",
        zh: "https://www.1010corporate.com/1010Corp/media/Doc/eshop-return-and-refund-policy-tc.pdf",
      },
    },
    {
      label: t("footer.acceptableUsePolicies"),
      url: {
        en: "https://www.1010.com.hk/sites/default/files/uploads/1010_next_g/charges_and_subscription/acceptable_use_policies_eng.pdf",
        zh: "https://www.1010.com.hk/sites/default/files/uploads/1010_next_g/charges_and_subscription/acceptable_use_policies_tch.pdf",
      },
    },
    {
      label: t("footer.fairUsagePolicy"),
      url: {
        en: "https://www.1010.com.hk/sites/default/files/uploads/1010_next_g/charges_and_subscription/fair_usage_policy_eng.pdf",
        zh: "https://www.1010.com.hk/sites/default/files/uploads/1010_next_g/charges_and_subscription/fair_usage_policy_tch.pdf",
      },
    },
    {
      label: t("footer.nonDiscriminationAndNonHarassment"),
      url: {
        en: "https://www.1010.com.hk/sites/default/files/uploads/Non-discrimination_and_Non-harassment_Statement/non-discrimination-and-non-harassment-statement-v2.pdf",
        zh: "https://www.1010.com.hk/sites/default/files/uploads/Non-discrimination_and_Non-harassment_Statement/non-discrimination-and-non-harassment-statement-tc-v2.pdf",
      },
    },
    {
      label: t("footer.termsAndConditions"),
      url: {
        en: "https://www.1010.com.hk/en/terms_and_conditions",
        zh: "https://www.1010.com.hk/tc/terms_and_conditions",
      },
    },
    {
      label: t("footer.specialConditions"),
      url: {
        en: "https://www.1010.com.hk/specialconditions",
        zh: "https://www.1010.com.hk/tc/specialconditions",
      },
    },
    {
      label: t("footer.customerService"),
      url: {
        en: "https://www.1010.com.hk/service_commitment",
        zh: "https://www.1010.com.hk/tc/service_commitment",
      },
    },
    {
      label: t("footer.regulatoryNotices"),
      url: {
        en: "https://www.1010.com.hk/regulatory_notices",
        zh: "https://www.1010.com.hk/tc/regulatory_notices",
      },
    },
    {
      label: t("footer.industryCodeOfPractice"),
      url: {
        en: "https://www.1010.com.hk/code_of_practice",
        zh: "https://www.1010.com.hk/tc/code_of_practice",
      },
    },
    {
      label: t("footer.qoSMeasures"),
      url: {
        en: "https://www.1010.com.hk/QoS_Measures",
        zh: "https://www.1010.com.hk/tc/QoS_Measures",
      },
    },
  ];

  const onClickSocialMedia = (url, channelName) => {
    const current_page_url = window.location.href;

    const gPayload = {
      destination_page_url: url,
      channel_name: channelName,
      page_url: current_page_url,
    }
    gaSocialClick(gPayload)
    gtmSocialClick(gPayload)
    
    window.open(url, "_blank");
  }

  return (
    <Container>
      <UpperContentContainer>
        <ContentWrapper>
          <Typography fontSize="24" bold>
            {t("footer.followUs")}
          </Typography>
          <SocialMedia>
            <ImageButton
              src={FacebookIcon}
              width="46px"
              height="46px"
              onClick={() => 
                onClickSocialMedia(
                  "https://www.facebook.com/1O1OBusinessSolutions/", 
                  "facebook"
                )
              }
            />
            <Space width="20px" />
            <ImageButton
              src={LinkedInIcon}
              width="46px"
              height="46px"
              onClick={() => 
                onClickSocialMedia(
                  "https://www.linkedin.com/company/1o1o-corporate-solutions", 
                  "linkedin"
                )
              }
            />
            <Space width="20px" />
            <ImageButton
              src={YoutubeIcon}
              width="46px"
              height="46px"
              onClick={() => 
                onClickSocialMedia(
                  "https://www.youtube.com/channel/UCOSoJi0TzFcTGtQxksKRS9Q", 
                  "youtube"
                )
              }
            />
          </SocialMedia>
        </ContentWrapper>
        <ContentWrapper>
          <Typography fontSize="20" fontWeight={500}>{t("footer.visitOurServiceWebsite")}</Typography>
          <Button 
            bg_Color={"#fff"} 
            style={{ 
              marginTop: "25px",
              padding: "8px 20px",
              textShadow: "none",
              minWidth: "290px",
            }}
            onClick={() => 
              onClickSocialMedia(
                "https://1010corporate.com",
                "corporate-website"
              )
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ textWrap: "wrap", textAlign: "start", lineHeight: 1.2 }}>{t("footer.goto1010Website")}</Typography>
              <img src={ArrowRightIcon} width={30} height={30} style={{ marginTop: "3px" }} />
            </div>
          </Button>
        </ContentWrapper>
      </UpperContentContainer>
      <Divider />

      <Space size="18px" />
      <Grid container spacing={2} justifyContent="space-between">
        <Grid container item xs={12} md={5}>
          {MENUS.map((item, index) => {
            return (
              <div
                style={{ 
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
              <Typography
                key={`footer-item-${index}`}
                onClick={() =>
                  window.open(item.url[i18n.language || "zh"], "_blank")
                }
                style={{
                  display: "inline-block",
                  // marginRight: 5,
                  cursor: "pointer",
                  lineHeight: "200%",
                }}
              >
                {item.label}
              </Typography>
              <Typography style={{ margin: '0 10px', lineHeight: "200%", }}>{index !== MENUS.length - 1 && "|"}</Typography>
              </div>
            );
          })}
        </Grid>
        <Grid item xs={12} md>
          <ResponsiveUI mobile={<Space size="100px" />} desktop={<div />} />
          <Typography style={{ whiteSpace: isMobile ? "pre-line" : "break-spaces" }} textAlign={"right"}>
            {t("footer.copyright")}
          </Typography>
        </Grid>
      </Grid>

      <CompanyText>
        <Typography>an HKT Company</Typography>
      </CompanyText>
    </Container>
  );
};

const Container = styled.div`
  background-color: #efefef;
  /* margin-top: 50px; */

  @media ${breakpoint.desktop} {
    padding: 60px;
  }

  @media ${breakpoint.mobile} {
    padding: 40px 30px 100px 30px;
  }
`;

const UpperContentContainer = styled.div`
  display: flex;
  gap: 120px;
  margin-bottom: 95px;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    gap: 60px;
    margin-bottom: 60px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SocialMedia = styled.div`
  display: flex;
  margin-top: 25px;
`;

const CompanyText = styled.div`
  display: flex;
  justify-content: flex-end;
  font-style: italic;
  margin-top: 15px;
`;

export default Footer;

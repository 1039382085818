import { useState } from 'react';
import { Grid } from "@mui/material";
import Option from "../../components/DeviceOption";
import Typography from '../../components/Typography';
import theme from "../../theme";
import { useField } from "formik";
import SimpleDialog from '../../components/CommonDialog/SimpleDialog';
import { useTranslation } from 'react-i18next';

const ExtraServiceField = (props) => {
    const { 
        label="",
        isRecommend=false,
        tagLabel,
        price=0,
        priceUnit="",
        originalPrice=0,
        isCircleCheckbox=false,
        icon,
        onChange,
        checked=false,
        onOpenDialog,
    } = props;

    return (
        <Grid item>
            <Option
                label={label}
                isRecommend={isRecommend}
                tagLabel={tagLabel}
                price={price}
                priceUnit={priceUnit}
                originalPrice={originalPrice > 0 ? originalPrice : null}
                CheckboxProps={{
                    circle: isCircleCheckbox,
                    onChange: (e) => onChange && onChange(e),
                }}
                icon={icon}
                selected={checked}
                detailsOnClick={() => onOpenDialog && onOpenDialog()}
            />
        </Grid>
    )
}

const ExtraServiceContainer = (props) => {
    const {
        title="",
        data=[],
        id="",
        locale,
    } = props;

    const [field, meta, helpers] = useField(id);

    const isFreeVas = id === 'freeVas';

    const [checkedItem, setCheckedItem] = useState(field.value);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dialogPayload, setDialogPayload] = useState(null);

    const { t } = useTranslation(['common']);

    const handleOnChange = (item, checked) => {
        const selected = checked ? [...checkedItem, item] : checkedItem.filter((i) => i._id !== item._id);

        helpers.setValue(selected);
        setCheckedItem(selected);
    }

    return (
        <Grid container item flexDirection={'column'} gap={2}>
            <Grid item>
                <Typography style={{ fontSize: '20px', color: theme.lightBlack, fontWeight: 'bold' }}>{title}</Typography>
            </Grid>
            <Grid container item flexDirection={'column'} gap={2}>
                { data.map((item, index) => (
                    <ExtraServiceField
                        key={`${title}-${item.name[locale]}-${index}`}
                        label={item?.name?.[locale] || ""}
                        isRecommend={!!item?.promotionTitle?.en || !!item?.promotionTitle?.zh}
                        tagLabel={item.promotionTitle?.[locale] || ""}
                        price={isFreeVas ? 0 : item.discountedPrice}
                        priceUnit={item.priceUnit}
                        originalPrice={item.originalPrice}
                        isCircleCheckbox={item.isCircleCheckbox}
                        icon={item?.imageUrl ? <img src={item.imageUrl} /> : undefined}
                        onChange={(e) => handleOnChange(item, e.target.checked)}
                        checked={checkedItem.some((i) => i._id === item._id)}
                        onOpenDialog={() => {
                            setDialogPayload(item);
                            setDialogIsOpen(true);
                        }}
                    />
                ))}
            </Grid>
            { (dialogIsOpen && dialogPayload !== null) && (
                <SimpleDialog 
                    isOpen={dialogIsOpen}
                    setIsOpen={setDialogIsOpen}
                    titleProp={<Typography fontSize={24} fontWeight={500}>{t('details')}</Typography>}
                    children={<div dangerouslySetInnerHTML={{ __html: dialogPayload?.detail?.[locale] }}/> || 'error'} // TODO
                />
            )}
        </Grid>
    )
}

export default ExtraServiceContainer;
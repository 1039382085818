import { useState } from 'react';
import Typography from "../Typography";
import OrderStatusTag from "../../pageComponents/profile/orderInfo/OrderStatusTag";
import { Grid } from "@mui/material";
import theme from '../../theme';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExpandLessIcon } from '../../assets/ic-expandLess.svg';
import { ReactComponent as ExpandMoreIcon } from '../../assets/ic-expandMore.svg';
import { breakpoint } from '../../utils/responsive';

const DetailButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const FieldContainer = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;

    @media ${breakpoint.mobile} {
        gap: 6px;
    }
`

const ItemSKUField = (props) => {
    const { 
        showDetail, 
        setShowDetail, 
        orderRecord, 
        isHeader=false, 
        label="", 
        type="plan",
    } = props;
    
    const { t } = useTranslation("orderContentItem");

    if (isHeader) {
        return (
            <FieldContainer>
                <Typography fontSize="16">{label}</Typography>
                { setShowDetail && (
                    <DetailButton onClick={() => setShowDetail(!showDetail)}>
                        {showDetail ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        {/* <Typography fontSize="16" color={theme.dirtyYellow} fontWeight={500}>
                            {t('details')}
                        </Typography> */}
                    </DetailButton>
                )}
            </FieldContainer>
        )
    } else {
        return (
            <Grid item container columnGap={2} style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                <Grid item><Typography fontSize="16">{label}</Typography></Grid>
                <Grid item xs container gap={'5px'} style={{ alignItems: 'center' }}>
                    <Grid item><Typography fontSize="18" style={{ lineHeight: '24px' }}>{orderRecord[0].sku || "SKU"}</Typography></Grid>
                    {/* <Grid item><OrderStatusTag status={orderRecord[0].status} /></Grid> */}
                </Grid>
            </Grid>
        )
    }
}

  
export default ItemSKUField;
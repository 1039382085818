import { Grid } from "@mui/material";
import Typography from "../../../components/Typography";
import Container from "../../../components/Container";
import Collapse from "../../../components/Collapse";
import Divider from "../../../components/Divider";
import styled from "styled-components";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";
import UploadIcon from '../../../assets/ic-smallUpload.svg';
import DocumentUploadSection from "./DocUploadSection";
import FieldItem from "./FieldItem";
import { breakpoint } from "../../../utils/responsive";

const FieldItemContainer = styled(Grid)`
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: flex-start;

    @media ${breakpoint.mobile} {
        flex-direction: column;
    }
`

const CommonBlock = (props) => {
    const {
        remarkText=null,
        remarkIcon=null,
        title="",
        document=[],
        items=[],
        refetch,
    } = props;

    return (
        <Container 
            style={{ gap: 0 }} 
            remark={remarkText}
            remarkIcon={remarkIcon || UploadIcon}
            remarkStyle={{ color: "#333" }}
        >
            <div>
                <Typography style={{ fontSize: '24px', fontWeight: 'bold' }}>{title}</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: "30px" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
                        <FieldItemContainer container>
                            { items.map((item) => (
                                <FieldItem label={item.label} value={item.value} _renderField={item._renderField} /> 
                            ))}
                        </FieldItemContainer>
                    </div>
                    <Divider />
                    { document.length > 0 && document.map((item, index) => (
                        <DocumentUploadSection 
                            key={`${title}-${index}`} 
                            data={item}
                            refetch={refetch}
                        />
                    ))}
                </div>
            </div>
        </Container>
    )
}

export default CommonBlock;
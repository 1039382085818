import styled from "styled-components";
import { useTranslation } from "react-i18next"
import IconENLogo from "../assets/ic-1010logo-en.svg";
import IconZhLogo from "../assets/ic-1010logo-zh.svg";
import { breakpoint } from "../utils/responsive";

const StyledLogo = styled.img`
    height: auto;
    max-width: 100%;
    cursor: pointer;

    @media ${breakpoint.desktop} {
        height: 50px;
        object-fit: contain;
        align-self: flex-start;
    }

    @media ${breakpoint.mobile} {
        height: 41px;
    }
`

const Logo = ({...props}) => {
    const { i18n } = useTranslation();

    const lang = i18n?.language || "zh";

    return (
        <StyledLogo src={lang === "zh" ? IconZhLogo : IconENLogo} {...props} />
    )
}

export default Logo;
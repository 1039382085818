import { Grid } from "@mui/material";
import { formatCurrency } from "../../utils/common";
import Typography from "../../components/Typography";
import theme from "../../theme";

const DevicePrice = ({ device, }) => {
    return (
        <Grid item container xs={12} flexDirection={'column'}>
            <Typography fontSize={16} mfontSize={16} color={theme.grey} style={{ textDecorationLine: 'line-through' }} condensed>
                {
                    device.sellingPrice && (
                        device.markedPrice && (device.markedPrice?.from && device.markedPrice?.to) ?
                            `HK$${formatCurrency(device.markedPrice?.from)} - HK$${formatCurrency(device.markedPrice?.to)}`
                            : `HK$${formatCurrency(device.markedPrice)}`
                    )
                }
            </Typography>
            <Typography fontSize={30} mfontSize={30} color={theme.lightBlack} bold condensed>
                {
                    device.sellingPrice ? (
                        (device.sellingPrice?.from && device.sellingPrice?.to) ?
                            `HK$${formatCurrency(device.sellingPrice?.from)} - HK$${formatCurrency(device.sellingPrice?.to)}`
                            : `HK$${formatCurrency(device.sellingPrice)}`
                    ) : (
                        device.markedPrice && (device.markedPrice?.from && device.markedPrice?.to) ?
                            `HK$${formatCurrency(device.markedPrice?.from)} - HK$${formatCurrency(device.markedPrice?.to)}`
                            : `HK$${formatCurrency(device.markedPrice)}`
                    )
                }
            </Typography>
        </Grid>
    )
}

export default DevicePrice;
import { useRef, useState } from 'react';
import MyButton from './Button';
import Typography from './Typography';
import styled from 'styled-components';
import theme from '../theme';
import { useTranslation } from 'react-i18next';

import ClearIcon from '@mui/icons-material/Clear';
import { Grid } from '@mui/material';
import LoadingIndicator from './LoadingIndicator';
import { GetWord } from '../utils/common';
import RemarkIcon from "../assets/ic-remark.svg";
import { isEmpty } from 'lodash';

const UploadContainer = styled(Grid)`
    display: flex;
    border-radius: 8px !important;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    flex-wrap: nowrap;

    .selector {
        height: 100%;
        flex: 1;
    
        border-radius: 0px !important;
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
        box-shadow: 0 0 6px 0 #c9c9c9;
        display: flex !important;
        justify-content: flex-start !important;

        background: #fff !important;
        color: #a7a7a7 !important;
        text-shadow: none !important;
    }
`

const FileNameContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px;
    ${props => props.isUpload && 'cursor: pointer;'}
`

const StyledFilename = styled(Typography)`
    font-size: 16px;
    color: #a7a7a7;
    font-weight: 500;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

const Button = styled(MyButton)`
    border-radius: 0px !important;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
    box-shadow: 0 0 6px 0 #c9c9c9;
`

/** File Uploader with support multiple files upload. Will return an array with file in `OnNext`. */
const FileUpload = (props) => {
    const { 
        accept=[],
        multiple=false,
        onNext,
        data=null,
        disabled=false,
        isLoading=false,
    } = props;

    const { t } = useTranslation('common');

    const [file, setFile] = useState(data);
    const [error, setError] = useState(null);
    const uploadRef = useRef();

    const handleFileChange = (e) => {
        setError(null);

        if (e.target.files) {
            // convert e.target.files to array
            const files = Array.from(e.target.files);

            // check selected file match accept file type
            if (accept.length > 0) {
                const acceptedFiles = files.filter((file) => {
                    const fileType = file.type.split('/')[1];
                    return accept.split(',').some((type) => type.trim().includes(fileType));
                });

                if (acceptedFiles.length !== files.length) {
                    uploadRef.current.value = null;
                    return setError('fileTypeHint')
                }
            }

            setFile(files);
        }
    }

    const handleClearFile = () => {
        setFile(null);
        setError(null);
        uploadRef.current.value = null
    }

    const handleClickUpload = () => {
        if (!file) return;
        onNext && onNext(file);
        setFile([{ name: file[0].name }]);
    }

    const uploadButtonDisabled = disabled || file?.some((f) => !(f instanceof File));

    return (
        <Grid container item xs md={10} direction={"column"} gap={1}>
            <UploadContainer item container xs md justifyContent={"flex-start"}>
                <Grid item xs>
                    <FileNameContainer
                        className='selector'
                        onClick={() => !disabled && !file && uploadRef.current.click()}
                        isUpload={!file && !disabled}
                    >
                        <div style={{ display: "flex", overflowWrap: "anywhere" }}>
                            <StyledFilename>
                                { !file ? (t('selectFile')) : (
                                    multiple && file.length > 1 ? t('selectedMultiFiles', {fileNumber: file.length}) : file[0]?.name
                                )}
                            </StyledFilename>
                            <input ref={uploadRef} hidden accept={accept} multiple={multiple} type="file" onChange={handleFileChange} disabled={disabled} />
                        </div>
                        { !disabled && file && <ClearIcon onClick={handleClearFile} style={{ marginLeft: 'auto', cursor: 'pointer' }} /> }
                    </FileNameContainer>
                </Grid>
                <Grid item md={2}>
                    <Button 
                        style={{ maxWidth: 70, padding: 15, fontSize: 16 }} 
                        onClick={handleClickUpload} 
                        disabled={uploadButtonDisabled}
                    >
                        {isLoading ? <LoadingIndicator /> : t('uploadBtn')}
                    </Button>
                </Grid>
            </UploadContainer>
            { !isEmpty(error) && (
                <Grid item xs>
                    <Typography style={{ fontSize: '12px', color: theme.mandatory }}>{t(error)}</Typography>
                </Grid>
            )}
            { (!file && !disabled) && (
                <Grid item xs container alignItems={"center"} gap={1}>
                    <img src={RemarkIcon} style={{ maxWidth: 14, maxHeight: 14 }} alt={t("fileTypeHint")} />
                    <Typography style={{ fontSize: '14px', color: theme.dirtyYellow }}>{t("fileTypeHint")}</Typography>
                </Grid>
            )}
        </Grid>
    )
}

export default FileUpload;
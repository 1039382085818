import { createTheme, ThemeProvider } from '@mui/material/styles';
import _ from "lodash";
import { forwardRef, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { CheckoutFormContext } from "./context/CheckoutFormContext";
import CustomerInformation from "./form/CustomerInformation";
import DeliveryContactInformation from "./form/DeliveryContactInformation";
import PaymentMethod from "./form/PaymentMethod";
import PersonalInformationStatement from "./form/PersonalInformationStatement";
import SupportingDoc from "./form/SupportingDoc";
import UserInfoForm from "./form/UserInfoForm";

const TNC_KEYPATH = [
  "tnc.savePersonalInformation",
  "tnc.directSaleThisServices",
  "tnc.directSaleMyClubHKT",
];

// IDK should it be prefilled again to all false after starting the new section
const prefillTnc = (data, acceptedTNC, setAcceptedTNC) => {
  data?.map((item) => {
    if (TNC_KEYPATH.includes(item.keyPath)) {
      const key = item.keyPath.replace("tnc.", "");
      let temp = acceptedTNC[key];

      if (_.isEmpty(temp) && !_.isEmpty(item?.value)) {
        temp.push({ tncId: item.value._id, accepted: false });
        setAcceptedTNC((prev) => ({ ...prev, [key]: temp }));
      }

    }
  });
};

const UserInfo = forwardRef(({
  step,
  setStep,
  currentStep,
  viewOnly,
  data,
  tncData,
  configData,
  calendarData,
  refetch,
  acceptedTNC,
  setAcceptedTNC,
  autoSave,
  setAutoSave,
}, ref) => {
  const [skipValidate, setSkipValidate] = useState(false);
  const { isDeviceOnly, hasDevice } = useContext(CheckoutFormContext)

  // useEffect(() => {
  //   if (tncData) {
  //     prefillTnc(tncData, acceptedTNC, setAcceptedTNC);
  //   }
  // }, []);

  const theme = createTheme({
    components: {
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.MuiGrid-item': {
              paddingTop: '36px',
            },
          },
        },
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <UserInfoForm
        data={data}
        configData={configData}
        step={step}
        setStep={setStep}
        currentStep={currentStep}
        refetch={refetch}
        skipValidate={skipValidate}
        setSkipValidate={setSkipValidate}
        autoSave={autoSave}
        setAutoSave={setAutoSave}
        hasPlan={!isDeviceOnly}
        ref={ref}
      >
        <Container>
          <CustomerInformation viewOnly={viewOnly} hasPlan={!isDeviceOnly} />
          <PaymentMethod viewOnly={viewOnly} hasPlan={!isDeviceOnly} />
          <DeliveryContactInformation
            viewOnly={viewOnly}
            setSkipValidate={setSkipValidate}
            configData={configData}
            calendarData={calendarData}
            allowDelivery={data?.isAllowedDelivery}
            allowPickup={data?.isAllowedPickup}
            hasDevice={hasDevice}
          />
          {!isDeviceOnly && <SupportingDoc />}
          <PersonalInformationStatement
            data={tncData}
            acceptedTNC={acceptedTNC}
            setAcceptedTNC={setAcceptedTNC}
          />
        </Container>
      </UserInfoForm>
    </ThemeProvider>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default UserInfo;

import {
  APPEAR_GLOBAL_ERROR,
  DISAPPEAR_GLOBAL_ERROR,
  HIDE_COOKIE_CONSENT,
  HIDE_GREETING_DIALOG,
  SHOW_COOKIE_CONSENT,
  SHOW_GREETING_DIALOG,
  UPDATE_CART_COUNT,
  UPDATE_COOKIE_CONSENT_HEIGHT,
  UPDATE_INIT,
  UPDATE_USER_INFO
} from "../actions/globalAction";

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case APPEAR_GLOBAL_ERROR:
      return {
        ...state,
        globalError: {
          title: action.title,
          message: action.message,
          options: { ...action.options },
        },
      };
    case DISAPPEAR_GLOBAL_ERROR:
      return { ...state, globalError: null };
    case UPDATE_USER_INFO:
      return { ...state, userInfo: action.userInfo, isInitialized: action.isInitialized || true };
    case UPDATE_INIT:
      return { ...state, isInitialized: action.isInitialized };
    case SHOW_COOKIE_CONSENT:
      return { ...state, showCookieConsent: true };
    case HIDE_COOKIE_CONSENT:
      return { ...state, showCookieConsent: false };
    case UPDATE_COOKIE_CONSENT_HEIGHT:
      return { ...state, cookieConsentHeight: action.cookieConsentHeight };
    case UPDATE_CART_COUNT:
      return { ...state, cartCount: action.cartCount };
    case HIDE_GREETING_DIALOG:
      return { ...state, isGreetingDialogOpen: false, greetingDialogLastOpen: { ...state.greetingDialogLastOpen, [action.lang]: new Date() } };
    case SHOW_GREETING_DIALOG:
      return { ...state, isGreetingDialogOpen: true };
    default:
      return state;
  }
};
export default GlobalReducer;

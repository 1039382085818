import styled from "styled-components";
import Typography from "../../../components/Typography";
import { breakpoint } from "../../../utils/responsive";
import { Grid } from "@mui/material";

const FieldItemWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
    gap: 11.5px;
    justify-content: center;
    flex-grow: 1;
`;

const FieldItem = (props) => {
    const { label, value, _renderField } = props;

    return (
        <FieldItemWrapper item md={3}>
            <div><Typography fontSize={16} fontWeight={500}>{label || `fieldLabel`}</Typography></div>
            <div style={{ flexGrow: 1 }}>
                { 
                    _renderField ? _renderField()
                        : <Typography fontSize={18}>{value || `fieldValue`}</Typography>
                }
            </div>
        </FieldItemWrapper>
    )
}

export default FieldItem;
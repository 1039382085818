import { useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleDialog from "../CommonDialog/SimpleDialog";
import Typography from "../Typography";

const ItemFieldDetails = ({ item, style }) => {
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const { t } = useTranslation(["orderContentItem", "common"]);

    return (
        <>
            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end', ...style }}>
                <Typography fontSize={18} color="dirtyYellow" style={{ cursor: "pointer" }} onClick={() => setDialogIsOpen(true)}>
                    {t("details")}
                </Typography>
            </div>
            {(dialogIsOpen && item !== null) && (
                <SimpleDialog
                    isOpen={dialogIsOpen}
                    setIsOpen={setDialogIsOpen}
                    titleProp={<Typography fontSize={24} fontWeight={500}>{t('details')}</Typography>}
                    children={<div dangerouslySetInnerHTML={{ __html: item }} /> || 'error'} // TODO
                />
            )}
        </>
    );
}

export default ItemFieldDetails;
import styled from "styled-components";
import { useEffect, useState } from "react";
import DeviceContent from "./DeviceContent";
import DeviceDetail from "../../pageComponents/shoppingcart/DeviceDetail";
import CommonContent from "./CommonContent";
import { useTranslation } from "react-i18next";
import { GetWord } from "../../utils/common";
import _ from "lodash";
import { formatCurrency } from "../../utils/common";

const DeviceWithVasContent = ({ data, idx, onEdit, onDelete, orderRecord, cardBaseStyle=null }) => {
  const { t } = useTranslation("orderContentItem");
  const [productOption, setProductOption] = useState([]);

  //get select option and value
  // TODO: refactor
  useEffect(() => {
    const keys = data?.options?.variationProduct?.options;
    const productOption = data?.product?.productOptions;
    const mapped =
      productOption &&
      productOption.map((item) => {
        const foundOption = _.find(keys, [
          "optionKey",
          item?.option?.optionKey,
        ]);
        if (foundOption) {
          const foundValue = _.find(item?.values, [
            "value",
            foundOption.valueKey,
          ]);
          if (foundValue)
            return { ...foundValue, optionLabel: item.option.label };
        }
      });

    setProductOption(mapped);
  }, [data]);

  return (
    <div>
      <DeviceContent
        key={`device-${idx}`}
        data={data}
        productOption={productOption}
        idx={idx}
        renderDetail={
          <DeviceDetail data={productOption} extraWidthForButton={onDelete} />
        }
        onEdit={onEdit}
        onDelete={onDelete}
        orderRecord={orderRecord}
        cardBaseStyle={cardBaseStyle}
      />
      {data?.options?.freeVas?.map((item) => {
        return (
          <CommonContent
            title={t("freeGift")}
            icon={item?.imageUrl}
            iconNeedDotDecoration
            row={[
              GetWord(item?.name),
              GetWord(item?.detail),
              data?.quantity,
              `HK$ 0`,
            ]}
            extraWidthForButton={onDelete}
            cardBaseStyle={cardBaseStyle}
          />
        );
      })}
      {data?.options?.paidVas?.map((item) => {
        return (
          <CommonContent
            title={t("paidItem")}
            icon={item?.imageUrl}
            iconNeedDotDecoration
            row={[
              GetWord(item?.name),
              GetWord(item?.detail),
              data?.quantity,
              `HK$ ${formatCurrency(
                item?.discountedPrice * data?.quantity,
                1
              )}`,
            ]}
            extraWidthForButton={onDelete}
            cardBaseStyle={cardBaseStyle}
          />
        );
      })}
    </div>
  );
};

export default DeviceWithVasContent;

import { useEffect } from "react";
import styled from "styled-components";
import { Divider, Grid } from "@mui/material";
import NewNumber from "../newNumber";
import ExistingNumber from "../existingNumber";
import CommonCheckbox from "../../../../components/Common/CommonCheckbox";
import Space from "../../../../components/Space";
import { useFormikContext, useField } from "formik";
import { useTranslation } from "react-i18next";
import { WindowSize, breakpoint } from "../../../../utils/responsive";

const PlanDetail = ({ name, idx, tncData }) => {
  const { t } = useTranslation("checkout");
  const [field, meta, helpers] = useField(`${name}.order_isAutoRenewal`);
  const { values } = useFormikContext();
  const totalPlanNum = values.order_list[idx].quantity;

  const isMobile = WindowSize() === "mobile"

  useEffect(() => {
    const isAutoRenewal = values.order_list[idx]?.isAutoRenewal;
    if (typeof isAutoRenewal === "boolean") {
      helpers.setValue(isAutoRenewal);
    } else {
      helpers.setValue(false);
    }
  }, []);

  return (
    <Container>
      <Divider />
      <Content>
        <Grid container>
          <Grid item xs={12} md={6}>
            <NewNumber
              name={`${name}`}
              totalPlanNum={totalPlanNum}
              idx={idx}
              required />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <ExistingNumber
              required
              name={`${name}`}
              totalPlanNum={totalPlanNum}
              idx={idx}
              tncData={tncData}
            />
          </Grid>
        </Grid>
      </Content>
      <Divider />
      <Space size="20px" />

      <CommonCheckbox
        label={t("autoRenewal")}
        checked={field.value}
        onChange={(e) => {
          helpers.setValue(e.target.checked);
        }}
        style={{ paddingLeft: isMobile ? 10 : 40 }}
      />
      <Space size="20px" />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  hegiht: auto;
  margin-top: 40px;
`;

const Content = styled.div`
  display: flex;

  @media ${breakpoint.mobile} {
    flex-direction: column;
  }
`;

export default PlanDetail;

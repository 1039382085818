import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { isEmpty } from 'lodash';
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);




/**
 * please check the dayjs official documentation for definition of day and date 
 * 
 * @typedef {Object} Ignores
 * @property {number[]} [day] - The days of the week to ignore. 0 is Sunday, 1 is Monday, and so on.
 * @property {number[]} [date] - The dates to ignore.
 * @property {dayjs.Dayjs[]} [otherDates] - The other dates to ignore.
 * 
 */

/**
 * TODO: the constructor should not depends on format of date, this dependency should be handled by the caller
 * 
 */
/**
 * @class DateRangeHelper
 * @classdesc A helper class for calculating date ranges with optional date include/ exclude.
 * @param {dayjs.Dayjs} startDate - The start date.
 * @param {number} tStart - The number of days to add to the start date.
 * @param {number} tEnd - The number of days to accumulate.
 * @param {Ignores} ignores - The ignore options.
 */
class DateRangeHelper {
  #HASH_TABLE_DATE_FORMAT = 'DD/MM/YYYY';
  #enabledDateHashTable;
  //  start and end are inclusive
  #actualStartDate;
  #actualEndDate;

  // helper method for constructor
  #formatIgnoreDates(ignores) {
    // all days before actualStartDate are excluded
    // startDate + tStart + ignores

    // todo: handle the case all days are ignored that will cause infinite loop
    return {
      ...ignores,
      otherDates: ignores.otherDates?.map((date) => {
        const dayjsObj = dayjs(date, ['DD/MM/YYYY', 'YYYY-MM-DD']);
        if (!dayjsObj.isValid()) {
          throw new Error('invalid date format, use DD/MM/YYYY or YYYY-MM-DD');
        }
        return dayjsObj;
      }) || []

    };
  }


  /**expect: 29/7, T+3 is 5/8 */

  #addDayWithSkipDates(day, addedDays) {

    let newDay = day.clone();
    let accumulatedDates = -1;

    let lastDate = null;

    while (accumulatedDates < addedDays) {
      if (!this.#shouldIgnoreForTDayCalculation(newDay)) {
        accumulatedDates += 1;
        lastDate = newDay.clone();
      }
      newDay = newDay.add(1, 'day');

    }
    return lastDate;
  }

  /**
   * @class DateRangeHelper
   * @classdesc A helper class for calculating date ranges with optional start date or end date include/ exclude.
   * @param {dayjs.Dayjs} startDate - The start date.
   * @param {number} tStart - The number of days to add to the start date.
   * @param {number} tEnd - The number of days to accumulate.
   * @param {Ignores} ignores - The ignore options.
   * @param {Boolean} startEndInclusive - whether the start and end date are inclusive. Default is `true`.
   */
  constructor(startDate, tStart, tEnd, ignores, startEndInclusive=true) {
    if (tStart > tEnd) {
      throw new Error('tStart must be less than tEnd');
    }
    this.tStart = tStart;
    this.tEnd = tEnd;
    this.ignores = this.#formatIgnoreDates(ignores);
    this.originalStartDate = startDate;
    /**
     * by definition, selectable range from [tStart, tEnd]
     */
    this.#actualStartDate = this.#addDayWithSkipDates(
      this.originalStartDate,
      this.tStart + (startEndInclusive ? 0 : 1)
    );
    this.#actualEndDate = this.#addDayWithSkipDates(
      this.originalStartDate,
      this.tEnd - (startEndInclusive ? 0 : 1)
    );
    this.#enabledDateHashTable = {};
  }




  #shouldIgnoreForTDayCalculation(date) {
    const ignoreBySelectedDate = this.ignores.otherDates?.some((ignoredDay) =>
      ignoredDay.isSame(date)
    );
    const ignoreByDay = this.ignores.day?.some((d) => {
      return d === date.day();
    });
    const ignoreByDate = this.ignores.date?.some((d) => d === date.date());

    return ignoreBySelectedDate || ignoreByDay || ignoreByDate;
  }



  /**this method is invoked as lazy initialization */
  #initDateHashTable(startDay, endDay) {
    const hashTable = {};
    while (startDay.isBefore(endDay)) {
      hashTable[startDay.format(this.#HASH_TABLE_DATE_FORMAT)] = false;
      startDay = startDay.add(1, 'day');
    }

    let current = this.#actualStartDate.clone();
    while (current.isSameOrBefore(this.endDate)) {
      if (!this.#shouldIgnoreForTDayCalculation(current)) {
        hashTable[current.format(this.#HASH_TABLE_DATE_FORMAT)] = true;
      }
      current = current.add(1, 'day');

    }

    this.#enabledDateHashTable = hashTable;
    return hashTable;
  }

  /**
   * First day of the interval.
   * @type {dayjs.Dayjs}
   */
  get startDate() {
    return this.#actualStartDate;
  }

  /**
   * Last day of the interval
   * @type {dayjs.Dayjs}
   */
  get endDate() {
    return this.#actualEndDate;
  }

  /**
   * The dates included in the interval.
   * @type {Object.<string, boolean>} - The key is the date in format 'DD/MM/YYYY' and the value is a boolean indicating if the date is included in the interval.
   */
  get dates() {
    if (isEmpty(this.#enabledDateHashTable)) {
      this.#initDateHashTable(this.originalStartDate, this.endDate);
    }

    return this.#enabledDateHashTable



  }

  /**
   * @param {dayjs.Dayjs} date
   * @returns {boolean}
   */
  shouldDisableDate(date) {
    if (isEmpty(this.#enabledDateHashTable)) {
      this.#initDateHashTable(this.originalStartDate, this.endDate);
    }

    const flag = !this.#enabledDateHashTable[date.format(this.#HASH_TABLE_DATE_FORMAT)];
    return flag
  }
}

export { DateRangeHelper };

import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _, { find } from 'lodash';
import TNCItem from './TncItem';
import { useEffect, useMemo } from 'react';
import useCheckoutGlobalTnc from '../hooks/useCheckoutGlobalTnc';
import { useField } from 'formik';

const DirectSaleTncSection = ({
    acceptedTNC,
    setAcceptedTNC,
    disableDivider,
}) => {
    const { t } = useTranslation(['checkout', 'common']);

    const { directedSalesTncs } = useCheckoutGlobalTnc()

    const [field, meta, helper] = useField('is_direct_sale_tnc_accepted');

    const directSalesTncNotAcceptedString = t(meta.error)

    const directSaleMyClubHktTncValue = useMemo(() => {
        return find(directedSalesTncs, ['keyPath', 'tnc.directSaleMyClubHKT'])?.value;
    }, [directedSalesTncs]);



    const directSaleTncValue = useMemo(() => {
        return find(directedSalesTncs, ['keyPath', 'tnc.directSaleThisServices'])?.value;
    }, [directedSalesTncs]);

    useEffect(() => {
        if (acceptedTNC?.directSaleThisServices?.length > 0) {
            const isAccepted = acceptedTNC?.directSaleThisServices?.[0]?.accepted || false;
            helper.setValue(isAccepted);
        }
    }, [acceptedTNC])

    return (
        <div>
            {
                directedSalesTncs.length > 0 && directedSalesTncs.map((item, index) => {
                    const isLast = index === directedSalesTncs.length - 1;
                    const keyPath = item?.keyPath?.replace('tnc.', '');
                    const title = {
                        en: t(`directSaleTnc.${keyPath}`, { lng: 'en' }),
                        zh: t(`directSaleTnc.${keyPath}`, { lng: 'zh' }),
                    };

                    // this solution is not robust, it will break if there's more than 1 directSaleMyClubHKT OR directSaleThisServices
                    return (
                        <div key={`direct-sale-tnc-wrapper-${keyPath}`}>
                            <TNCItem
                                key={`direct-sale-tnc-${keyPath}`}
                                title={title}
                                detail={isLast && directSaleTncValue?.description ? directSaleTncValue?.description : null}
                                detailsTitle={directSaleTncValue?.title}
                                id={directSaleTncValue?._id}
                                keyPath={keyPath}
                                acceptedTNC={acceptedTNC}
                                setAcceptedTNC={setAcceptedTNC}
                                disableDivider={true}
                                error={keyPath === 'directSaleThisServices' && directSalesTncNotAcceptedString}
                            />
                        </div>
                    )
                })
            }
            {!disableDivider && <Divider style={{ marginTop: 32 }} />}
        </div>
    )
}

export default DirectSaleTncSection;
import { useTranslation } from "react-i18next"
import { GetWord, formatCurrency } from "../utils/common"
import { find, pick } from "lodash"
import { useMemo } from "react"

const usePlanViewModel = (planItem) => {
  const { t, i18n } = useTranslation('common')
  const plan = planItem?.plan || {}
  const freeVas = planItem?.options?.freeVas || []
  const paidVas = planItem?.options?.paidVas || []

  /**
   * @type {Array<object>}
   */
  const planNumberRows = useMemo(() => {
    if (planItem.mnpNumbers && planItem.newNumbers) {
      return planItem.newNumbers
        .concat(planItem.mnpNumbers)
        //.map((n, index) => `${t('orderContentItem:numberNo', { index: index + 1 })} ${n.number}`);
        .map((n, index) => ({
          title: t('orderContentItem:numberNo', { index: index + 1 }),
          number: n.number,
        }));
    }
    return [];
  }, [planItem, t])


  const getContract = () => {
    const contractPeriodId = planItem?.options?.contractPeriodId;
    const contractInfo = planItem?.plan?.contractPeriodDetails;

    return find(contractInfo, ["_id", contractPeriodId]);
  };


  const summationRow = {
    planName: GetWord(planItem?.plan?.planName),
    localData: GetWord(planItem?.plan?.localData),
    period: `${getContract()?.period} ${t('common:months')}`,
    quantity: planItem.quantity,
    unitPrice: `HK$ ${formatCurrency(planItem?.discountedSubtotal / planItem?.quantity, 1)}`,
    totalPrice: `HK$ ${formatCurrency(planItem?.discountedSubtotal, 1)}`,
  }


  const planRow = {
    planName: GetWord(plan.planName),
    localData: GetWord(plan.localData),
    period: `${getContract()?.period} ${t("months")}`,
    quantity: planItem.quantity,
    unitPrice: `HK$ ${formatCurrency(getContract()?.discountedPrice)}`,
    totalPrice: `HK$ ${formatCurrency(getContract()?.discountedPrice * planItem.quantity)}`,
  }


  /**
    * @type {Array<object>}
    */
  const freeVasRows = freeVas.map((vas) => {
    return {
      name: GetWord(vas.name),
      detail: GetWord(vas.detail),
      period: `${getContract()?.period} ${t("months")}`,
      quantity: planItem.quantity,
      unitPrice: `HK$ ${formatCurrency(0)}`,
      totalPrice: `HK$ ${formatCurrency(0)}`,
    }
  }
  )


  /**
    * @type {Array<object>}
    */
  const paidVasRows = paidVas.map((vas) => {
    return {
      name: GetWord(vas.name),
      detail: GetWord(vas.detail),
      period: `${getContract()?.period} ${t("months")}`,
      quantity: planItem.quantity,
      unitPrice: `HK$ ${formatCurrency(vas?.discountedPrice)}`,
      totalPrice: `HK$ ${formatCurrency((vas?.discountedPrice || 0) * planItem.quantity)}`,
    }
  })
  const planMobileRow = pick(planRow, ['planName', 'localData', 'totalPrice'])
  const freeVasMobileRows = freeVasRows.map(vas => pick(vas, ['name', 'detail', 'totalPrice']))
  const paidVasMobileRows = paidVasRows.map(vas => pick(vas, ['name', 'detail', 'totalPrice']))



  return {
    planNumberRows,
    summationRow,
    planRow,
    freeVasRows,
    paidVasRows,
    planMobileRow,
    freeVasMobileRows,
    paidVasMobileRows,
  }
}


export default usePlanViewModel
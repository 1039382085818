import { useState } from "react";
import styled from "styled-components";
import BasicPlanInfo from "./BasicPlanInfo";
import CustomCardContainer from "../CustomCardContainer";
import { Divider } from "@mui/material";
import { FeatureListItem } from "./PlanCard";
import { useTranslation } from "react-i18next";
import { List } from "@mui/material";
import AdbIcon from "@mui/icons-material/Adb";
import MyTypography from "../Typography";
import IconArrowDown from "../../assets/ic-expandLess.svg";
import IconArrowUp from "../../assets/ic-expandMore.svg";
import theme from "../../theme";

const StyledList = styled(List)`
  padding: 20px 20px 20px 28px !important;
  height: 100%;
  min-height: 85px;

  max-height: 280px;
  overflow-y: auto;
`;

const PlanDetailCard = ({ data }) => {
  const { t } = useTranslation("mobile");
  const [isExpand, setIsExpand] = useState(true);
  return (
    <Container>
      <Card>
        <Content borderRadius={50}>
          <BasicPlanInfo
            yellowTag={data?.flipCardLabel}
            icon={data?.flipCardIconImageUrl}
            title={data?.planName}
            subTitle={data?.localData}
            originalPrice={data?.originalPrice}
            discountedPrice={data?.discountedPrice}
            quantityDesc={t("common:month")}
          />
          <Divider />
          <StyledList>
            {data &&
              data?.cardFront?.map((item) => {
                return (
                  <FeatureListItem
                    smallSize
                    icon={item.imageUrl}
                    description={item.description}
                  />
                );
              })}
          </StyledList>
          <Divider />
          <PlanInclude onClick={() => setIsExpand(!isExpand)}>
            <MyTypography color={theme.dirtyYellow} bold fontSize="16">
              {t("planInclude")}
            </MyTypography>
            <img
              src={isExpand ? IconArrowUp : IconArrowDown}
              style={{ width: 22, height: 22 }}
            />
          </PlanInclude>
          {isExpand && (
            <StyledList>
              {data &&
                data?.planDetailsInclude?.map((item) => {
                  return (
                    <FeatureListItem
                      icon={item.imageUrl}
                      title={item.title}
                      description={item.description}
                      remark={item.remark}
                      remarkIconSize={20}
                    />
                  );
                })}
            </StyledList>
          )}
        </Content>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 300px;
  height: auto;
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  // height: 100%;

  display: flex;
  flex-direction: column;
`;

const Content = styled(CustomCardContainer)`
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

const PlanInclude = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 10px;
  padding-left: 28px;
  cursor: pointer;
`;

export default PlanDetailCard;

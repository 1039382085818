import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CommonBlock from "./CommonBlock";
import { formatDate } from "../../../utils/common";
import { NEW_NUMBER_EFFECTIVE_TIMESLOT } from "../../checkout/orderList/newNumber";

const PrepaidNumber = (props) => {
    const { data, refetch } = props;

    const { t } = useTranslation('profile', { keyPrefix: 'pendingDocument' });

    const documentReducedList = useMemo(() => {
        const list = [];
        data?.forEach((item, index) => {
            item?.document?.forEach((doc) => {
                list.push({ ...doc, sort: index + 1 });
            })
        })
        return list;
    }, [data])

    const getNumberListItems = (data) => {
        const items = [];

        data?.forEach((item, index) => {
            items.push({
                label: t('mobileNumberWillBring', { index: index + 1 }),
                value: item?.mobileNumber,
            });

            items.push({
                label: t('effectiveDate'),
                value: item?.effectiveDate,
            });

            items.push({
                label: t('effectiveTime'),
                value: NEW_NUMBER_EFFECTIVE_TIMESLOT.find((slot) => slot.value === item?.effectiveTime)?.title,
            });
        })

        return items;
    }

    return (
        <CommonBlock
            remarkText={`${t('documentHintPrefix')}${t("24hours")}${t('documentHintPostfix')}`}
            title={t('bringNumber')}
            document={documentReducedList}
            items={getNumberListItems(data)}
            refetch={refetch}
        />
    )
}

export default PrepaidNumber;
import { useState } from "react";
import { Divider, Typography, Grid } from "@mui/material";
import styled from "styled-components";
import theme from "../../../../theme";
import NumberBlock from "./NumberBlock";
import { breakpoint, WindowSize } from "../../../../utils/responsive";
import { TIME_SLOT } from "../../../checkout/form/DeliveryInformation";
import { MNP_EFFECTIVE_TIME_SLOT } from "../../../checkout/orderList/existingNumber/constant";
import { NEW_NUMBER_EFFECTIVE_TIMESLOT } from "../../../checkout/orderList/newNumber";

const Container = styled.div`
    display: flex;
    justify-content: space-between;

    @media ${breakpoint.mobile} {
        flex-direction: column;
    }
`;

const PlanNumberComponent = (props) => {
    const { data, t } = props;

    const isMobile = WindowSize() === 'mobile';

    const getEffectiveDateTimeString = () => {
        const isMnp = data?.plan?.mnpNumbers?.length > 0;


        const timeSlotLabel = isMnp ? MNP_EFFECTIVE_TIME_SLOT.find((item) => item.value === data?.plan.mnpNumber.effectiveTime).title :
            NEW_NUMBER_EFFECTIVE_TIMESLOT.find((item) => item.value === data?.plan.newNumberEffectiveTime)?.title;

        return `${isMnp ? data?.plan.mnpNumber.effectiveDate : data?.plan.newNumberEffectiveDate} ${timeSlotLabel || ''}`;
    }

    return (
        <>
            <Container>
                <NumberBlock
                    type="new"
                    title={t('orderDetails.newMobileNumber')}
                    description={t('orderDetails.chooseNewNumber')}
                    data={{
                        numberList: data?.plan?.newNumbers.map((item) => item?.number || ""),
                        expectedDate: data?.plan?.newNumbers?.length > 0 && getEffectiveDateTimeString(),
                    }}
                    viewOnly
                    t={t}
                />
                <Divider orientation={isMobile ? 'horizontal' : "vertical"} flexItem />
                <NumberBlock
                    type="transfer"
                    title={t('orderDetails.mobilePortIn')}
                    description={t('orderDetails.portedNumber')}
                    data={{
                        numberList: data?.plan?.mnpNumbers.map((item) => item?.number || ""),
                        expectedDate: data?.plan?.mnpNumbers?.length > 0 && getEffectiveDateTimeString(),
                    }}
                    viewOnly
                    t={t}
                />

            </Container>
            <Divider style={{ width: "100%" }} />
        </>
    )
}

export default PlanNumberComponent;
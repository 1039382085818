import Typography from "../../components/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { breakpoint, pageMargin } from "../../utils/responsive";
import EmptyCartIcon from '../../assets/ic-emptyCart.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 100px 0;
    width: 100%;

    @media ${breakpoint.mobile} {
        padding: 70px 0;
    }
`;

const EmptyCart = () => {
    const { t } = useTranslation("shoppingCart");

    return (
        <Container justifyContent={"center"}>
            <Typography fontSize={24} fontWeight={500} style={{ marginBottom: '24px' }}>{t('emptyCartHint')}</Typography>
            <img src={EmptyCartIcon} alt="Empty Cart" />
        </Container>
    )
}

export default EmptyCart;
import SimpleDialog from "./SimpleDialog";
import MyButton from "../Button";
import Space from "../Space";
import { useTranslation } from "react-i18next";

const ConfirmDialog = ({
  children,
  isOpen,
  setIsOpen,
  handleClose,
  actionBtnLabel,
  onConfirm,
}) => {
  const { t } = useTranslation("common");
  return (
    <SimpleDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleClose={handleClose}
    >
      {children}

      <div style={{ display: "flex", marginTop: 30 }}>
        <MyButton
          style={{ width: "50%" }}
          type="secondary"
          onClick={() => setIsOpen(false)}
        >
          {t("cancel")}
        </MyButton>
        <Space width="20px" />
        <MyButton style={{ width: "50%" }} onClick={onConfirm}>
          {actionBtnLabel}
        </MyButton>
      </div>
    </SimpleDialog>
  );
};

export default ConfirmDialog;

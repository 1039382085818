import styled, { keyframes } from 'styled-components';
import theme from '../theme';
import Typography from './Typography'

const CardTag = styled(Typography)`
    border-bottom-right-radius: 15px;
    background: ${theme.tagGradientColor};
    width: max-content;
    // height: 28px;
    height: max-content;
    // padding: 3px 30px;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    && {
        text-shadow: 0 3px 6px #c7b645;
        color: #333;
        // font-size: ${theme.fontSize.tag};
        font-size: 14px;
        font-weight: bold;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
    }
`

export default CardTag
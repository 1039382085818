import { forwardRef } from "react";
import styled from "styled-components";
import StepTitle from "../../components/Plan/StepTitle";
import { Divider } from "@mui/material";
import Space from "../../components/Space";
import MyButton from "../../components/Button";
import { breakpoint, WindowSize } from "../../utils/responsive";
import { useTranslation } from "react-i18next";
import { DESKTOP_NAVBAR_HEIGHT, MOBILE_NAVBAR_HEIGHT } from "../../components/NavBar/constant";

const StepContainer = forwardRef(({
  title,
  step,
  currentStep,
  setStep,
  children,
  lastStep,
  onClick,
  onSave,
  onProceedNextStep,
  isLastStep = false,
}, ref) => {
  const { t } = useTranslation("common");
  const isMobile = WindowSize() === "mobile";

  const handleClickEdit = (id) => {
    const disableEdit = step.some((o) => o.id < id && o.isCompleted !== true);
    if (!disableEdit) {
      const newSteps = step.map((item) => {
        return { ...item, isActive: item.id === id };
      });
      setStep(newSteps);
    }

    onClick && onClick();
  };

  return (
    <Container isFirst={currentStep?.id === 1} ref={ref}>
      <StepTitle
        stepList={step}
        currentStep={currentStep}
        title={title}
        stepId={currentStep?.id}
        onClick={handleClickEdit}
        fullWidth={true}
        isLastStep={isLastStep}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // width: "100%",
        }}
      >
        {currentStep?.isActive && (
          <div
            style={{
              display: "flex",
              width: "100%",
              maxWidth: "1420px",
              marginLeft: isMobile ? 50 : 120,
              marginRight: isMobile ? 10 : 100,
            }}
          >
            {!lastStep && <Divider orientation="vertical" flexItem />}
            <Space width="40px" />

            <Content>
              {children}
              <Buttons isLastStep={isLastStep}>
                {<MyButton onClick={onSave}>{t("save")}</MyButton>}
                <Space width="20px" />
                <MyButton onClick={onProceedNextStep ? onProceedNextStep : onSave}>
                  {lastStep ? t("payment") : t("nextStep")}
                </MyButton>
              </Buttons>
              <Space size={35} />
            </Content>
          </div>
        )}
      </div>
    </Container>
  );
});

const Container = styled.div`
  @media ${breakpoint.desktop} {
    ${props => props.isFirst && 'padding-top: 20px'};
    scroll-margin-top: ${DESKTOP_NAVBAR_HEIGHT}px;
  }

  @media ${breakpoint.mobile} {
    ${props => props.isFirst && 'padding-top: 20px'};
    // scroll-margin-top: ${MOBILE_NAVBAR_HEIGHT}px;  // no navbar in mobile checkout
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoint.desktop} {
    width: 100%;
    margin-top: 15px;
  }

  @media ${breakpoint.mobile} {
    width: 100%;
    margin-left: -60px;
    margin-top: 25px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${breakpoint.desktop} {
    ${props => props.isLastStep ? 'padding-top: 20px' : 'padding: 20px 0 40px 0'};
  }

  @media ${breakpoint.mobile} {
    flex-direction: column;
    ${props => props.isLastStep ? 'padding-top: 20px' : 'padding: 20px 0'};
  }
`;

export default StepContainer;

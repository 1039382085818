import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import theme from "../theme";
import styled from "styled-components";

export default function LoadingIndicator() {
  return (
    <Container>
      <CircularProgress style={{ color: theme.yellow }} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { forwardRef, useEffect } from "react";
import styled from "styled-components";
import CardBase from "../../components/Card/CardBase";
import StepContainer from "./StepContainer";
import { Divider } from "@mui/material";
import MyTypography from "../../components/Typography";
import Checkbox from "../../components/Common/CommonCheckbox";
import { useTranslation } from "react-i18next";
import TNCItem from "./tnc/TncItem";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import { useState } from "react";
import { next } from "../../pages/Checkout";
import theme from "../../theme";
import _ from "lodash";
import { ACCEPTED_TNCS_KEYS } from "./hooks/useCheckoutInitialRequiredTncs";

const TNC = forwardRef(({
  step,
  setStep,
  currentStep,
  data,
  acceptedTNC,
  setAcceptedTNC,
}, ref) => {
  const { t } = useTranslation(["checkout", "error"]);
  const [acceptedAllTNC, setAcceptedAllTNC] = useState(false);
  const [error, setError] = useState(false);

  // auto check "I agree all TNC" if all TNC are accepted
  useEffect(() => {
    const allAccepted = acceptedTNC.planTncs.length > 0 && _.findIndex(acceptedTNC.planTncs, ["accepted", false]) === -1;
    setAcceptedAllTNC(allAccepted);
  }, [acceptedTNC])

  const handleAcceptAllTnc = () => {
    const temp =
      data &&
      data?.map((item) => {
        return { tncId: item._id, accepted: !acceptedAllTNC };
      });

    setAcceptedTNC((prev) => ({ ...prev, planTncs: temp }));
    setAcceptedAllTNC(!acceptedAllTNC);
  };

  const handleSave = () => {
    if (
      data?.length > 0 &&
      (_.isEmpty(acceptedTNC.planTncs) ||
        _.findIndex(acceptedTNC.planTncs, ["accepted", false]) > -1)
    ) {
      setError(true);
    } else {
      setError(false);
      next(currentStep?.id + 1, step, setStep);
    }
  };

  return (
    // onSave and onClick
    <StepContainer
      step={step}
      currentStep={currentStep}
      setStep={setStep}
      title={t("confirmTermsAndConditions")}
      onSave={handleSave}
      ref={ref}
    >
      <CardBase>
        <Container>
          <MyTypography fontSize="24" bold>
            {t("termsAndConditions")}
          </MyTypography>
        </Container>
        <Divider />
        <TncContainer>
          {data &&
            data.map((item) => {
              return (
                <>
                  <TNCItem
                    id={item?._id}
                    keyPath={ACCEPTED_TNCS_KEYS.planTncs}
                    title={item?.title}
                    shortDescription={item?.shortDescription}
                    detail={item?.description}
                    acceptedTNC={acceptedTNC}
                    setAcceptedTNC={setAcceptedTNC}
                    disabled={acceptedAllTNC}
                    disableDivider={true}
                  />
                  <Divider />
                </>

              );
            })}
        </TncContainer>
        <Container>
          <Checkbox
            checked={acceptedAllTNC}
            label={t("iAgreeAllTnc")}
            onClick={handleAcceptAllTnc}
            style={{  }}
            checkboxStyle={{ paddingLeft: 0 }}
          />
          {error && (
            <MyTypography color={theme.error2} style={{ marginTop: 15 }}>
              {t("error:requiredAcceptTnc")}
            </MyTypography>
          )}
        </Container>
      </CardBase>
    </StepContainer>
  );
});

const Container = styled.div`
  padding: 30px;

  @media ${breakpoint.mobile} {
    padding: 30px 25px;
  }
`;

const TncContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export default TNC;

import styled from "styled-components";
import MyTypography from "../Typography";
import Space from "../Space";
import RemarkIcon from "../../assets/ic-remark.svg";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SimpleDialog from "../CommonDialog/SimpleDialog";
import { GetWord } from "../../utils/common";

const DetailButton = ({ title, content }) => {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Container onClick={() => setIsOpen(true)}>
        <MyTypography color={theme.dirtyYellow} fontSize={18} mFontSize={16}>
          {t("details")}
        </MyTypography>
        <Space width="5px" />
        <img src={RemarkIcon} width={22} height={22} />
      </Container>

      {isOpen && (
        <SimpleDialog isOpen={isOpen} setIsOpen={setIsOpen} title={title && GetWord(title)}>
         { content && <MyTypography dangerouslySetInnerHTML={{ __html: content && GetWord(content) }} overflowWrap /> }
        </SimpleDialog>
      )}
    </div>
  );
};

export default DetailButton;

const Container = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;  
`;

import PageHeader from "../components/Header";
import Typography from "../components/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { breakpoint, pageMargin } from "../utils/responsive";
import NotFoundIcon from "../assets/ic-404.svg";
import Button from "../components/Button";
import Space from "../components/Space";
import { Link } from "react-router-dom";

const PageContainer = styled.div`
  margin: ${pageMargin.desktop};

  @media ${breakpoint.mobile} {
    margin: ${pageMargin.mobile};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 100px 0;

  @media ${breakpoint.mobile} {
    padding: 70px 0;
  }
`;

const NotFoundPage = () => {
  const { t } = useTranslation("error");

  return (
    <>
      <PageHeader>{t("notFoundPageTitle")}</PageHeader>
      <PageContainer>
        <Container justifyContent={"center"}>
          <Typography
            fontSize={24}
            fontWeight={500}
            textAlign="center"
            style={{ marginBottom: "24px" }}
          >
            {t("notFoundHintText")}
          </Typography>
          <img src={NotFoundIcon} alt="Not Found" />
          <Space size="40px" />

          <Link to={`/`} style={{ textDecoration: "none" }}>
            <Button>{t("backToHomePage")}</Button>
          </Link>
        </Container>
      </PageContainer>
    </>
  );
};

export default NotFoundPage;

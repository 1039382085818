const size = {
    mobile: 860,
    desktop: 861,
}

export default {
    primary: "#3E3E3E",
    secondary: "#9A9A9A",
    white: "#fff",
    black: "#000",
    lightBlack: "#333",
    lightYellow: "#FFF6AD",
    paleYellow: "#ffec74",
    yellow: "#FFDD00",
    dirtyYellow: "#746b2f",
    shadowYellow: "#dbbd00",
    orange: "#ffb100",
    disabled: "#b9b9b9",
    mandatory: "#db7600",
    error: "#fd7960",
    error2: "rgb(255, 83, 50)",
    error3: "rgb(255, 83, 50, 0.5)",
    red: "#d32f2f",
    grey: "#6a6a6a",
    disableGrey: "#9f9f9f",
    blackGradientColor: `linear-gradient(109deg, #3e3e3e, #9a9a9a 95%)`,
    tagGradientColor: `linear-gradient(to right, #fd0 0%, #ffec74 100%)`,
    outerContainerShadow: "0 3px 15px 0 rgb(201,201,201)",
    innerContainerShadow: "0 0px 8px 0 rgb(201,201,201)",
    greenTag: "#44B7B6",
    divider: "#e0e0e0",
    navSubItem: "#747678",
    fontSize: {
        smallTag: 13,
        tag: 20,
        price: 27,
        priceUnit: 15,
        originalPrice: 13,
        header: 42,
        title1: 38,
        title2: 24,
        title3: 22,
        title4: 18,
        title5: 32,
        subTitle: 16,
        subTitle2: 13,
        button: 18,
        textButton: 16,
        textButton2: 18,
        body1: 18,
        body2: 14,
        body3: 20,
        h1: 50,
        h2: 40,
        h3: 32,
        h4: 20,
        h5: 14,
    },
    size: {
        ...size,
    },
    device: {
        mobile: `(max-width: ${size.mobile}px)`,
        desktop: `(min-width: ${size.desktop}px)`,
    },
    maxWidth: {
        desktop: "1440px",
    }
}
import useMediaQuery from '@mui/material/useMediaQuery';

export const size = {
    mobile: 899,
    desktop: 900,
    largeDesktop: 1441,
  }

export const breakpoint = {
    mobile: `(max-width: ${size.mobile}px)`, 
    desktop: `(min-width: ${size.desktop}px)`,
    largeDesktop: `(min-width: ${size.largeDesktop}px)`,
}

export const pageMargin = {
    mobile: `30px`,
    desktop: `40px 100px`,
    largeDesktop: `40px 100px`,
}


export const ResponsiveUI = ({desktop, mobile, largeDesktop, mobileOffset = 0}) => {
  const matches = useMediaQuery(`(min-width: ${size.desktop + mobileOffset}px)`);
  const matchLarge = useMediaQuery(`(min-width: ${size.largeDesktop}px)`);

  if(matchLarge && largeDesktop){
    //   console.log("largeDesktop");
      return largeDesktop;
  } else if(matches && desktop) {
    //   console.log("desktop");
      return desktop;
  } else{
    //   console.log("mobile");
      return mobile;
  }
}

export const WindowSize = () => {
  const matches = useMediaQuery(`(min-width: ${size.desktop}px)`);
  const matchLarge = useMediaQuery(`(min-width: ${size.largeDesktop}px)`);
  const match1024 = useMediaQuery(`(min-width: 1024px)`)

  if(matchLarge){
      return 'largeDesktop';
  } else if(match1024){
      return '1024';
  } else if(matches) {
      return 'desktop';
  } else{
      return 'mobile';
  }
}
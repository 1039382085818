import { Form, FormikProvider, useFormik } from 'formik';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { processPayment, updateQuoteTnc } from '../../../apis';
import DevicePriceBar from '../../../components/BottomBar/DevicePriceBar';
import MyButton from '../../../components/Button';
import Space from '../../../components/Space';
import { GlobalContextStore } from '../../../context/GlobalProvider';
import { appearGlobalError } from '../../../requests/globalRequest';
import { formatQuoteProductForGA } from '../../../utils/common';
import { gaBeginCheckout } from '../../../utils/ga';
import { gtmBeginCheckout } from '../../../utils/gtm';
import useCheckoutGlobalTnc from '../hooks/useCheckoutGlobalTnc';
import { usePreviewOrderTnc } from '../hooks/usePreviewOrderTnc';
import * as Yup from 'yup';
import { breakpoint } from '../../../utils/responsive';

const getInitialValues = (data) => {
  const { userInfo, contactUserInfo, deliveryInfo } = data?.userForm;
  const initialValues = {
    order_list: data?.items || [],
    customer_title: userInfo?.title || '',
    customer_engName: userInfo?.fullName?.en || '',
    customer_chiName: userInfo?.fullName?.zh || '',
    customer_idType: userInfo?.idType || '',
    customer_idNum: userInfo?.idNumber || '',
    customer_dateOfBirth: userInfo?.birthday || '',
    customer_companyName: userInfo?.companyName || '',
    customer_jobPosition: userInfo?.companyPosition || '',
    customer_email: userInfo?.email || '',
    customer_mobileNo: userInfo?.mobileNumber || '',
    customer_address1: userInfo?.mailingAddress[0] || '',
    customer_address2: userInfo?.mailingAddress[1] || '',
    customer_district: userInfo?.mailingDistrict || '',
    contact_title: contactUserInfo?.title || '',
    contact_engName: contactUserInfo?.fullName?.en || '',
    contact_chiName: contactUserInfo?.fullName?.zh || '',
    contact_email: contactUserInfo?.email || '',
    contact_mobileNo: contactUserInfo?.mobileNumber || '',
    delivery_method: deliveryInfo?.deliveryMethod || 'pickup',
    delivery_addressId: deliveryInfo?.deliveryAddressSelectedId || '',
    delivery_address1: deliveryInfo?.deliveryAddress[0] || '',
    delivery_address2: deliveryInfo?.deliveryAddress[1] || '',
    delivery_district: deliveryInfo?.deliveryDistrict || '',
    delivery_date: deliveryInfo?.deliveryDate || '',
    delivery_time: deliveryInfo?.deliveryTime || '',
    payment_method: 'creditCard',
    tnc_accept_status: {},
  };

  return initialValues;
};


/**
   * 
   * @param {string[]} requiredTncIds 
   * @returns 
   */
const createValidationSchema = (requiredTncIds) => {
  const validationObject = {};
  requiredTncIds.forEach((id) => {
    validationObject[id] = Yup.boolean().required().oneOf([true], 'error:required');
  });
  return Yup.object().shape({
    tnc_accept_status: Yup.object().shape(validationObject),
  });
}

const PreviewForm = ({ data: quoteData, children, setIsPreview }) => {
  const { globalDispatch } = useContext(GlobalContextStore);
  const { t } = useTranslation('checkout');

  const { acceptedTNCStatus: acceptedTNC } = useCheckoutGlobalTnc();
  const { productOverallTncIds, overallTncStatus, isPreviewRequiredTncsAccepted } = usePreviewOrderTnc();




  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(quoteData),
    onSubmit: async (payload, { setErrors }) => {

      if (!isPreviewRequiredTncsAccepted) {
        setErrors({ accepted_tnc: "error:requiredAcceptTnc" });
        return
      }
      try {
        //update tnc
        const tncResult = await updateQuoteTnc(acceptedTNC);
        if (tncResult && tncResult?.msg === 'success') {
          const gaPayload = quoteData?.items.map((item, index) =>
            formatQuoteProductForGA({ ...item, sort: index })
          );

          // trigger begin_checkout ga event
          gaBeginCheckout(gaPayload, quoteData?.promoCode);
          gtmBeginCheckout(gaPayload, quoteData?.promoCode);

          //checkout
          let urlBase = window.location.origin;
          const checkoutResult = await processPayment({
            redirectUrl: `${urlBase}/processingPayment`,
          });
          if (checkoutResult && checkoutResult?.data?.redirect_url) {
            window.open(checkoutResult?.data?.redirect_url, '_self');
          } else {
            throw {
              code: checkoutResult?.result?.code,
              msg: checkoutResult?.result?.errorMessage,
            };
          }
        } else {
          throw {
            code: tncResult?.result?.code,
            msg: tncResult?.result?.errorMessage,
          };
        }
      } catch (e) {
        console.log('submit fail', e);
        appearGlobalError(
          globalDispatch,
          e?.result?.code,
          e?.result?.errorMessage,
          {
            type: 'fail',
          }
        );
      }
    },
    // validationSchema: createValidationSchema(productOverallTncIds),
  });


  // useEffect(() => {
  //   const newStatus = {}
  //   overallTncStatus.forEach((item) => {
  //     newStatus[item.tncId] = item.accepted;
  //   })
  //   formik.setFieldValue('tnc_accept_status', newStatus);
  // }, [overallTncStatus])


  return (
    <FormikProvider value={formik}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Form style={{ maxWidth: '1440px', overflowX: "hidden" }}>
          <Container>{children}</Container>

          <PaymentButton>
            <MyButton type="secondary" onClick={() => setIsPreview(false)}>
              {t('back')}
            </MyButton>
            <Space width="20px" />
            <MyButton onClick={() => formik.submitForm()}>
              {t('payment')}
            </MyButton>
          </PaymentButton>

          <DevicePriceBar
            totalPrice={quoteData?.calculatedOrderPrice}
            onClick={() => formik.submitForm()}
            isCheckout
            titleShowTotal
          />
        </Form>
      </div>
    </FormikProvider>
  );
};

const Container = styled.div`
  margin: 0 50px;

  @media ${breakpoint.mobile} {
    margin: 0 35px;
  }
`;

const PaymentButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 50px;

  @media ${breakpoint.mobile} {
    margin: 35px;
    flex-direction: column;
  }
`;

export default PreviewForm;

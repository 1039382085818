import { useState, useEffect, useRef, useMemo } from "react";
import Field from "./Field";
import QuantiyField from "./QuantityField";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider, useFormik } from "formik";
import AlertDialog from "../../components/CommonDialog/AlertDialog";
import _ from "lodash";
import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";
import TotalPriceBar from "../../components/BottomBar/DevicePriceBar";

// const mapStateToProps = (state, editId) => editId ? state.order_list.find(o => o.id === editId) : null;

const getInitialValues = (data, selected) => {
  const initialValues = {
    plan: data?._id || "",
    contractPeriod: selected?.options?.contractPeriodId || "",
    freeVas: selected?.options?.freeVas || [],
    paidVas: selected?.options?.paidVas || [],
    quantity: selected?.quantity || 1,
  };

  return initialValues;
};

const MobileForm = ({ setSteps, steps, data, selected, onSubmit }) => {
  const { t } = useTranslation("mobile");
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const stepRefs = useRef([])

  const formik = useFormik({
    enableReinitialize: true,
    // initialValues: getInitialValues(plan, mapStateToProps(orderState, router.query.editId)),
    initialValues: getInitialValues(data, selected),
    onSubmit: async (payload) => {
      let body = {
        plan: payload.plan,
        quantity: payload.quantity,
        options: {
          contractPeriodId: payload.contractPeriod,
          freeVas: payload.freeVas,
          paidVas: payload.paidVas,
        },
      };
      onSubmit && onSubmit(body);
    },
  });




  const formOptions = {
    contractPeriod: data?.contractPeriodDetails,
    freeVas: data?.freeVas.map((item) => ({ ...item, discountedPrice: 0, isRecommended: (data?.recommendVasIds || []).includes(item._id) })),
    paidVas: data?.paidVas.map((item) => ({ ...item, isRecommended: (data?.recommendVasIds || []).includes(item._id) })),
  };


  const onTitleClick = (id, isRequired, val) => {
    if (isRequired && _.isEmpty(val)) {
      setIsAlertDialogOpen(true);
    } else {
      const newSteps = steps.map((item) => {
        if (item.id === id - 1 && val) {
          return {
            ...item,
            // isCompleted: val ? !_.isEmpty(val) : item?.isCompleted,
            isCompleted: true,
            isActive: false,
          };
        } else {
          return { ...item, isActive: item.id === id };
        }
      });

      // if next active step is the last step, set it as completed
      if (id === steps.length) {
        newSteps[id - 1].isCompleted = true;
      }

      setSteps(newSteps);
    }
  };

  const totalPrice = useMemo(() => {
    const value = formik.values;
    if (!value) return 0
    const { contractPeriod, freeVas, paidVas, quantity = 1 } = value;

    let total = 0;
    if (contractPeriod) {
      const contractPeriodPrice = data?.contractPeriodDetails.find(
        (item) => item._id === contractPeriod
      ).discountedPrice;

      total += contractPeriodPrice || 0;
    }

    if (freeVas) {
      freeVas.forEach((item) => {
        const freeVasPrice = data?.freeVas.find(
          (i) => i._id === item
        ).discountedPrices;
        total += freeVasPrice || 0;
      });
    }

    if (paidVas) {
      paidVas.forEach((item) => {
        const paidVasPrice = data?.paidVas.find(
          (i) => i._id === item
        ).discountedPrice;
        total += paidVasPrice || 0;
      });
    }

    return total * quantity;
  }, [formik?.values])

  const disableSubmit = steps.some((item) => !item.isCompleted);

  useEffect(function scrollOnActiveStepChange() {
    const activeStep = steps?.find((item) => item.isActive);
    if (activeStep) {
      stepRefs.current[activeStep.id - 1]?.scrollIntoView({ 
        block: "start",
        behavior: "smooth",
      });
    }
  }, [steps])

  return (
    <FormikProvider value={formik}>
      <Form>
        <FieldContainer>
          <Field
            id="contractPeriod"
            title={t("contractPeriod")}
            options={formOptions.contractPeriod}
            stepId={1}
            steps={steps}
            onTitleClick={onTitleClick}
            isRequired
            showTag={false}
            ref={(el) => (stepRefs.current[0] = el)}
          />
          <Field
            id="freeVas"
            title={t("freeService")}
            options={formOptions.freeVas}
            stepId={2}
            steps={steps}
            onTitleClick={onTitleClick}
            multiple
            maxSelection={data?.freeVasMax}
            maxOptionShownCollapsed={6}
            ref={((el) => stepRefs.current[1] = el)}
          />
          <Field
            id="paidVas"
            title={t("businessService")}
            options={formOptions.paidVas}
            stepId={3}
            steps={steps}
            onTitleClick={onTitleClick}
            multiple
            maxOptionShownCollapsed={6}
            ref={((el) => stepRefs.current[2] = el)}
          />
          <QuantiyField
            id="quantity"
            stepId={4}
            steps={steps}
            setSteps={setSteps}
            onTitleClick={onTitleClick}
            min={data?.quantityMin}
            max={data?.quantityMax}
            isLastStep={true}
            ref={((el) => stepRefs.current[3] = el)}
          />
        </FieldContainer>

        <TotalPriceBar
          disabled={disableSubmit}
          totalPrice={totalPrice}
          onClick={() => formik.submitForm()}
        />
      </Form>

      <AlertDialog
        isOpen={isAlertDialogOpen}
        handleClose={() => setIsAlertDialogOpen(false)}
        title={t("itemsNotSelected")}
        content={`${t("pleaseSelect")} ${t("contractPeriod")}`}
        btnLabel="Ok"
        type="alert"
      />
    </FormikProvider>
  );
};

const FieldContainer = styled.div`
  z-index: 0;

  @media ${breakpoint.desktop} {
    padding: 28px 0px 28px 50px;
  }

  @media ${breakpoint.mobile} {
    padding-top: 20px;
  }
`;

export default MobileForm;

import React, { useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Typography from "../../../components/Typography";
import Collapse from "../../../components/Collapse";
import Container from "../../../components/Container";
import ClientInfo from "./ClientInfo";
import PrepaidNumber from "./PrepaidNumber";
import theme from "../../../theme";
import { WindowSize } from "../../../utils/responsive";
import { fetchUserOrderById } from "../../../apis";
import LoadingIndicator from "../../../components/LoadingIndicator";

const OrderItem = (props) => {
  const { data, index, defaultExpand=false } = props;

  const { t } = useTranslation("profile");
  const [isExpand, setIsExpand] = useState(defaultExpand);

  const isMobile = WindowSize() === "mobile";

  const { 
    data: orderData,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["fetchUserOrderById", { id: data?.orderId }],
    queryFn: () => fetchUserOrderById(data?.orderId),
    enabled: isExpand,
  });

  const pendingDocumentData = useMemo(() => {
    const planRelatedData = orderData?.data?.filter((item) => item?.type === "plan");
    // console.log(`==planRelatedData ${index} - `, planRelatedData)

    const isGlobalAllowUpload = planRelatedData?.[0]?.isUploadDocumentsAllowed;
    const GlobalDocumentKey = ["passport", "addressProof"];

    return {
      // TODO
      clientInfo: {
        ...planRelatedData?.[0]?.userForm,
        orderId: data?.orderId,
        document: [
          {
            type: planRelatedData?.[0]?.userForm?.idType,
            uploadDate: planRelatedData?.[0]?.plan?.userDocuments?.passport?.value?.createdAt,
            status: planRelatedData?.[0]?.plan?.userDocuments?.passport?.value?.docStatus || "pending",
            filename: planRelatedData?.[0]?.plan?.userDocuments?.passport?.value?.filename,
            isAllowUpload: isGlobalAllowUpload && planRelatedData?.[0]?.plan?.userDocuments?.passport?.isUploadRequired,
            orderId: data?.orderId,
          },
          {
            type: "addressProof",
            uploadDate: planRelatedData?.[0]?.plan?.userDocuments?.addressProof?.value?.createdAt,
            status: planRelatedData?.[0]?.plan?.userDocuments?.addressProof?.value?.docStatus || "pending",
            filename: planRelatedData?.[0]?.plan?.userDocuments?.addressProof?.value?.filename,
            isAllowUpload: isGlobalAllowUpload && planRelatedData?.[0]?.plan?.userDocuments?.addressProof?.isUploadRequired,
            orderId: data?.orderId,
          }
        ]
      },
      numberList: planRelatedData?.map((item) => {
        const isMnp = item?.plan?.mnpNumber?.number !== undefined;
        if (!isMnp) return null;

        const requireDocuments = item?.plan?.userDocuments ? Object.keys(item?.plan?.userDocuments).filter((key) => !GlobalDocumentKey.includes(key)) : [];

        return {
          mobileNumber: item?.plan?.mnpNumber?.number,
          effectiveDate: item?.plan?.mnpNumber?.effectiveDate,
          effectiveTime: item?.plan?.mnpNumber?.effectiveTime,
          document: requireDocuments.map((key) => ({
            type: key,
            uploadDate: item?.plan?.userDocuments?.[key]?.value?.createdAt,
            status: item?.plan?.userDocuments?.[key]?.value?.docStatus || "pending",
            filename: item?.plan?.userDocuments?.[key]?.value?.filename,
            isAllowUpload: isGlobalAllowUpload && item?.plan?.userDocuments?.[key]?.isUploadRequired,
            orderItemId: item?.orderItemId,
          }))
        }
      }).filter((item) => item !== null)
    }
  }, [orderData?.data])

  const OrderNoItem = ({ orderId }) => {
    return (
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Typography fontSize={isMobile ? 18 : 16} style={{ width: 'max-content', textWrap: 'nowrap' }}>{t('profile:orderDetails.orderNo')}</Typography>
        <Typography fontSize={18} bold style={{ lineHeight: '24px' }}>{orderId}</Typography>
      </div>
    )
  }

  const CollapseTitleProp = ({ orderId = "", ...props }) => {
    return (
      <Grid container gap={"40px"} alignItems={"center"} style={{ minHeight: "42px" }}>
        <Grid item xs md={2}>
          <Typography fontSize={24} bold style={{ lineHeight: '24px' }}>{t('pendingDocument.order', { index: index })}</Typography>
        </Grid>
        {!isMobile && (
          <Grid item xs>
            <OrderNoItem orderId={orderId} />
          </Grid>
        )}
      </Grid>
    )
  }

  const CollapseSubTitleProp = ({ orderId, ...props }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <OrderNoItem orderId={orderId} />
      </div>
    )
  }

  const showPrepaidNumberSection = pendingDocumentData?.numberList?.length > 0 
                                    && pendingDocumentData.numberList.some(item => item?.document.length > 0);

  return (
    <Container style={{ boxShadow: theme.outerContainerShadow, padding: 0 }}>
      <div>
        <Collapse
          titleProp={<CollapseTitleProp orderId={data?.orderNumber} />}
          descriptionProp={isMobile ? (<CollapseSubTitleProp orderId={data?.orderNumber} />) : null}
          isOpen={isExpand}
          onExpand={() => setIsExpand((prev) => !prev)}
        >
          {
            isLoading ? <LoadingIndicator /> : (
              <div style={{ display: "flex", flexDirection: "column", gap: 50, padding: 30, paddingTop: 0 }}>
                <ClientInfo 
                  key={`clientInfo-${index}`} 
                  data={pendingDocumentData?.clientInfo}
                  refetch={refetch}
                />
                
                { 
                  showPrepaidNumberSection && (
                    <PrepaidNumber
                      key={`number-${index}`}
                      data={pendingDocumentData?.numberList}
                      refetch={refetch}
                    />
                  )
                }
              </div>
            )
          }
        </Collapse>
      </div>
    </Container>
  )
}

export default OrderItem;
import { useEffect } from "react";
import { forwardRef, useState } from "react";
import { Grid, Tabs, Tab, Divider } from "@mui/material";
import styled from "styled-components";
import theme from "../../theme";
import { WindowSize, breakpoint } from "../../utils/responsive";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const StyledTabs = styled(Tabs)`
  position: relative;

  & .MuiTabScrollButton-root {
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(265deg, rgba(255, 255, 255, 0.25) 89%, #fff 48%, #fff 6%);
  }

  & .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 1;
  }

  & .MuiTabs-indicator {
    display: none;
  }

  @media ${breakpoint.mobile} {
    & .MuiTabs-flexContainer {
      width: max-content;
    }
  }
`

const ScrollButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  position: absolute;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);

  ${props => props.direction === 'left' ? 'left: 0;' : 'right: 0;'}
  transform: ${props => props.direction === 'left' ? 'none' : 'rotate(180deg)'};

  z-index: 20;
`

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    color: ${theme.lightBlack};
    font-size: 22px;
    font-weight: bold;
    border: none;
    background: ${theme.divider};
    border-top-right-radius: 25px;
    margin-right: 12.8px;
    text-transform: none;
    text-align: start;
  }

  @media ${breakpoint.mobile} {
    &.MuiTab-root:first-child {
      margin-left: 24px;
    }
  }

  &.Mui-selected {
    border: none;
    background: ${theme.tagGradientColor};
    color: ${theme.lightBlack} !important;
  }

  // add margin to last tab so that scroll button not overlap with tab
  &:last-child {
    margin-right: 24px;
  }
`

const ScrollButton = forwardRef((props, ref) => {
  const { direction, disabled, setTabIndex, totalTabs, ...other } = props;

  const handleClickButton = () => {
    setTabIndex((prev) => {
      if (direction === 'left') {
        return prev > 0 ? prev - 1 : prev;
      } else {
        return prev < totalTabs - 1 ? prev + 1 : prev;
      }
    })
  }

  return (
    <ScrollButtonContainer ref={ref} {...other} direction={direction} onClick={handleClickButton}>
      <KeyboardArrowLeftIcon fontSize="small" opacity={disabled ? 0.3 : 1} />
    </ScrollButtonContainer>
  )
});

const TabSection = (props) => {
  const { tabs = [], value, setValue, showScrollButton=true, tabStyle=null } = props;

  const isMobile = WindowSize() === "mobile";

  return (
    <Grid item>
      <StyledTabs
        value={value}
        onChange={(event, newValue) => setValue && setValue(newValue)}
        variant={showScrollButton ? "scrollable" : undefined}
        scrollButtons={showScrollButton && isMobile && tabs.length > 1}
        allowScrollButtonsMobile={showScrollButton && isMobile}
        selectionFollowsFocus={true}
        ScrollButtonComponent={ScrollButton}
        TabScrollButtonProps={{
          // tabIndex: value,
          setTabIndex: setValue,
          totalTabs: tabs?.length,
        }}
      >
        {tabs.map((tab, index) => (
          <StyledTab id={`tab-${index}`} label={tab || ""} style={{...tabStyle}} />
        ))}
      </StyledTabs>
      <Divider orientation="horizontal" flexItem />
    </Grid>
  );
};

const CommonTab = ({ tabs, tabStyle, tabGap=2, initialTabIndex=0 }) => {
  const [tab, setTab] = useState(initialTabIndex);

  useEffect(() => {
    if (initialTabIndex !== tab) setTab(initialTabIndex);
  }, [initialTabIndex])

  return (
    <Grid
      container
      item
      flexDirection={"column"}
      gap={tabGap}
    >
      <Grid item xs>
        <TabSection
          tabs={tabs.map((tab) => tab.label)}
          value={tab}
          setValue={setTab}
          tabStyle={tabStyle}
        />
      </Grid>

      <Grid item xs container>
        {tabs
          ? tabs[tab].render
            ? tabs[tab].render()
            : "Render Error"
          : "No Content"}
      </Grid>
    </Grid>
  );
};

export default CommonTab;

import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../components/CommonDialog/ConfirmDialog";
import MyTextField from "../../../components/TextField";
import DistrictSelect from "../../../components/Selects/DistrictSelect";
import CommonTextField from "../../../components/Common/CommonTextField";
import Space from "../../../components/Space";
import { updateProfileAddress, addProfileAddress } from "../../../apis";
import { useEffect, useState, useContext } from "react";
import { updateUserInfo } from "../../../requests/globalRequest";
import { GlobalContextStore } from "../../../context/GlobalProvider";
import _ from "lodash";

const checker = (data, setError) => {
  let error = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (_.isEmpty(data[key])) {
        error = { ...error, [key]: true };
      }
    }
  }

  setError(error);
  return !_.isEmpty(error);
};

const EditAddressDialog = ({
  isOpen,
  setIsOpen,
  addressId,
  addressOne,
  addressTwo,
  district,
  handleAutoSubmit,
}) => {
  const { t } = useTranslation("checkout");
  const { globalState, globalDispatch } = useContext(GlobalContextStore);
  const [error, setError] = useState();
  const [value, setValue] = useState({
    addr1: "",
    addr2: "",
    dist: "",
  });

  useEffect(() => {
    if (addressId) {
      setValue({
        addr1: addressOne,
        addr2: addressTwo,
        dist: district,
      });
    }
  }, []);

  const handleSave = async () => {
    //TODO - handle field blocking
    const body = {
      address: [value?.addr1, value?.addr2],
      district: value?.dist,
    };

    if (!checker(value, setError)) {
      try {
        const result = addressId
          ? await updateProfileAddress(body, addressId)
          : await addProfileAddress(body);
        handleAutoSubmit();
        updateUserInfo(globalDispatch);
        handleClose();
        setIsOpen(false);
      } catch (e) {
        console.log("update address error - ", e);
      }
    }
  };

  const handleChange = (e, id) => {
    const val = e.target.value;
    setValue({ ...value, [id]: val });
  };

  const handleSelect = (e, id) => {
    setValue({ ...value, [id]: e.target.value });
  };

  const handleClose = () => {
    setError({});
    setValue({
      addr1: "",
      addr2: "",
      dist: "",
    });
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleClose={handleClose}
      actionBtnLabel={t("common:save")}
      onConfirm={handleSave}
    >
      <CommonTextField
        value={value.addr1}
        handleChange={(e) => handleChange(e, "addr1")}
        placeholder={t("addressRemark1")}
        title={t("address")}
        helperText="error:required"
        error={error?.addr1}
        required
      />
      <Space size="15px" />
      <CommonTextField
        value={value.addr2}
        handleChange={(e) => handleChange(e, "addr2")}
        placeholder={t("addressRemark2")}
        helperText="error:required"
        error={error?.addr2}
      />
      <Space size="15px" />

      <DistrictSelect
        value={value.dist}
        error={error?.dist}
        helperText="error:required"
        onChange={(e) => handleSelect(e, "dist")}
      />
    </ConfirmDialog>
  );
};

export default EditAddressDialog;

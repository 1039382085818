import { Grid } from "@mui/material";
import Typography from "../../../../components/Typography";
import AddressRecord from "./AddressRecord";
import Checkbox from "../../../../components/Common/CommonCheckbox";
import { useTranslation } from "react-i18next";

const PADDING_VALUE = '38px';

const AddressSection = (props) => {
    const { data, title="", onEdit=null, onDelete=null, showSameAsMailingCheckbox=false } = props;

    const { t } = useTranslation('profile');

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: PADDING_VALUE,
            width: "100%",
            padding: `0 ${PADDING_VALUE}`,
        }}>
            <Grid item container alignItems={{ md: "center" }} flexWrap={"wrap"} flexDirection={{ xs: "column", md: "row" }}>
                <Typography style={{ fontSize: '24px', fontWeight: 'bold', marginRight: "30px" }}>{title}</Typography>
                { showSameAsMailingCheckbox && (
                    <Grid item xs marginTop={{ xs: "30px", md: "0" }}>
                        <Checkbox
                            id="delivery_isSameAsMailingAddress"
                            label={t("accountProfile.sameAsMailingAddress")}
                            checked={data?.isSameAsMailingAddress}
                            disabled
                            checkboxStyle={{ paddingLeft: 0 }}
                        />
                    </Grid>
                )}
            </Grid>
            { data?.addresses && data?.addresses.length > 0 && (
                <Grid 
                    container 
                    columnSpacing={5}
                    rowGap={{ xs: PADDING_VALUE, md: '78px' }} 
                    direction={{ xs: 'column', md: 'row' }} 
                    justifyContent={'flex-start'}
                    spacing={0}
                >
                    { data?.addresses?.map((item) => {
                        return (
                            <Grid item xs md={4}>
                                <AddressRecord data={item} onEdit={onEdit} onDelete={onDelete} />
                            </Grid>
                        )
                    })}
                </Grid>
            )}
        </div>
    )
}

export default AddressSection;
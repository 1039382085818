import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import AlertDialog from "../../../components/CommonDialog/AlertDialog";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import NoDocumentIcon from "../../../assets/ic-noDocument.svg";
import OrderItem from "./OrderItem";
import { breakpoint } from "../../../utils/responsive";
import { DocConfigProvider } from "./context/DocConfigProvider";

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 100%;

  @media ${breakpoint.mobile} {
    padding: 0 30px;
  }
`;

const PendingDocument = (props) => {
  const { data=[], refetch, } = props;

  const { t } = useTranslation("profile");
  const [hintDialogIsOpen, setHintDialogIsOpen] = useState(false);

  useEffect(() => {
    const isAfterPayment = localStorage.getItem("isAfterPayment");
    if (isAfterPayment) {
      setHintDialogIsOpen(true);
      localStorage.removeItem("isAfterPayment");
    }
  }, [])

  if (data.length === 0) return (
    <Grid container justifyContent="center">
      <Grid container item xs={12} md={6} justifyContent={'center'} style={{ padding: '100px 0' }}>
        <img src={NoDocumentIcon} alt="No Document" />
      </Grid>
    </Grid>
  );
  
  return (
    <DocConfigProvider>
      {hintDialogIsOpen && (
        <AlertDialog
          isOpen={hintDialogIsOpen}
          handleClose={() => setHintDialogIsOpen(false)}
          title={t("pendingDocument.dialogTitle", { time: 24 })}
          titleStyle={{ textAlign: "center" }}
          content={t("pendingDocument.dialogContent")}
          btnLabel={t("title.myAccount")}
          type="upload"
        />
      )}
      <BodyContainer>
        { data.map((item, index) => (
          <div>
            <OrderItem
              key={`orderItem-${index}`}
              index={data.length - index}
              data={item}
              defaultExpand={index === 0}
            />
          </div>
        ))}
      </BodyContainer>
    </DocConfigProvider>
  );
};

export default PendingDocument;

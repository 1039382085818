import { useState, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import theme from "../theme";
import Typography from "./Typography";
import TextField from "./TextField";
import Button from "./Button";
import { fetchUserTest, requestLoginOtp } from "../apis";
import Space from "./Space";
import ImageLoginBg from "../assets/image-loginBg.png";
import ImageLoginBgMobile from "../assets/image-loginBgMobile.png";
import { breakpoint } from "../utils/responsive";
import { useNavigate } from "react-router-dom";
import CheckEmail from "../pageComponents/login/CheckEmail";
import LoginOTP from "../pageComponents/login/LoginOTP";
import { appearGlobalError } from "../requests/globalRequest";
import { GlobalContextStore } from "../context/GlobalProvider";
import { gaSignUpFail } from "../utils/ga";
import { gtmSignUpFail } from "../utils/gtm";

const Container = styled.div`
  box-shadow: 0 3px 15px 0 #c9c9c9;
  background-color: #fff;

  @media ${theme.device.mobile} {
    height: 683px;
    width: 314px;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  @media ${theme.device.desktop} {
    height: 580px;
    width: 1024px;
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
  }
`;

const Left = styled.div`
  flex: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${theme.device.mobile} {
    border-top-right-radius: 25px;
    background-image: url(${ImageLoginBgMobile});
    padding: 40px 20px 30px 20px;
  }

  @media ${theme.device.desktop} {
    border-bottom-left-radius: 50px;
    border-top-right-radius: 0px;
    background-image: url(${ImageLoginBg});
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${theme.device.mobile} {
    flex: 1.5;
    margin: 40px;
  }

  @media ${theme.device.desktop} {
    flex: 1;
    margin: 70px;
  }
`;

const LoginHeader = styled(Typography)`
  && {
    @media ${theme.device.mobile} {
      font-size: ${theme.fontSize.title2}px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    @media ${theme.device.desktop} {
      font-size: ${theme.fontSize.title5}px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
`;

function Login(props) {
  const { onLogin } = props;
  const { t } = useTranslation("login");
  const {globalDispatch} = useContext(GlobalContextStore);

  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [loginEmail, setLoginEmail] = useState();

  const handleLogin = async (email) => {
    try {
      const res = await requestLoginOtp({ email: email });

      if (res && res.msg === "success") {
        if (res.contactUs === true) {
          gaSignUpFail();
          gtmSignUpFail();
          navigate("/contactUs", { replace: true });
        }
        setStep(1);
        setLoginEmail(email);
      }else {
        throw {
          code: res.result.code,
          error: res.result.errorMessage,
        };
      }
    } catch (e) {
      console.log("request otp error - ", e);
      appearGlobalError(globalDispatch, e?.code, e?.errorMessage, {
        type: "fail",
      });
    }
  };

  const onResend = async () => {
    handleLogin(loginEmail);
  };

  return (
    <Container>
      <Left>
        <Typography
          fontSize={theme.fontSize.header}
          style={{ marginBottom: 12 }}
          bold
          textShadow={"0 3px 6px #c7b645"}
        >
          {t("welcome")}
        </Typography>
        <Typography
          fontSize={theme.fontSize.title4}
          breakSpaces
          align="center"
          bold
          textShadow={"0 3px 6px #c7b645"}
          style={{ maxWidth: 320 }}
          textAlign="center"
        >
          {t("welcome_desc")}
        </Typography>
      </Left>
      <Content>
        <LoginHeader>{t("loginOrCreateAcc")}</LoginHeader>
        {step === 0 ? (
          <CheckEmail handleLogin={handleLogin} />
        ) : (
          <LoginOTP onResend={onResend} loginEmail={loginEmail}/>
        )}
      </Content>
    </Container>
  );
}

export default Login;

import { useContext, useEffect } from 'react'
import { Grid } from '@mui/material';
import styled from 'styled-components';
import Login from '../components/Login';
import withNavigate from '../hoc/withNavigate'
import { GlobalContextStore } from '../context/GlobalProvider';
import _ from "lodash";

const Container = styled(Grid)`
    && {
        height: 100%;
        width: 100%;
        display: flex;
        margin: 7% 0px;
    }
`

function LoginPage(props){
    const { globalState } = useContext(GlobalContextStore);
    const { navigate } = props;

    useEffect(() => {
        if (!_.isEmpty(globalState?.userInfo)) {
            navigate('/profile', { replace: true })
        }
    }, [])

    return (
        <Container justifyContent="center" alignItems="center">
            <Login />
        </Container>
    )
}

export default withNavigate(LoginPage);
import { createContext } from "react";

import { useContext } from "react";
import { CheckoutFormContext } from "./CheckoutFormContext";
import useCheckoutInitialRequiredTncs from "../hooks/useCheckoutInitialRequiredTncs";




/**
 * @typedef {ReturnType<typeof useCheckoutInitialRequiredTncs>} TncContextValue
 */

/**
 * @type {TncContextValue}
 */
const defaultContext = {}

export const TncContext = createContext(defaultContext)



export default function TncProvider({ children }) {
  const { isDeviceOnly, quoteQuery, tncQuery } = useContext(CheckoutFormContext)
  const ctx = useCheckoutInitialRequiredTncs(isDeviceOnly, quoteQuery, tncQuery)

  return (
    <TncContext.Provider value={{ ...ctx }}>
      {children}
    </TncContext.Provider>
  )
}
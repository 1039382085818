import styled from "styled-components";
import MyTypography from "../Typography";
import RemarkIcon from "../../assets/ic-remark.svg";
import MyButton from "../Button";
import { Link } from "react-router-dom";
import Space from "../Space";
import { useTranslation } from "react-i18next";
import { breakpoint, WindowSize } from "../../utils/responsive";
import { Tooltip } from "@mui/material";
import { formatCurrency } from "../../utils/common";

const DevicePriceBar = ({ titleShowTotal, isCheckout=false, onClick, totalPrice, showUp=false, disabled=false, isEdit=false }) => {
  const { t } = useTranslation(["common", "mobile", "checkout"]);
  const isMobile = WindowSize() === "mobile";

  const buttonColor = isCheckout ? "black" : "yellow";

  return (
    <Container>
      <SubContainer>
        <Title>
          <div style={{ display: "flex", alignItems: "center" }}>
            <MyTypography fontSize={isMobile ? "20" : "24"} bold>
              {titleShowTotal ? t("common:totalPrice") : t("common:firstPayment")}
            </MyTypography>
            {/* <Tooltip title={"Remark Text"} arrow placement="top">
              <img src={RemarkIcon} alt={"Remark"} style={{ marginLeft: "10px" }} />
            </Tooltip> */}
          </div>
          { !isMobile && <Space width="10px" /> }

          <TotalPrice>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MyTypography fontSize="24" condensed>HK$</MyTypography>
              <MyTypography fontSize={isMobile ? "30" : "38"} bold condensed>
                {formatCurrency(totalPrice || 0, 1)}
              </MyTypography>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: isMobile ? '3px' : '5px' }}>
              <MyTypography fontSize="16" bold>
                {showUp && t("common:up")}
              </MyTypography>
            </div>

          </TotalPrice>
        </Title>
        <MyButton
          color={buttonColor}
          style={{ width: "auto", maxWidth: 150, marginLeft: isMobile ? '50px' : 0 }}
          onClick={onClick}
          disabled={disabled}
        >
          {isCheckout ? t("checkout:payment") : isEdit ? t("common:save") : t("common:addToCart")}
        </MyButton>
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  height: auto;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  box-shadow: rgb(0,0,0,0.25) 0px -3px 10px;

  display: flex;
  justify-content: center;
`;

const SubContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 84px;
  margin: 0 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${breakpoint.desktop} {
    flex-direction: row;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TotalPrice = styled.div`
  margin-left: 50px;
  display: flex;
  // align-items: center;

  @media ${breakpoint.mobile} {
    margin-left: 0;
  }
`;

export default DevicePriceBar;

import styled from 'styled-components';
import { Box, Grid } from '@mui/material';
import { Divider } from '@mui/material';
import Typography from '../Typography';
import MobileIcon from '../../assets/ic-mobilePlanCard.svg';
import DeleteIcon from '../../assets/ic-delete.svg';
import ItemTable from './ItemTable';
import PlanDetail from '../../pageComponents/shoppingcart/PlanDetail';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveUI } from '../../utils/responsive';
import { GetWord, formatCurrency } from '../../utils/common';
import _, { get } from 'lodash';
import CardBase from '../Card/CardBase';
import { getItemColumnWidth } from '../../utils/common';
import { useField } from 'formik';

const PlanContent = ({
  renderDetail,
  viewOnly,
  orderRecord,
  onEdit,
  onDelete,
  remarkProps,
  iconNeedDotDecoration,
  data,
  idx,
  cardBaseStyle,
  extraWidthForButton = false,
}) => {
  const { t } = useTranslation(['orderContentItem', 'common']);
  const [showDetail, setShowDetail] = useState(true);

  const getPlanNumbers = () => {
    if (data?.type === 'plan' && data.mnpNumbers && data.newNumbers) {
      return data.newNumbers.concat(data.mnpNumbers).map((n) => n.number);
    }
    return null;
  };

  const getContract = () => {
    const contractPeriodId = data?.options?.contractPeriodId;
    const contractInfo = data?.plan?.contractPeriodDetails;

    return _.find(contractInfo, ['_id', contractPeriodId]);
  };

  return (
    <CardBase style={{ ...cardBaseStyle }}>
      <ItemTable
        type={'plan'}
        editId={data?._id}
        itemId={data?.plan?._id}
        colWidth={getItemColumnWidth('plan')}
        header={[
          t('item', { index: idx + 1 }),
          t('selectedDetails'),
          t('contractPeriod'),
          t('quantity'),
          t('monthlyFee'),
          t('total'),
        ]}
        headerChildren={
          viewOnly && (
            <ResponsiveUI
              desktop={
                <div style={{ alignSelf: 'flex-start', borderTop: '1px #e0e0e0 solid', width: '100%', paddingTop: 16 }}>
                  {getPlanNumbers() &&
                    getPlanNumbers().map((number, index) => (
                      <div>
                        <Typography style={{ paddingRight: 16 }}>{t('orderContentItem:numberNo', { index: index + 1 })}</Typography>
                        <Typography style={{ fontWeight: 700 }}>{number}</Typography>
                      </div>
                    ))}
                </div>
              }
              mobile={
                <Box display='flex' flexDirection='column' alignItems='flex-end' ml={2} pt={2} pr={2} borderTop={"1px #e0e0e0  solid"}>
                  {getPlanNumbers() &&
                    getPlanNumbers().map((number, index) => (
                      <Typography
                        key={index}
                        fontSize="14"
                        style={{ marginBottom: 5 }}
                      >
                        <span style={{ paddingRight: 16 }}>{t('orderContentItem:numberNo', { index: index + 1 })}</span>
                        <span style={{ fontWeight: 700 }}>{number}</span>
                      </Typography>
                    ))}
                </Box>
              }
            />
          )
        }
        row={[
          GetWord(data?.plan?.planName),
          GetWord(data?.plan?.localData),
          `${getContract()?.period} ${t('common:months')}`,
          data?.quantity,
          `HK$ ${formatCurrency(data?.discountedSubtotal / data?.quantity, 1)}`,
          `HK$ ${formatCurrency(data?.discountedSubtotal, 1)}`,
        ]}
        icon={
          data?.plan?.flipCardIconImageUrl ? (
            <img
              src={data?.plan?.flipCardIconImageUrl}
              style={{ objectFit: 'cover' }}
            />
          ) : (
            <img src={MobileIcon} style={{ objectFit: 'cover' }} />
          )
        }
        onEdit={onEdit}
        onDelete={onDelete}
        setShowDetail={setShowDetail}
        showDetail={showDetail}
        renderDetail={renderDetail}
        orderRecord={orderRecord}
        iconNeedDotDecoration={data?.plan?.flipCardIconImageUrl ? true : iconNeedDotDecoration}
        extraWidthForButton={extraWidthForButton}
      />
      {showDetail && renderDetail}
      {onDelete && (
        <ResponsiveUI
          mobile={
            <>
              <div style={{ display: 'flex' }}>
                <Divider style={{ width: '100%' }} />
              </div>
              <DeleteButton onClick={() => onDelete && onDelete()}>
                <img src={DeleteIcon} style={{ marginRight: 5 }} />
                <Typography fontSize="18" bold>
                  {t('delete', { ns: 'common' })}
                </Typography>
              </DeleteButton>
            </>
          }
          desktop={<div />}
        />
      )}
      {remarkProps !== undefined && remarkProps}
    </CardBase>
  );
};

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 8px 0 #9b9b9b;
  border-radius: 25px;
  margin-bottom: 30px;

  overflow: hidden;
  padding: 20px 30px;
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 25px 0;
`;

export default PlanContent;

import { useLayoutEffect, useState, useEffect } from 'react';
import theme from '../theme';

function useWindowSize() {

    const [device, setDevice] = useState("")

    useEffect(() => {
        function handleResize() {
            const width = window.innerWidth
            setDevice(width <= theme.size.desktop ? "mobile" : "desktop")
        }
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [])

    return device
}

export default useWindowSize
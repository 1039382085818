import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import styled from "styled-components";
import theme from "../theme";

/**
 * Primary UI component for user interaction
 */
const TextButton = styled(Button)`
  && {
    background-color: transparent;
    color: ${(props) => props?.textColor || theme.dirtyYellow};
    font-size: ${(props) => props?.fontSize || theme.fontSize.textButton}px;
    ${(props) => props.bold && `font-weight: bold;`}
    &:hover {
      background-color: transparent;
    }
    text-transform: none;
    text-shadow: "rgb(0, 0, 25) 0px 3px 10px 0px";

    .MuiButton-startIcon {
      margin-right: 15px;
    }
  }
`;

const MyTextButton = ({ color, ...props }) => {
  return (
    <TextButton variant="text" disableRipple textColor={color} {...props} />
  );
};

export default MyTextButton;

MyTextButton.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

// Button.defaultProps = {
//   onClick: undefined,
// };

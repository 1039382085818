import { useMemo, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "../../../components/Typography";
import Link from "../../../components/Link";
import UploadIcon from "./UploadIcon";
import styled from "styled-components";
import theme from "../../../theme";
import FileUpload from "../../../components/FileUpload";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/common";
import { breakpoint, WindowSize } from "../../../utils/responsive";
import { useMutation } from "@tanstack/react-query";
import { uploadDocument } from "../../../apis";
import ErrorHint from "./ErrorHint";
import { isNull } from "lodash";
import { useDocConfig } from "./context/DocConfigProvider";
import { ReactComponent as ZoomInIcon } from '../../../assets/ic-zoomLens.svg';
import SimpleDialog from "../../../components/CommonDialog/SimpleDialog";
import SimCardImage from "../../../assets/image-simCard.png";

const MAXIMUM_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE || 5 * 1024 * 1024;    //5MB
const MNP_FORM_NAME = "mnpForm";
const CHANGE_OWNERSHIP_FORM_NAME = "changeOwnershipForm";
const SIM_CARD_IMAGE_NAME = "simCardImage";

const DocumentUploadSection = (props) => {
    const { data, refetch } = props;

    const { t } = useTranslation('profile', { keyPrefix: 'pendingDocument' });
    const [error, setError] = useState(null);

    const { docConfig } = useDocConfig();
    const mnpForm = useMemo(() => docConfig?.find((item) => item?.keyPath === "doc_upload.mnpDownloadForm"), [docConfig]);
    // const changeOwnershipForm = useMemo(() => docConfig?.find((item) => item?.keyPath === "doc_upload.changeOwnershipForm"), [docConfig]);

    const uploadedFile = useMemo(() => {
        return data?.filename ? [{ name: data?.filename }] : null;
    }, [data?.filename])

    const uploadDocumentMutation = useMutation({
        mutationFn: (payload) => uploadDocument(payload),
        onSuccess: (res) => {
            console.log("uploadDocumentMutation success - ", res)
            refetch && refetch();
        },
        onError: (err) => {
            console.log("uploadDocumentMutation error - ", err);
            setError(err?.result)
        }
    })

    const handleUploadDocument = (files) => {
        if (files?.[0]?.size > MAXIMUM_FILE_SIZE) {
            return setError({ error: "fileSizeLimitMessage" });
        }

        let formData = new FormData();
        const formattedDocumentType = data?.type === "hkid" ? "passport" : data?.type;

        formData.append('file', files?.[0]);
        formData.append('filename', files?.[0].name);
        formData.append('documentType', formattedDocumentType);

        if (data?.orderItemId) {
            formData.append('orderItemId', data?.orderItemId);
        } else {
            formData.append('orderId', data?.orderId);
        }

        uploadDocumentMutation.mutate(formData);
    }

    const DocumentAddonDownloadComponent = ({ type }) => {
        const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

        const srcUrl = useMemo(() => {
            if (type === MNP_FORM_NAME) return mnpForm?.valueUrl;
            // if (type === CHANGE_OWNERSHIP_FORM_NAME) return changeOwnershipForm?.valueUrl;
            if (type === SIM_CARD_IMAGE_NAME) return SimCardImage;
            return "#";
        }, [type, mnpForm?.valueUrl])

        if ([SIM_CARD_IMAGE_NAME].includes(type)) return (
            <>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <img src={srcUrl} alt="sim_card_image_sample" style={{ width: 'auto', height: '60px', cursor: 'pointer' }} onClick={() => setIsPreviewDialogOpen(true)} />
                <ZoomInIcon style={{ cursor: "pointer" }} onClick={() => setIsPreviewDialogOpen(true)} />
            </div>
            {
                isPreviewDialogOpen && (
                    <SimpleDialog isOpen={isPreviewDialogOpen} setIsOpen={setIsPreviewDialogOpen} hasSpace={false} containerStyle={{ overflow: 'hidden' }}>
                        <div style={{ textAlign: "center" }}>
                            <img src={srcUrl} alt="sim_card_image_sample" style={{ maxWidth: '100%', maxHeight: 'inherit' }} />
                        </div>
                    </SimpleDialog>
                )
            }
            </>
        )
        if ([MNP_FORM_NAME, CHANGE_OWNERSHIP_FORM_NAME].includes(type)) return (
            <Link href={srcUrl} target="_blank" style={{ fontSize: '20px', fontWeight: 'bold' }}>{`${t('download')}`}</Link>
        )
        return null;
    }

    return (
        <Grid container flexDirection={"column"} gap={{ xs: 2, md: 1 }}>
            <Grid
                item
                container
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent={{ md: "space-between" }}
                alignItems={{ md: "center" }}
                flexWrap={"wrap"}
                gap={{ xs: 2, md: 1 }}
            >
                <Grid item md container gap={1} alignItems={"center"}>
                    <Grid item xs={1}>
                        <UploadIcon />
                    </Grid>
                    <Grid item xs>
                        <Typography style={{ fontSize: '20px', color: '#000', fontWeight: 'bold' }}>
                            {data?.type ? t(`document.${data.type}`, { index: data?.sort }) : ""}
                        </Typography>
                        <DocumentAddonDownloadComponent type={data?.type} />
                    </Grid>
                </Grid>
                <Grid item md container gap={2} alignItems={"center"}>
                    <Grid item md container gap={2} alignItems={"center"} flexWrap={"nowrap"}>
                        <Typography style={{ fontSize: '18px', color: '#333' }}>{t('uploadDate')}</Typography>
                        <Typography style={{ fontSize: '18px', color: '#000', fontWeight: 500 }}>{formatDate(data?.uploadDate)}</Typography>
                    </Grid>
                    <Grid item md container gap={2} alignItems={"center"} flexWrap={"nowrap"}>
                        <Typography style={{ fontSize: '18px', color: '#333' }}>{t('uploadStatus')}</Typography>
                        <Typography style={{ fontSize: '18px', color: theme.mandatory, fontWeight: 500 }}>{t(`status.${data?.status}`)}</Typography>
                    </Grid>
                </Grid>
                <Grid item md={3} container>
                    <FileUpload
                        accept={'.pdf, .jpg, .jpeg'}
                        data={uploadedFile}
                        disabled={!data?.isAllowUpload}
                        onNext={handleUploadDocument}
                        isLoading={uploadDocumentMutation?.isLoading}
                    />
                </Grid>
            </Grid>
            { !isNull(error) && (
                <Grid item md pl={{ md: "35px" }}>
                    <ErrorHint value={error} />
                </Grid>
            )}
        </Grid>
    )
}

export default DocumentUploadSection;
import { useState } from "react";
import RemarkIcon from "../../assets/ic-remark.svg"
import styled from "styled-components";
import Typography from "../Typography";
import SimpleDialog from "../CommonDialog/SimpleDialog";
import MyTypography from "../Typography";
import { GetWord } from "../../utils/common";

const Container = styled.div`
  display: flex;
  cursor: pointer;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const InfoButton = ({ label, title, content, size=22, style }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{ ...style }}>
      <Container
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <img src={RemarkIcon} style={{ maxWidth: size, maxHeight: size }} />
        {label && <Typography color="dirtyYellow" fontWeight={500}>{label}</Typography>}
      </Container>

      {isOpen && (
        <SimpleDialog isOpen={isOpen} setIsOpen={setIsOpen} title={GetWord(title)}>
          <div dangerouslySetInnerHTML={{ __html: GetWord(content) }}/>
        </SimpleDialog>
      )}
    </div>
  );
};

export default InfoButton;

import Typography from "../../../components/Typography";
import styled from "styled-components";
import InfoIcon from '../../../assets/ic-smallInfo.svg';
import theme from "../../../theme";
import { useTranslation } from "react-i18next";
import { WindowSize, breakpoint } from "../../../utils/responsive";

const Tag = styled.div`
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 #c9c9c9;

    display: inline-flex;
    gap: 10px;
    align-items: center;
    padding: 3px 15px;
    width: max-content;

    font-size: 18px;    // icon size
    color: ${theme.lightBlack};

    @media ${breakpoint.mobile} {
      padding: 5px 10px;
      border-radius: 12px;
    }
`

const status_list = {
    "pending": { color: "#FFA500" },
    "paymentFailure": { color: "#fd5332" },
    "paymentSuccess": { color: "#a76c26" },
    "pendingDocument": { color: "#a76c26" },
    "documentSubmitted": { color: '#ff9d00' },
    "verified": { color: '#ff9d00' },
    "completed": { color: "#51c3c3" },
    "cancelled": { color: "#bababa" },
}

const OrderStatusTag = ({ status="needFollowUp" }) => {
  const { t } = useTranslation('profile');
  const isMobile = WindowSize() === 'mobile';

  return (
    <Tag>
      <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: status_list[status]?.color }} />
      <Typography bold fontSize={isMobile ? 11 : 18}>{t(`orderDetails.status.${status}`)}</Typography>
      {/* <img src={InfoIcon} alt="info" width={20} height={20} /> */}
    </Tag>
  )
}

export default OrderStatusTag;
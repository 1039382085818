import styled from "styled-components";
import CustomCardContainer from "../../../components/CustomCardContainer";
import MyTypography from "../../../components/Typography";
import theme from "../../../theme";
import CommonTag from "../../../components/Tags/CommonTag";
import TextButton from "../../../components/TextButton";
import Item from "./Item";
import { Divider } from "@mui/material";
import CardTag from "../../../components/CardTag";
import Space from "../../../components/Space";
import { breakpoint, ResponsiveUI } from "../../../utils/responsive";

const ComboPrice = () => {
  return (
    <div>
      <MyTypography fontSize="24" bold color={theme.grey}>
        組合優惠價
      </MyTypography>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <MyTypography fontSize="28">HK$</MyTypography>
        <MyTypography fontSize="44" bold>
          145
        </MyTypography>
        <MyTypography fontSize="14">/月</MyTypography>
      </div>
      <MyTypography fontSize="11" color="#a7a7a7">
        原價 <del>HK$300</del>
      </MyTypography>
      <Space size="10px" />
      <CommonTag bgColor="#fff9b3" color={theme.grey}>
        共節省$400
      </CommonTag>
      <Space size="10px" />
      <TextButton>計劃詳情</TextButton>
    </div>
  );
};

const ComboOffer = () => {
  return (
    <Contaienr>
      <MyTypography fontSize="32" bold style={{ marginBottom: 30 }}>
        經常一起購買的商品
      </MyTypography>
      <CustomCardContainer width="100%" height="auto" borderRadius={80}>
        <CardTag>Top Tag</CardTag>

        <Content>
          <Item selected />
          <ResponsiveSpace />
          <MyTypography fontSize="50" color="#001861">
            +
          </MyTypography>
          <ResponsiveSpace />
          <Item />
          <ResponsiveSpace />

          <ResponsiveUI
            desktop={<Divider orientation="vertical" flexItem />}
            mobile={<Divider style={{ width: "100%" }} />}
          />

          <ResponsiveSpace />
          <ComboPrice />
        </Content>
      </CustomCardContainer>
    </Contaienr>
  );
};

const Contaienr = styled.div`
  background-color: #fffde6;
  width: 100%;
  height: auto;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoint.desktop} {
    padding: 40px 100px;
  }

  @media ${breakpoint.mobile} {
    flex-direction: column;
    padding: 20px;
  }
`;

const ResponsiveSpace = styled.div`
  @media ${breakpoint.desktop} {
    width: 70px;
  }

  @media ${breakpoint.mobile} {
    height: 30px;
  }
`;

export default ComboOffer;

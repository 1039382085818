import { useField } from "formik";
import { Grid } from "@mui/material";
import Select from "../../components/MenuItemSelect";
import { useEffect } from "react";

const SKUSelectField = (props) => {
    const { id, title=null, options=[], readOnly, locale, onChange, t, showError=true } = props;
    const [field, meta, helpers] = useField(id);

    const handleOnChange = (optionsSelected) => {
        onChange && onChange(optionsSelected);
        helpers.setValue(optionsSelected);
    }

    useEffect(() => {
        if (options.length === 1 && !readOnly) {
            handleOnChange([options[0]?._id || options[0]?.id]);
        }
    }, [options, readOnly])

    return (
        <Grid item container flexDirection={'column'}>
            <Select
                title={title?.[locale] || ""}
                options={options}
                mandatory
                getOptionLabelField={'label'}
                onChange={handleOnChange}
                value={field.value}
                error={showError && meta?.error}
                helperText={((showError && meta?.error) && t && t(meta?.error)) || ""}
                readOnly={readOnly}
            />
        </Grid>
    )
}

export default SKUSelectField;
import { useContext } from "react";
import styled from "styled-components";
import { Badge } from "@mui/material";
import IconShoppingCart from "../../assets/ic-shoppingCart.svg";
import { GlobalContextStore } from "../../context/GlobalProvider";

const Icon = styled.img`
    cursor: pointer;
`;

const CartIcon = ({ onClick, ...props }) => {
    const { globalState } = useContext(GlobalContextStore);

    return (
        <div style={{ padding: "8px", ...props?.style }}>
            <Badge badgeContent={globalState?.cartCount || 0} color="error">
                <Icon src={IconShoppingCart} onClick={onClick} />
            </Badge>
        </div>
    )
}

export default CartIcon;
import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import styled from "styled-components";
import Typography from "../Typography";
import theme from "../../theme";
import { useTranslation } from "react-i18next";

const CustomTextField = styled(TextField)`
  width: ${(props) => (props.width ? props.width : "100%")};
  box-shadow: ${props => props.error ? 'none' : '0 0 6px 0 #c9c9c9'};
  border-radius: 10px;
  & label.Mui-focused {
    color: ${theme.white};
  }
  & .MuiOutlinedInput-root {
    border-radius: 10px;
    
    &.Mui-focused fieldset {
      border-color: ${theme.white};
    }
    &.Mui-disabled {
      background-color: #f2f2f2;
    }
    &.Mui-disabled fieldset {
      border-color: #c9c9c9;
    }
    &:hover fieldset {
      border-color: ${theme.white};
    }
    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${theme.error3};
      box-shadow: ${theme.error3} 0 0 6px 0;
    }
  }
`;

const small = {
  padding: `10.5px 14px`,
  fontSize: 14,
};

const medium = {
  padding: `16.5px 14px`,
  fontSize: 16,
};

const MyTextField = ({
  title,
  titleColor,
  titleSize,
  error,
  helperText,
  size = "medium",
  labelProps,
  id,
  value,
  handleChange,
  ...props
}) => {
  const { t } = useTranslation("error");
  const inputStyle = size === "medium" ? medium : small;

  return (
    <>
      <div>
        {title && (
          <Typography
            color={titleColor}
            header
            {...labelProps}
            style={{ display: "inline-block" }}
          >
            {title}
          </Typography>
        )}
      </div>
      <CustomTextField
        {...props}
        value={value}
        onChange={handleChange}
        sx={{
          fieldset: { borderColor: "#fff" },
        }}
        error={error}
        inputProps={{
          ...props.inputProps,
          style: {
            ...inputStyle,
            ...props?.inputProps?.style,
          },
        }}
        variant="outlined"
        margin="none"
      />
      {error && (
        <Typography
          color={theme.error2}
          fontSize={12}
          style={{ marginTop: "6px", marginLeft: 15 }}
        >
          {t(helperText || error)}
        </Typography>
      )}
    </>
  );
};

export default MyTextField;

MyTextField.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  titleColor: PropTypes.string,
  titleSize: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  required: PropTypes.bool,
};

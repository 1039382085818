import styled from "styled-components";
import MyTypography from "../Typography";
import RedditIcon from "@mui/icons-material/Reddit";
import ItemTable from "./ItemTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import DeleteIcon from "../../assets/ic-delete.svg";
import {
  GetWord,
  formatCurrency,
  getItemColumnWidth,
} from "../../utils/common";
import CardBase from "../Card/CardBase";
import _ from "lodash";
import { Divider } from "@mui/material";
import Space from "../Space";
import ExternalRemark from "../ExternalRemark";

const DeviceContent = ({
  data,
  idx,
  renderDetail,
  orderRecord,
  onEdit,
  onDelete,
  remarkProps,
  productOption,
  iconNeedDotDecoration = true,
  cardBaseStyle = null,
  extraWidthForButton = false,
}) => {
  const { t } = useTranslation("orderContentItem");
  const [showDetail, setShowDetail] = useState(false);

  return (
    <div style={{ marginTop: 35 }}>
      {data.externalRemark && (
        <ExternalRemark value={data.externalRemark} />
      )}
      <CardBase style={{ ...cardBaseStyle, marginTop: 0 }}>
        <ItemTable
          type={"product"}
          itemId={data?.product?._id}
          editId={data?._id}
          colWidth={getItemColumnWidth("product")}
          header={[
            t("item", { index: idx + 1 }),
            t("selectedDetails"),
            t("quantity"),
            t("price"),
          ]}
          row={[
            GetWord(data?.product?.productName),
            GetWord(productOption?.[0]?.label),
            data?.quantity,
            `HK$ ${formatCurrency(
              data?.discountedPricePerItem * data?.quantity,
              1
            )}`,
          ]}
          // icon={<RedditIcon />}
          icon={
            <img
              src={data?.product?.flipCardIconImageUrl}
              alt={GetWord(data?.options?.variationProduct?.productName)}
            />
          }
          onEdit={onEdit}
          onDelete={onDelete}
          setShowDetail={setShowDetail}
          showDetail={showDetail}
          renderDetail={renderDetail}
          orderRecord={orderRecord}
          iconNeedDotDecoration={iconNeedDotDecoration}
          extraWidthForButton={extraWidthForButton}
        />
        {showDetail && renderDetail}
        {onDelete && (
          <ResponsiveUI
            mobile={
              <>
                <div style={{ display: "flex" }}>
                  <Divider style={{ width: "100%" }} />
                </div>
                <DeleteButton onClick={() => onDelete && onDelete()}>
                  <img src={DeleteIcon} style={{ marginRight: 5 }} />
                  <MyTypography fontSize="18" bold>
                    {t("delete", { ns: "common" })}
                  </MyTypography>
                </DeleteButton>
              </>
            }
            desktop={<div />}
          />
        )}
        {remarkProps !== undefined && remarkProps}
      </CardBase>
    </div>
  );
};

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 8px 0 #9b9b9b;
  border-radius: 25px;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 20px 30px;
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 25px 0;
`;

export default DeviceContent;

import { Breadcrumbs } from '@mui/material';
import MyTypography from './Typography';
import { useNavigate } from "react-router-dom";
import theme from '../theme';

export default function CustomBreadcrumbs(props) {
    const { data = [] } = props;
    const navigate = useNavigate();

    return (
        <Breadcrumbs separator=">" aria-label="breadcrumb">
            {data.map(({ label, href, onClick, ...items }, index) => {
                const isLastItem = data.length - 1 === index;

                return (
                    <MyTypography
                        key={`breadcrumb-${index}`}
                        fontSize={14}
                        bold={isLastItem}
                        color={isLastItem ? theme.lightBlack : '#939598'}
                        style={{ cursor: !isLastItem ? 'pointer' : 'auto' }}
                        onClick={() => {
                            if (onClick) return onClick();
                            !isLastItem && href && navigate(href);
                        }}
                    >
                        {label || ""}
                    </MyTypography>
                )
            })}
        </Breadcrumbs>
    )
}
import { useState } from "react";
import Typography from "../../components/Typography";
import Link from "../../components/Link";
import theme from "../../theme";
import BubbleSelect from "../../components/Selects/BubbleSelect";
import SimpleDialog from '../../components/CommonDialog/SimpleDialog';
import { useField } from "formik";
import { useQueries } from "@tanstack/react-query";
import { fetchTNC } from "../../apis";
import { GetWord } from "../../utils/common";

const REEServiceField = (props) => {
    const { t, isMobile, showError } = props;
    const [field, meta, helpers] = useField("needRemovalService");
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const [TNCData] = useQueries({
        queries: [
            {
                queryKey: 'TNCData',
                queryFn: () => fetchTNC(),
            },
        ]
    });

    const OPTIONS = [
        { id: '1', label: t('reeNeeded'), value: true },
        { id: '2', label: t('reeNotNeeded'), value: false },
    ]

    const TNCItem = TNCData?.data?.data?.find(item => item.keyPath === 'tnc.freeRemovalServices') || undefined;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography fontSize={16} fontWeight={'bold'} color={theme.lightBlack}>
                {t('reeService')}
                <span style={{ color: theme.mandatory, marginLeft: '5px' }}>*</span>
            </Typography>
            <BubbleSelect
                id="needRemovalService"
                options={OPTIONS}
                fullWidth={isMobile}
                // data={formik.values.needRemovalService?.[0]}
                containerStyle={{ width: '100%' }}
                verticalAlign={false}
                bubbleWidth={"180px"}
                bubbleContentWrapperStyle={{
                    margin: "20px",
                    marginLeft: "15px",
                }}
                bubbleStyle={{
                    borderRadius: '20px',
                }}
            />
            { (meta?.error && showError) && <Typography color={theme.error} fontSize={12}>{t(meta.error)}</Typography> }
            <Typography color={theme.lightBlack}>
                {t('reeTNCHint')}<Link onClick={() => setDialogIsOpen(true)}>{t('reeTNCTerm')}</Link>{t('reeTNCPostfix')}
            </Typography>
            { (dialogIsOpen) && (
                <SimpleDialog 
                    isOpen={dialogIsOpen}
                    setIsOpen={setDialogIsOpen}
                    titleProp={<Typography fontSize={24} fontWeight={500}>{GetWord(TNCItem?.value?.title)}</Typography>}
                    children={<div dangerouslySetInnerHTML={{ __html: GetWord(TNCItem?.value?.description) }} />}
                />
            )}
        </div>
    )
}

export default REEServiceField;
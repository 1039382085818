import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Typography from "./Typography";
import theme from "../theme";
import PropTypes from "prop-types";
import Step from "./Step";
import Grid from "@mui/material/Grid";
import DoneIcon from "@mui/icons-material/Done";
import { Divider } from "@mui/material";
import { breakpoint } from "../utils/responsive";
import _ from "lodash";

function StepGroup({ data = [] }) {
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    const active = _.find(data, ["isActive", true]);

    setActiveStep(active?.id);
  }, [data]);

  return (
    <Container>
      {data.map((item, idx) => {
        const isLastStep = idx === data?.length - 1;
        return (
          <StepItem>
            <Step
              bgColor={item?.id <= activeStep ? theme.yellow : theme.divider}
            >
              {item.id}
            </Step>
            {!isLastStep && (
              <StyledDivider
                bgColor={item?.id <= activeStep ? theme.yellow : theme.divider}
              />
            )}
          </StepItem>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 30px;
`;

const StepItem = styled.div`
  align-items: center;
  display: flex;
`;

const StyledDivider = styled.div`
  background-color: ${(props) => props.bgColor};
  height: 3px;
  @media ${breakpoint.mobile} {
    width: 40px;
    margin: 0px 5px;
  }

  @media ${breakpoint.desktop} {
    width: 70px;
    margin: 0px 10px;
  }
`;

export default StepGroup;

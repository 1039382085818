import { useState } from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import MyTypography from "../../../components/Typography";
import CardBase from "../../../components/Card/CardBase";
// import TextField from "../../../components/TextField";
import TextField from "../../../components/FormField/FormTextField";
import CheckboxGroup from "../../../components/CheckboxGroup";
import { Divider } from "@mui/material";
import Select from "../../../components/Select";
import { useTranslation } from "react-i18next";
import { breakpoint, WindowSize } from "../../../utils/responsive";
import ConfirmDialog from "../../../components/CommonDialog/ConfirmDialog";
import Form from "./Form";
import DateTimePicker from "../../../components/DateTimePicker";
import DatePicker from "../../../components/DateTimePicker/DatePicker";
import DistrictSelect from "../../../components/Selects/DistrictSelect";
import dayjs from "dayjs";

const CustomerInformation = ({ viewOnly, hasPlan }) => {
  const { t } = useTranslation(["checkout", "common"]);
  const isMobile = WindowSize() === "mobile";

  return (
    <div>
      <Form title={t("customerInformation")} isCollapsible={false}>
        {/* Customer Info */}
          <Grid container paddingX={{ xs: "25px", md: "30px" }} spacing={4}>
            {hasPlan && <Grid item xs={12} md={4} alignSelf={"stretch"}>
              <CheckboxGroup
                id="customer_title"
                title={t("title")}
                options={[
                  { value: "mr", label: t("mr") },
                  { value: "mrs", label: t("mrs") },
                  { value: "miss", label: t("miss") },
                ]}
                viewOnly={viewOnly}
                required
                row
              />
            </Grid>}
            <Grid item xs={12} md={4} alignSelf={"stretch"}>
              <TextField
                id="customer_engName"
                placeholder={"Chan Tai Man"}
                title={t("englishFullName")}
                // subTitle={t("sameAsID")}
                viewOnly={viewOnly}
                required
              />
            </Grid>
            {hasPlan && <Grid item xs={12} md={4} alignSelf={"stretch"}>
              <TextField
                id="customer_chiName"
                placeholder={"陳大文"}
                title={t("chineseFullName")}
                subTitle={t("sameAsID")}
                viewOnly={viewOnly}
                required
              />
            </Grid>}

            {hasPlan && <Grid item xs={12} md={4}>
              <CheckboxGroup
                id="customer_idType"
                title={t("idType")}
                options={[
                  { id: 1, value: "hkid", label: t("hkid") },
                  { id: 2, value: "passport", label: t("passport") },
                ]}
                viewOnly={viewOnly}
                row
              />
            </Grid>}
            {hasPlan && <Grid item xs={12} md={4}>
              <TextField
                id="customer_idNum"
                placeholder={t("idNumber")}
                title={t("idNumber")}
                viewOnly={viewOnly}
                required
              />
            </Grid>}
            {hasPlan && <Grid item xs={12} md={4}>
              <DatePicker
                id="customer_dateOfBirth"
                placeholder={"出生日期"}
                title={t("dateOfBirth")}
                viewOnly={viewOnly}
                maxDate={dayjs(Date.now())}
                required
              />
            </Grid>}

            {hasPlan && <Grid item xs={12} md={4}>
              <TextField
                id="customer_companyName"
                placeholder={t("chineseCompanyName")}
                title={t("companyName")}
                viewOnly={viewOnly}
                required
              />
            </Grid>}
            {hasPlan && <Grid item xs={12} md={4}>
              <TextField
                id="customer_jobPosition"
                placeholder={t("jobPosition")}
                title={t("jobPosition")}
                viewOnly={viewOnly}
                required
              />
            </Grid>}
            <Grid item xs={12} md={4} alignSelf={"stretch"}>
              <TextField
                id="customer_email"
                placeholder={t("billingEmailAddress")}
                title={t("billingEmailAddress")}
                viewOnly={viewOnly}
                required
              />
            </Grid>

            <Grid item xs={12} md={4} alignSelf={"stretch"}>
              <TextField
                id="customer_mobileNo"
                placeholder={t("mobileNo")}
                title={t("mobileNo")}
                subTitle={t("receiveCargeCode")}
                viewOnly={viewOnly}
                required
              />
            </Grid>
          </Grid>

          <Grid item xs={12} paddingY={"30px"}>
            <Divider />
          </Grid>

          {/* Mailing Address */}
          <Grid container flexDirection={"column"} item xs={12} style={{ padding: `0 ${isMobile ? "25px" : "30px"}` }} spacing={4}>
            {hasPlan && <Grid item xs={12}>
              <MyTypography fontSize="24" bold>
                {t("mailingAddress")}
              </MyTypography>
            </Grid>}

            <Grid item xs={12}>
              <TextField
                id="customer_address1"
                placeholder={t("addressRemark1")}
                title={t("address")}
                viewOnly={viewOnly}
                required
              />
              <div style={{ height: 15 }} />
              <TextField
                id="customer_address2"
                placeholder={t("addressRemark2")}
                viewOnly={viewOnly}
              />
            </Grid>

            <Grid item xs={12}>
              <DistrictSelect id="customer_district" viewOnly={viewOnly} />
            </Grid>
          </Grid>
      </Form>
    </div>
  );
};

export default CustomerInformation;
